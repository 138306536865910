import { useState } from "react";
import { useSelector } from 'react-redux';
import LazyLoad from 'react-lazyload';

// utility
import { formatData } from '../../utility/functions';

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import MessaggioButtons from "./messaggio_buttons";

const MessaggioImmagine = (props) => {
    const [ isHover, setIsHover ] = useState(false);
    const is_amministratore = useSelector((state) => state.dettaglio.is_amministratore);
    const msg = props.msg;

    let verticale = msg.attributi?.ratio ? msg.attributi?.ratio < 1 : false;
    let placeholderHeight = 344;

    if (msg.attributi !== undefined) {
        if(verticale){
            placeholderHeight = msg.attributi?.width / msg.attributi?.ratio;
            if(placeholderHeight > 344){
                placeholderHeight = 344;
            }
        }

        if(!verticale){
            if(msg.attributi?.width >= 330 && msg.attributi?.ratio > 2){ // solo per immagini molto lunghe
                placeholderHeight = msg.attributi?.width / msg.attributi?.ratio;
            }else{
                placeholderHeight =  330 / msg.attributi?.ratio;
            }
        }
    }

    const Placeholder = (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <FontAwesomeIcon spin icon={faSpinner} />
        </div>
    );


    return (
        <div 
            id={msg.ChiaveOrdinamento} 
            className={`w-100 d-flex py-2 msg_${msg.who}`}
            onMouseEnter={()=>{setIsHover(true)}}
            onMouseLeave={()=>{setIsHover(false)}}
        >
            <div className={`msg border shadow-sm rounded ${msg.active ? 'active':''} ${msg.eliminazione_in_corso === true && 'opacity-25'}`}>
                <div className="nome"><strong>{msg.who !== 'admin' && msg.who !== 'out' && msg.from_name}</strong></div>
                <div className={`position-relative img_wrapper p-2 ${verticale ? 'verticale' : 'orizzontale'}`}>
                    {msg.url === '' && <img className="rounded blurrata" src={msg.preview} style={{ width: verticale ? 240 : 330, height: placeholderHeight, maxWidth: '100%' }} alt={msg.nome_originale} />}
                    {msg.url !== '' && (
                        <a target="_blank" href={msg.url} rel="noreferrer">
                            <LazyLoad once placeholder={Placeholder} style={{ width: verticale ? 240 : 330, height: placeholderHeight, maxWidth: '100%' }} scrollContainer={document.getElementById('lista_messaggi')} overflow={true}>
                                <img className="rounded" src={`${msg.url}${verticale ? '?d=100' : '?d=x100'}`} alt={msg.nome_originale} />
                            </LazyLoad>
                        </a>
                    )}
                </div>
                <span className="data">{msg.who !== 'admin' ? formatData(msg.messaggio_data_ora) : ''}</span>
            </div>
            {isHover && is_amministratore && msg.who !== 'admin' && <MessaggioButtons msg={msg} setIsHover={setIsHover}/>}
        </div>
    )

}

export default MessaggioImmagine