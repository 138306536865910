const sortMessaggi = (messaggi) => {
    if (messaggi.length > 1) {
        messaggi.sort((a, b) => {
            return new Date(a.messaggio_data_ora) < new Date(b.messaggio_data_ora) ? -1 : 1
        })
    }
    return messaggi
}

const sortMessaggiReverse = (messaggi) => {
    if (messaggi.length > 1) {
        messaggi.sort((a, b) => {
            return new Date(a.messaggio_data_ora) > new Date(b.messaggio_data_ora) ? -1 : 1
        })
    }
    return messaggi
}

const sortChat = (chat) => {

    if (chat.length > 1) {
        chat.sort((a, b) => {
            return new Date(a.last_updated) < new Date(b.last_updated) ? 1 : -1
        })
    }

    return chat
}

const getFileType = (type) => {
    if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') { // escludo svg perchè complicano un po' il sistema
        return 'immagine'
    } else if (type.startsWith('video')) {
        return 'video'
    } else if (type.startsWith('audio')) {
        return 'audio'
    } else if (type === 'application/pdf') {
        return 'pdf'
    } else {
        return 'file'
    }
}

const sortCategoria = (categoria) => {

    if (categoria.length > 1) {
        categoria.sort((a, b) => {
            return new Date(a.last_used) < new Date(b.last_used) ? 1 : -1
        })
    }

    return categoria
}

const formatData = (input) => {
    const oggi = new Date();
    let data = new Date(input);

    if (datesAreOnSameDay(oggi, data)) {
        return data.getHours() + ':' + (data.getMinutes() < 10 ? '0' + data.getMinutes() : data.getMinutes());
    } else {
        let anno = data.getFullYear().toString();
        return (data.getDate() < 10 ? '0' + data.getDate() : data.getDate()) + '/' + (data.getMonth() < 9 ? '0' + (data.getMonth() + 1) : (data.getMonth() + 1)) + '/' + anno.slice(2, 4) + ' ' + data.getHours() + ':' + (data.getMinutes() < 10 ? '0' + data.getMinutes() : data.getMinutes());
    }
}

const datesAreOnSameDay = (first, second) => first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();

const ieriOggi = (data) => {
    const giorno = new Date();

    if (datesAreOnSameDay(data, giorno)) return 'oggi'
    giorno.setDate(giorno.getDate() - 1);
    if (datesAreOnSameDay(data, giorno)) return 'ieri'

    return false
}

const puliziaUtentiDettaglio = (utenti_dettaglio) => {
    let result = [];

    utenti_dettaglio.L.forEach(utente => {
        result.push({
            nominativo: utente.M.nominativo.S,
            utente: utente.M.utente.S,
        })
    })

    return result
}

const getLetterColor = (word) => {

    var alfabeto = {};

    alfabeto['default'] = '#008394'; // default in caso di categorie con caratteri speciali

    alfabeto['65'] = "#1abc9c";
    //A
    alfabeto['66'] = "#16a085";
    //B
    alfabeto['67'] = "#f1c40f";
    //C
    alfabeto['68'] = "#f39c12";
    //D
    alfabeto['69'] = "#2ecc71";
    //E
    alfabeto['70'] = "#27ae60";
    //F
    alfabeto['71'] = "#d93a37";
    //G
    alfabeto['72'] = "#a6b12e";
    //H
    alfabeto['73'] = "#5c9bbc";
    //I
    alfabeto['74'] = "#f5888d";
    //J
    alfabeto['75'] = "#9a89b5";
    //K
    alfabeto['76'] = "#407887";
    //L
    alfabeto['77'] = "#9a89b5";
    //M
    alfabeto['78'] = "#5a8770";
    //N
    alfabeto['79'] = "#d33f33";
    //O
    alfabeto['80'] = "#a2b01f";
    //P
    alfabeto['81'] = "#f0b126";
    //Q
    alfabeto['82'] = "#0087bf";
    //R
    alfabeto['83'] = "#f18636";
    //S
    alfabeto['84'] = "#dd87bf";
    //T
    alfabeto['85'] = "#b2b7bb";
    //U
    alfabeto['86'] = "#72acae";
    //V
    alfabeto['87'] = "#9c8ab4";
    //W
    alfabeto['88'] = "#5a8770";
    //X
    alfabeto['89'] = "#eeb424";
    //Y
    alfabeto['90'] = "#407887";
    //Z
    alfabeto['48'] = "#16a085";
    alfabeto['49'] = "#f1c40f";
    alfabeto['50'] = "#f39c12";
    alfabeto['51'] = "#2ecc71";
    alfabeto['52'] = "#27ae60";
    alfabeto['53'] = "#a6b12e";
    alfabeto['54'] = "#5c9bbc";
    alfabeto['55'] = "#f5888d";
    alfabeto['56'] = "#9a89b5";
    alfabeto['57'] = "#407887";

    try {
        var letter = word?.toUpperCase();

        var ascii = Math.round((letter.charCodeAt(0) + letter.charCodeAt(1)) / 2);

        if ((ascii <= 57 && ascii >= 48) || (ascii <= 90 && ascii >= 65)) {
            return alfabeto[ascii];
        }

        return alfabeto['default'];

    } catch {
        return alfabeto['default'];
    }
}

export {
    sortMessaggi,
    sortChat,
    formatData,
    getLetterColor,
    sortCategoria,
    puliziaUtentiDettaglio,
    getFileType,
    datesAreOnSameDay,
    ieriOggi,
    sortMessaggiReverse
}
