import { useState } from "react";
import anchorme from "anchorme";
// libreria emoji
import { getEmojiDataFromNative, Emoji } from 'emoji-mart-virtualized'
import data from 'emoji-mart-virtualized/data/all.json'

const emojiRegex = require('emoji-regex');

const DettaglioDescrizione = (props) => {
    const [open, setOpen] = useState(false);

    let descrizione = props.descrizione;

    if(descrizione === null){
        return (
            <div className="p-2" id="chat-dettaglio-descrizione">
                Nessuna descrizione
            </div>
        )
    }else{

        function escape(s) {
            let lookup = {
                '"': "&quot;",
                '\'': "&apos;",
                '<': "&lt;",
                '>': "&gt;"
            };
            return s.replace( /["'<>]/g, c => lookup[c] );
        }

        descrizione = escape(descrizione);

        descrizione = anchorme({
            input: descrizione,
            options: {
                attributes: {
                    target: "_blank"
                },
                truncate: 40,
                middleTruncation: true,
            }
        });

        const regex = emojiRegex();
    
        // Per renderizzare emoji più belle
        function replacer(match) {
            try{
                // queste funzioni restituiscono spesso null. Per ora ho gestito gli errori. Potrebbe dipendere dal archivio delle emoji che viene preso online che ogni tanto fa cilecca. Da indagare comunque per farlo meglio
                const emojiData = getEmojiDataFromNative(match, 'google', data);
    
                const html = Emoji({
                    html: true,
                    set: 'google',
                    emoji: emojiData,
                    size: 22
                });
    
                if(html !== null){
                    return html
                }else{
                    return match
                }
            }catch (error){
                console.log(error)
                return match
            }
        }

        descrizione = descrizione.replaceAll(regex, replacer);

        if(descrizione.length > 400){
            return (
                <>
                    <div className="p-2" id="chat-dettaglio-descrizione">
                        <p style={{whiteSpace: 'pre-wrap'}} className="mb-0" >
                            { !open && <span className="mw-100 text-break" dangerouslySetInnerHTML={{__html: descrizione.slice(0 , 350)}}></span>}
                            {!open ? '...' : <span dangerouslySetInnerHTML={{__html: descrizione}}></span> }    
                        </p>
                        <div className="text-end">
                            <span className="show_more " onClick={() => setOpen(!open)} >{!open ? '+ Mostra altro' : '- Mostra meno'}</span>
                        </div>
                    </div>
                </>
            )
        }else{
            return (
                <>
                    <div className="p-2">
                        <p style={{whiteSpace: 'pre-wrap'}} className="mb-0 mw-100" id="chat-dettaglio-descrizione">
                            <span className="mw-100 text-break" dangerouslySetInnerHTML={{__html: descrizione}}></span> 
                        </p>
                    </div>
                </>
            )
        }
    }
}

export default DettaglioDescrizione