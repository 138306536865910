import chiamataAPI from "../../utility/chiamataAPI";
import costanti from "../../utility/costanti";

import { feedbackActions } from '../feedback/feedbackSlice';
import { moduliActions } from '../moduli/moduliSlice';
import {chatDettaglioGet} from '../dettaglio/dettaglioActions'

export const submitAggiungiUtenti = (payload) => {
    return async (dispatch, getState) => {
        dispatch(moduliActions.setModificaStatus({ status: 'loading' }));

        const form = payload.form;

        const richiesta_axios = payload.richiesta_axios;

        const user = getState().user;
        const chat_id = getState().dettaglio.dettagli.ChiavePartizione;

        let utente = [];

        form.checkbox.forEach((user) => {
            if(user.checked && !user.disabled){
                utente.push(JSON.stringify({
                    userId: user.ChiaveOrdinamento,
                    is_amministratore: false
                }));
            }
        })

        let url = costanti.api_utente_inserisci;

        let data = {
            chat_id: chat_id,
            account: user.account,
            utente: utente,
            userId: user.userId
        }

        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(feedbackActions.addNotifica({ errori: ['Operazione Interrotta'] }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'submitAggiungiUtenti',
                        payload: payload
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                // qua l'aggiornamento dei dati viene triggerato dal websocket conversazione_update
                dispatch(moduliActions.setModificaStatus({ status: 'ok' }));
                dispatch(moduliActions.setDettaglio({ page: 'dettaglio' }));
                dispatch(feedbackActions.addNotifica({success: 'Utenti aggiunti correttamente'})); 
            } else if (r.stato === 0) {
                dispatch(moduliActions.setModificaStatus({ status: 'ok' }));
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
            }
        })
    }
};

export const submitEliminaUtenti = (payload) => {
    return async (dispatch, getState) => {
        dispatch(moduliActions.setModificaStatus({ status: 'loading' }));

        const form = payload.form;

        const richiesta_axios = payload.richiesta_axios;

        const user = getState().user;
        const chat_id = getState().dettaglio.dettagli.ChiavePartizione;

        let utente = [];

        form.checkbox.forEach((user) => {
            if(user.checked){
                utente.push(user.ChiaveOrdinamento);
            }
        })

        let url = costanti.api_elimina_utente;

        let data = {
            chat_id: chat_id,
            account: user.account,
            utente: utente,
            userId: user.userId
        }

        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(feedbackActions.addNotifica({ errori: ['Operazione Interrotta'] }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'submitEliminaUtenti',
                        payload: payload
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                // qua l'aggiornamento dei dati viene triggerato dal websocket conversazione_update
                dispatch(moduliActions.setModificaStatus({ status: 'ok' }));
                dispatch(moduliActions.setDettaglio({ page: 'dettaglio' }));
                dispatch(feedbackActions.addNotifica({success: 'Utenti eliminati correttamente'})); 
            } else if (r.stato === 0) {
                dispatch(moduliActions.setModificaStatus({ status: 'ok' }));
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
            }
        })
    }
};

export const submitModificaAmministratore = (payload) => {
    return async (dispatch, getState) => {
        dispatch(moduliActions.setModificaStatus({ status: 'loading' }));

        const form = payload.form;

        const richiesta_axios = payload.richiesta_axios;

        const user = getState().user;
        const chat_id = getState().dettaglio.dettagli.ChiavePartizione;

        let utente = [];

        form.checkbox.forEach((user) => {
            if(user.checked !== user.is_amministratore){
                utente.push(JSON.stringify({
                    userId: user.ChiaveOrdinamento,
                    is_amministratore: user.checked
                }));
            }
        })

        let url = costanti.api_utente_isamministratore_update;

        let data = {
            chat_id: chat_id,
            account: user.account,
            utente: utente,
            userId: user.userId
        }

        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(feedbackActions.addNotifica({ errori: ['Operazione Interrotta'] }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'submitModificaAmministratore',
                        payload: payload
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                dispatch(moduliActions.setModificaStatus({ status: 'ok' }));
                dispatch(moduliActions.setDettaglio({ page: 'dettaglio' }));
                dispatch(feedbackActions.addNotifica({success: 'Amministratori modificati correttamente'}));
                dispatch(chatDettaglioGet({})); // qua non c'è possibilità di annullare la richiesta con un token all'unmount. Per ora va bene così il caso ultralimite sarebbe uno cambia amministratori all'operazione andata a buon fine cambia chat. Al cambio chat parte una richiesta di chat dettaglio che dovrebbe arrivare dopo questa richiesta e quindi uno apre la nuova chat ma come chat dettaglio si ritrova questa. è praticamente impossibile e anche se succedesse uno clicca e si riapre la sua chat.
            } else if (r.stato === 0) {
                dispatch(moduliActions.setModificaStatus({ status: 'ok' }));
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
            }
        })
    }
};

export const submitModificaDettagli = (payload) => {
    return async (dispatch, getState) => {
        dispatch(moduliActions.setModificaStatus({ status: 'loading' }));

        const form = payload.form;

        const richiesta_axios = payload.richiesta_axios;

        const user = getState().user;
        const chat_id = getState().dettaglio.dettagli.ChiavePartizione;

        let url = costanti.api_chat_dettaglio_modifica;

        let data = {
            chat_id: chat_id,
            account: user.account,
            userId: user.userId,
            titolo: form.titolo,
            descrizione: form.descrizione,
            categoria: form.categoria,
            piattaforma: 'web'
        }

        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(feedbackActions.addNotifica({ errori: ['Operazione Interrotta'] }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'submitModificaDettagli',
                        payload: payload
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                // qua i dettagli chat vengono aggiornati da conversazione_update sul websocket
                dispatch(moduliActions.setModificaStatus({ status: 'ok' }));
                dispatch(moduliActions.setDettaglio({ page: 'dettaglio' }));
                dispatch(feedbackActions.addNotifica({success: 'Dettagli chat modificati correttamente'}));
            } else if (r.stato === 0) {
                dispatch(moduliActions.setModificaStatus({ status: 'ok' }));
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
            }
        })
    }
};