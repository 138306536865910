import { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Navbar, Button, Form } from "react-bootstrap"

import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight, faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons"

import FormCategorie from "./form_categorie"

import { moduliActions } from "../../store/moduli/moduliSlice"
import { submitModificaDettagli } from "../../store/modifica/modificaActions"
import { listaCategorieGet } from '../../store/servizio/servizioActions'

const ModificaDettagli = () => {
    const status = useSelector((state) => state.moduli.modifica.status);
    const dettagli = useSelector((state) => state.dettaglio.dettagli);
    const lista_categorie = useSelector((state) => state.servizio.lista_categorie);
    const is_amministratore = useSelector((state) => state.dettaglio.is_amministratore);

    const dispatch = useDispatch();

    const richiesta_axios = useRef(axios.CancelToken.source());

    useEffect(() => {
        // questo aggiornamento di utenti e categorie si potrebbe evitare tanto sono già abbastanza aggiornate e c'è il tasto eventualmente
        dispatch(listaCategorieGet());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            try {
                richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch {}
        }
    }, [])

    const initialState = {
        titolo: dettagli.titolo,
        descrizione: dettagli.descrizione,
        categoria: dettagli.categoria ? dettagli.categoria : [],
        input_categoria: '',
        lista_categorie: lista_categorie
    };

    const [form, setForm] = useState(initialState);

    useEffect(() => {
        setForm({
            ...form,
            lista_categorie: lista_categorie
        })
    }, [lista_categorie])

    const indietro = () => {
        dispatch(moduliActions.setDettaglio({ page: 'dettaglio' }))
    }

    useEffect(() => {
        if (!is_amministratore) {
            indietro();
        }
    })

    useEffect(() => {
        setForm(initialState);
    }, [dettagli])

    const submitHandler = (e) => {
        let element = e.currentTarget;

        if (status === 'loading') {
            e.preventDefault()
        } else if (element.checkValidity() === true) {
            e.preventDefault();
            dispatch(submitModificaDettagli({ 
                form: form,
                richiesta_axios: richiesta_axios.current 
            }));
        }
    }

    const onChangeHandler = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (target.type !== 'checkbox') {
            setForm({
                ...form,
                [name]: name == 'input_categoria' ? value.toLowerCase() : value
            });
        } else {

            const checked = target.checked;

            setForm({
                ...form,
                lista_utenti: {
                    ...form.lista_utenti,
                    [name]: {
                        ...form.lista_utenti[name],
                        is_checked: checked
                    }
                }
            });
        }
    }

    return (
        <div id="modifica" className="h-100 w-100 bg-white">
            <Navbar expand="xs" id="titolo_pannello_generico" className="border-bottom shadow-sm px-3 bg-light">
                <Navbar.Brand>
                    Modifica Dettagli
                </Navbar.Brand>
                <span className="cursor-pointer text-primary" onClick={() => { indietro() }}>Indietro <FontAwesomeIcon icon={faArrowAltCircleRight} /></span>
            </Navbar>
            <Form id="form-modifica-dettagli" onSubmit={submitHandler}>
                <div id="modifica-dettagli" className="p-3">
                    <Form.Group className="mb-2">
                        <Form.Label><strong>Titolo Chat</strong></Form.Label>
                        <Form.Control type="text" placeholder="Titolo Chat" value={form.titolo} name="titolo" onChange={onChangeHandler} />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label><strong>Descrizione</strong></Form.Label>
                        <Form.Control as="textarea" rows={3} value={form.descrizione} name="descrizione" onChange={onChangeHandler} />
                    </Form.Group>
                    <FormCategorie form={form} setForm={setForm} onChangeHandler={onChangeHandler} />
                </div>
                <div id="confirm" className="shadow-top border-top w-100 confirm-button d-flex justify-content-around align-items-center">
                    {/* <small onClick={reset} className="text-primary cursor-pointer">Reset</small> */}
                    <Button className="w-50 rounded-pill" disabled={status === 'loading'} type="submit" variant="primary">{status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <> <FontAwesomeIcon className="me-3" icon={faCheck} /> Conferma</>}</Button>
                </div>
            </Form>
        </div>
    )
}

export default ModificaDettagli