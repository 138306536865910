import { useSelector, useDispatch } from 'react-redux';
import { Form, InputGroup, FormControl } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faSyncAlt, faSpinner } from "@fortawesome/free-solid-svg-icons"

import { listaUtentiGet } from '../../store/servizio/servizioActions'

const RicercaUtenti = (props) => {
    const form = props.form;
    const onChangeHandler = props.onChangeHandler;

    const lista_utenti_status = useSelector((state) => state.moduli.servizio.lista_utenti_status);
    const userId = useSelector((state) => state.user.userId);

    const dispatch = useDispatch();

    const refreshUtenti = () => {
        dispatch(listaUtentiGet());
    }

    return (
        <Form.Group className="py-2">
            <Form.Label><strong>Ricerca utente</strong><FontAwesomeIcon onClick={() => { refreshUtenti() }} className="ms-2 text-primary cursor-pointer" spin={lista_utenti_status === 'loading'} size="sm" icon={faSyncAlt} /></Form.Label>
            <InputGroup className="mb-3 w-75">
                <FormControl placeholder="Filtra Utenti" onChange={onChangeHandler} name="filtro_utenti" value={form.filtro_utenti} />
                <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
            </InputGroup>
            {lista_utenti_status === 'loading' && (
                <div className="text-primary text-center py-2">
                    <FontAwesomeIcon spin className="text-primary" icon={faSpinner} />
                </div>
            )}
            {lista_utenti_status === 'ok' && (
                Object.entries(form.lista_utenti).map((obj) => {
                    const [key, value] = obj;

                    let nome = value.nome;
                    let cognome = value.cognome;
                    let filtro_utenti = form.filtro_utenti;

                    if (key !== userId && (nome.toLowerCase().startsWith(filtro_utenti.toLowerCase()) || cognome.toLowerCase().startsWith(filtro_utenti.toLowerCase()))) {
                        return (
                            <Form.Check key={key} className="py-1" onChange={onChangeHandler} checked={value.is_checked} id={`checkbox_crea_${key}`} name={value.ChiaveOrdinamento} type="checkbox" label={`${value.nome} ${value.cognome}`} />
                        )
                    } else {
                        return null
                    }
                })
            )}
        </Form.Group>
    )
}

export default RicercaUtenti