import axios from 'axios';
import chiamataAPI from "../../utility/chiamataAPI";
import costanti from "../../utility/costanti";
import {sortCategoria} from "../../utility/functions"

import { feedbackActions } from '../feedback/feedbackSlice';
import { servizioActions } from './servizioSlice';
import { moduliActions } from '../moduli/moduliSlice';

export const listaUtentiGet = () => {
    return async (dispatch, getState) => {
        dispatch(moduliActions.setServizioStatus({lista_utenti_status: 'loading'}))
        const richiesta_axios = axios.CancelToken.source();

        const user = getState().user;

        let url = costanti.api_utente_lista_get;
        
        let data = {
          account: user.account,
          userId: user.userId
        }

        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(feedbackActions.addNotifica({ errori: ['Operazione Interrotta'] }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'listaUtentiECategorieGet'
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                dispatch(moduliActions.setServizioStatus({lista_utenti_status: 'ok'}))
                dispatch(servizioActions.setListaUtenti({
                    lista_utenti: r.data,
                }))
            } else if (r.stato === 0) {
                dispatch(moduliActions.setServizioStatus({lista_utenti_status: 'error'}))
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? [...r.errori, 'Impossibile ricevere lista utenti. Riprovare'] : ['Impossibile ricevere lista utenti. Riprovare'] }));
            }

        })

    }
};

export const listaCategorieGet = () => {
    return async (dispatch, getState) => {
        dispatch(moduliActions.setServizioStatus({lista_categorie_status: 'loading'}))
        const richiesta_axios = axios.CancelToken.source();

        const user = getState().user;

        let url = costanti.api_servizio_categoria_get;
        let data = {
          account: user.account,
          userId: user.userId
        }

        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(feedbackActions.addNotifica({ errori: ['Operazione Interrotta'] }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'listaUtentiECategorieGet'
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                dispatch(moduliActions.setServizioStatus({lista_categorie_status: 'ok'}))
                dispatch(servizioActions.setListaCategorie({
                    lista_categorie: sortCategoria(r.data),
                }))
            } else if (r.stato === 0) {
                dispatch(moduliActions.setServizioStatus({lista_categorie_status: 'error'}))
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? [...r.errori, 'Impossibile ricevere lista categorie. Riprovare'] : ['Impossibile ricevere lista categorie. Riprovare']  }));
            }

        })
    }
};