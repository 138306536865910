const host_api = process.env.REACT_APP_STAGE === 'prod' ? 'api.dimmia.com' : 'test-api.dimmia.com';
const host_websocket = process.env.REACT_APP_STAGE === 'prod' ? 'websocket.dimmia.com' : 'test-websocket.dimmia.com';
const costanti = {
    api_servizio_tipo_get: `https://${host_api}/v1/servizio/servizio-tipo-get`,
    api_servizio_get: `https://${host_api}/v1/servizio/servizio-get`,
    websocket_url: `wss://${host_websocket}/v1`,
    api_chat_inserisci: `https://${host_api}/v1/chat/chat-inserisci`,
    api_utente_inserisci: `https://${host_api}/v1/chat/chat-utente-inserisci`,
    api_login_account: `https://${host_api}/v1/login`,
    api_chat_dettaglio_get: `https://${host_api}/v1/chat/chat-dettaglio-get`,
    api_utente_isamministratore_update: `https://${host_api}/v1/chat/chat-amministratore-update`,
    api_elimina_utente: `https://${host_api}/v1/chat/chat-utente-elimina`,
    api_chat_dettaglio_modifica: `https://${host_api}/v1/chat/chat-dettaglio-update`,
    api_utente_lista_get: `https://${host_api}/v1/utente/utente-lista-get`,
    api_chat_ricerca: `https://${host_api}/v1/chat/chat-ricerca`,
    api_servizio_categoria_get: `https://${host_api}/v1/servizio/servizio-categoria-get`,
    api_chat_utente_is_silenziata_update: `https://${host_api}/v1/chat/chat-utente-is-silenziata-update`,
    api_chat_utente_is_archiviata_update: `https://${host_api}/v1/chat/chat-utente-is-archiviata-update`,
	api_url_presigned_url_get: `https://${host_api}/v1/assets/presigned-url-get`,
    api_autologin: `https://${host_api}/v1/autologin`,
}

export default costanti;