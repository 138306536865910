import { useState } from "react";
import { useSelector } from 'react-redux';

import anchorme from "anchorme";
import { formatData } from "../../utility/functions";

import MessaggioButtons from "./messaggio_buttons";

// libreria emoji
import { getEmojiDataFromNative, Emoji } from 'emoji-mart-virtualized'
import data from 'emoji-mart-virtualized/data/all.json'
const emojiRegex = require('emoji-regex');

const MessaggioTesto = (props) => {
    const msg = props.msg;
    const [ isHover, setIsHover ] = useState(false);
    const is_amministratore = useSelector((state) => state.dettaglio.is_amministratore);

    let testo = escape(msg.testo);

    testo = anchorme({
        input: testo,
        options: {
            attributes: {
                target: "_blank"
            },
            truncate: 25,
            middleTruncation: true,
        }
    });

    const regex = emojiRegex();
    
    let isOnlyEmoji = testo.replace(regex, '') === "";

    // Per renderizzare emoji più belle
    function replacer(match) {
        try{
           // queste funzioni restituiscono spesso null. Per ora ho gestito gli errori. Potrebbe dipendere dal archivio delle emoji che viene preso online che ogni tanto fa cilecca. Da indagare comunque per farlo meglio
           const emojiData = getEmojiDataFromNative(match, 'google', data);

           const html = Emoji({
               html: true,
               set: 'google',
               emoji: emojiData,
               size: isOnlyEmoji ? 36 : 22
           });

            if(html !== null){
                return html
            }else{
                return match
            }
        }catch (error){
            console.log(error)
            return match
        }
    }

    function escape(s) {
        let lookup = {
            '"': "&quot;",
            '\'': "&apos;",
            '<': "&lt;",
            '>': "&gt;"
        };
        return s.replace( /["'<>]/g, c => lookup[c] );
    }

    testo = testo.replaceAll(regex, replacer);

    return (
        <div 
            id={msg.ChiaveOrdinamento} 
            className={`w-100 d-flex py-2 msg_${msg.who}`}
            onMouseEnter={()=>{setIsHover(true)}}
            onMouseLeave={()=>{setIsHover(false)}}
        >
            <div className={`msg border shadow-sm rounded ${msg.active && 'active'} ${msg.eliminazione_in_corso === true && 'opacity-25'}`}>
                <div className="nome"><strong>{msg.who !== 'admin' && msg.who !== 'out' && msg.from_name}</strong></div>
                <div style={{ whiteSpace: 'pre-wrap' }} className={`${isOnlyEmoji ? 'text-center' : ''} testo text-break`} dangerouslySetInnerHTML={{ __html: testo }}></div>
                <span className="data">{msg.who !== 'admin' ? formatData(msg.messaggio_data_ora) : ''}</span>
            </div>
            {isHover && is_amministratore && msg.who !== 'admin' && <MessaggioButtons msg={msg} setIsHover={setIsHover}/>}
        </div>
    )
}

export default MessaggioTesto