import { createSlice } from '@reduxjs/toolkit';
import { sortChat, sortMessaggiReverse } from '../../utility/functions';

const initialState = {
    init: {
        categoria: [],
        filtro_stato: '',
        testo: '',
        lista_utenti: null,
    },
    risultato: {
        lista_chat: null,
        lista_messaggi: null
    },
    from_chat: 0,
    from_messaggi: 0
};

const ricercaSlice = createSlice({
    name: 'ricerca',
    initialState,
    reducers: {
        reset: (state) => {
            return initialState
        },
        setRisultato: (state, action) => {
            let lista_chat = null;
            let lista_messaggi = null;
            let from_chat = state.from_chat;
            let from_messaggi = state.from_messaggi;

            if(action.payload.altri == null){ // non so caricando altri risultati
                lista_chat = action.payload.lista_chat;
                lista_messaggi = action.payload.lista_messaggi;
                from_chat = action.payload.from_chat;
                from_messaggi = action.payload.from_messaggi;
            }else if(action.payload.altri == 'chat'){ // sto caricando altre chat
                lista_chat = sortChat([...new Set([...state.risultato.lista_chat, ...action.payload.lista_chat])]);
                lista_messaggi = [...state.risultato.lista_messaggi];
                from_chat = action.payload.from_chat;
            }else if(action.payload.altri == 'messaggi'){ // sto caricando altri messaggi
                lista_messaggi = sortMessaggiReverse([...new Set([...state.risultato.lista_messaggi, ...action.payload.lista_messaggi])]);
                lista_chat = [...state.risultato.lista_chat];
                from_messaggi = action.payload.from_messaggi;
            }
            
            return {
                ...state,
                init: action.payload.init,
                risultato: {
                    lista_chat: lista_chat,
                    lista_messaggi: lista_messaggi
                },
                from_chat: from_chat,
                from_messaggi: from_messaggi,
            }
        }
    }
});

export const ricercaActions = ricercaSlice.actions;

export default ricercaSlice;