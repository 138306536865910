import { useState, createRef, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { nanoid } from '@reduxjs/toolkit';

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faFile, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

// dropzone
import Dropzone from 'react-dropzone'

import { ProgressBar, Form, Button } from 'react-bootstrap'

// utility
import axios from 'axios';
import { getFileType } from '../../utility/functions';
import logoPdf from '../../assets/img/adobe-pdf-icon.svg'

//actions
import { feedbackActions } from '../../store/feedback/feedbackSlice'
import { moduliActions } from '../../store/moduli/moduliSlice'
import { inviaFile } from '../../store/messaggi/messaggiActions'

const InputFile = () => {
    const statusFiles = useSelector((state) => state.moduli.input_messaggio.statusFiles);
    const completati = useSelector((state) => state.moduli.input_messaggio.completati);
    const xs = useSelector((state) => state.moduli.input_messaggio.xs);
    const dettaglio_open = useSelector((state) => state.moduli.dettaglio.open);

    const divInput = useRef(null);

    const [form, setForm] = useState({
        file: []
    })

    const dispatch = useDispatch();

    const richiesta_axios = useRef(axios.CancelToken.source());

    useEffect(()=>{
        // devo dare il tempo all'animazione di apertura del dettaglio se no ho la vecchia width. Comunque questo resize all'apertura del dettaglio si fa solo in rare width intermedie tra il desktop e il mobile
        setTimeout(() => {
            if(divInput.current?.offsetWidth < 550){
                dispatch(moduliActions.setInputVisualization({xs: true}))
            }else{
                dispatch(moduliActions.setInputVisualization({xs: false}))
            }
        }, 301)
        
    }, [divInput.current, dettaglio_open])

    useEffect(() => {
        if(completati.length === form.file.length && form.file.length > 0){
            abort();
        }

        if(xs && form.file.length === 0){
            openDialog();
        }

        return () => {
            try {
                richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch {}
        }
    }, []);

    useEffect(() => {
        if(completati.length === form.file.length && form.file.length > 0){
            abort();
        }
    }, [completati, form.file]);

    const dropzoneRef = createRef();

    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };

    const getFiles = (acceptedFiles, rejectedFiles) => {
        let statusFiles = {};
        
        acceptedFiles.forEach(file => {

            let type = getFileType(file.type);

            if (type === 'immagine') {
                let img = new Image();
                let preview = URL.createObjectURL(file);
                img.onload = function () {
                    file.attributi = {
                        width: this.width,
                        height: this.height,
                        ratio: this.width / this.height
                    }
                }
                img.src = preview;
                file.preview = preview;
            }

            file.tipo = type;

            file.id = nanoid();

            statusFiles[file.id] = {
                action: 'wait',
                progress: 0
            }

        });

        let stop = false;
        rejectedFiles.forEach(file => {
            file.errors.forEach(err => {
                switch (err.code) {
                    case 'too-many-files':
                        // errori.push('Selezionati troppi file. Numero massimo di file: 5');
                        if(!stop){
                            dispatch(feedbackActions.addNotifica({ errori: ['Selezionati troppi file. Numero massimo di file: 5'] }));
                            stop = true; // in modo da far uscire un messaggio solo e non mille se hai scelto troppi files
                        }
                        break;
                    case 'file-too-large':
                        // errori.push(file.file.name + ': File troppo grande. Dimensione massima file: 20MB');
                        dispatch(feedbackActions.addNotifica({ errori: [file.file.name + ': File troppo grande. Dimensione massima file: 20MB'] }));
                        break;
                    default:
                        // errori.push(file.file.name + ': ' + err.message);
                        dispatch(feedbackActions.addNotifica({ errori: [file.file.name + ': ' + err.message] }));
                        break;
                }
            })
        })

        setForm({
            ...form,
            file: acceptedFiles
        })

        dispatch(moduliActions.setInputStatus({statusFiles: statusFiles, completati: []}));
    }

    const abort = () => {
        try {
            richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
        } finally {
            richiesta_axios.current = axios.CancelToken.source();
        }
        dispatch(moduliActions.setInputStatus({statusFiles: {}, completati: []}));
        dispatch(moduliActions.setInputType({ type: 'testo' }));
    }

    const submitHandler = () => {
        dispatch(inviaFile({
            form: form, 
            richiesta_axios: richiesta_axios.current
        }));
    }

    const style = {
        height: '100%',
        padding: 25,
    }

    return (
        <div ref={divInput} id="input-file">
            <div id='dropzone-wrapper'>
                <Dropzone ref={dropzoneRef} maxFiles={5} maxSize={20000000} noClick onDragLeave={abort} onDrop={getFiles}>
                    {({ getRootProps, getInputProps }) => (
                        <section className="h-100">
                            <div  {...getRootProps({ style })}>
                                <div id="dropzone-inside">
                                    <input {...getInputProps()} />
                                    <div id="anteprima-immagini" className="d-flex justify-content-center align-items-center h-100" >
                                        {form.file.length === 0 && (
                                            <h5>{xs ? 'Scegli file da inviare' : 'Trascina i file da inviare'}</h5>
                                        )}
                                        {form.file.length > 0 && (
                                            form.file.map(file => {
                                                return (
                                                    <div style={{ padding: 10, width: 280, height: 280 }} className="anteprima d-flex justify-content-center align-items-center flex-column border text-center m-3" key={file.name} >
                                                        {file.tipo === 'immagine' && <img src={file.preview} style={{ maxWidth: '80%', maxHeight: '80%' }} alt={file.name} />}
                                                        {file.tipo === 'pdf' && <img style={{ width: '60%' }} src={logoPdf} alt={file.name} />}
                                                        {file.tipo !== 'immagine' && file.tipo !== 'pdf' && <FontAwesomeIcon style={{ fontSize: 60 }} icon={faFile} />}
                                                        <p className="text-center text-break mb-0 pt-2">{file.name}</p>
                                                        {statusFiles[file.id]?.action !== 'wait' && (
                                                            // overlay
                                                            <div className={`anteprima-overlay rounded d-flex justify-content-center align-items-center flex-column text-primary ${statusFiles[file.id]?.action === 'error' && 'error'}`}>
                                                                {statusFiles[file.id]?.action !== 'error' && (
                                                                    <>
                                                                        <span className="text-capitalize mb-2"><h6><strong>{statusFiles[file.id]?.action}...</strong></h6></span>
                                                                        <ProgressBar style={{ height: 4 }} now={statusFiles[file.id]?.progress} />
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })
                                        )}
                                    </div>
                                    <div onClick={(e) => { e.stopPropagation(); abort() }} className="p-4 close_icon cursor-pointer">
                                        <FontAwesomeIcon className="text-primary" style={{ fontSize: 25 }} icon={faTimes} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
            <div className=" w-100 shadow-top border-top" id="input-messaggio">
                <Form onSubmit={(e) => { e.preventDefault() }} className="form-inline w-100 h-100 ">
                    <Form.Group className="w-100 h-100 d-flex align-items-center justify-content-center">
                        <div className={` ${xs ? 'd-flex justify-content-center align-items-center w-25' : ' w-15'} text-end `}>
                            <FontAwesomeIcon onClick={() => { abort() }} style={{ fontSize: 25 }} className="icona-close fs-5 align-middle text-primary me-3 cursor-pointer" icon={faTimes} />
                        </div>
                        <div className="flex-fill h-100 position-relative d-flex align-items-center justify-content-center">
                            <Button onClick={openDialog} className="btn-recording btn cursor-pointer btn-primary">Scegli file da inviare</Button>
                        </div>
                        <div className={` ${xs ? 'd-flex justify-content-center align-items-center w-25' : 'text-start w-15'}  `}>
                            <Button onClick={submitHandler} className={` ${xs ? '' : 'ms-3 px-4'}  cursor-pointer rounded-pill`} disabled={form.file.length === 0}><FontAwesomeIcon icon={faPaperPlane} /></Button>
                        </div>
                    </Form.Group>
                </Form>
            </div>
        </div>
    )
}

export default InputFile