import { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import axios from 'axios';

import { listaCategorieGet, listaUtentiGet } from '../../store/servizio/servizioActions'
import { moduliActions } from "../../store/moduli/moduliSlice"
import { ricercaActions } from '../../store/ricerca/ricercaSlice'

import { Navbar, Form, Button } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleLeft, faSearch, faSpinner, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons"

import RicercaCategorie from "./ricerca_categorie"
import RicercaUtenti from "./ricerca_utenti"
import Risultato from './risultato'


import { submitRicerca } from "../../store/ricerca/ricercaActions"

const Ricerca = () => {
    const lista_utenti = useSelector((state) => state.servizio.lista_utenti);
    const lista_categorie = useSelector((state) => state.servizio.lista_categorie);
    const userId = useSelector((state) => state.user.userId);
    const status = useSelector((state) => state.moduli.ricerca.status);
    const init = useSelector((state) => state.ricerca.init);
    const risultato = useSelector((state) => state.ricerca.risultato);

    const activeScroll = useRef(true); // così non c'è lo scroll quando riapro ricerca ma solo quando clicco
    const isMount = useRef(false); // se sto aprendo la pagina ricerca do un timeout allo scroll se no scrollo dritto

    const dispatch = useDispatch();

    const richiesta_axios = useRef(axios.CancelToken.source());

    useEffect(() => {
        return () => {
            try {
                richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch { }
        }
    }, [])

    useEffect(() => {
        let lista_chat = risultato.lista_chat;

        if (activeScroll.current) {
            if (lista_chat !== null) {
                let time = isMount.current ? 100 : 400; // quando apro una chat da url non è detto che riesca a fare lo scroll. Perchè partono mille chiamate e può darsi che arrivi la chat prima di lista utenti e lista categorie. il timeout di 100 aiuta. Avevo messo 0 e non funzionava quasi mai //XXX Forse sarebbe utili mettere un tasto scrolla ai risultati
                setTimeout(() => {
                    let topPos = document.getElementById('risultato').offsetTop;
                    document.getElementById('ricerca').scrollTop = topPos - 70;
                }, time);
            }
        }else{
            activeScroll.current = true;
        }

        isMount.current = true;

    }, [risultato])

    const formatListaUtenti = (lista_utenti) => {
        let lista_utenti_form = {};

        lista_utenti?.forEach((utente) => {
            if (utente.ChiaveOrdinamento !== userId) {
                lista_utenti_form[utente.ChiaveOrdinamento] = {
                    ...utente,
                    is_checked: false,
                    is_amministratore: false
                };
            } else {
                lista_utenti_form[utente.ChiaveOrdinamento] = {
                    ...utente,
                    is_checked: true,
                    is_amministratore: true
                };
            }
        });

        return lista_utenti_form
    }

    const initialState = {
        lista_categorie: lista_categorie,
        categoria: [...init.categoria],
        filtro_stato: init.filtro_stato,
        input_categoria: '',
        filtro_utenti: '',
        testo: init.testo,
        lista_utenti: init.lista_utenti !== null ? { ...init.lista_utenti } : formatListaUtenti(lista_utenti),
        archiviata: false
    };

    const [form, setForm] = useState(initialState);

    useEffect(() => {
        // questo aggiornamento di utenti e categorie si potrebbe evitare tanto sono già abbastanza aggiornate e c'è il tasto eventualmente
        dispatch(listaCategorieGet());
        dispatch(listaUtentiGet());
    }, []);

    const indietro = () => {
        dispatch(moduliActions.toggleRicerca());
    }

    const reset = () => {
        try {
            richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
        } finally {
            richiesta_axios.current = axios.CancelToken.source();
        }

        setForm({
            ...form,
            categoria: [],
            filtro_stato: '',
            testo: '',
            lista_utenti: formatListaUtenti(lista_utenti),
        });
        dispatch(ricercaActions.setRisultato({
            init: {
                categoria: [],
                filtro_stato: '',
                testo: '',
                lista_utenti: null,
            },
            lista_chat: null,
            lista_messaggi: null
        }))
    }

    const submitHandler = (e) => {
        let element = e.currentTarget;

        if (status === 'loading') {
            e.preventDefault()
        } else if (element.checkValidity() === true) {
            e.preventDefault();
            dispatch(moduliActions.setRicercaStatus({ status: 'loading' }));
            dispatch(submitRicerca({
                form: form,
                altri: null,
                richiesta_axios: richiesta_axios.current
            }));
        }
    }

    const onChangeHandler = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (target.type !== 'checkbox' || name === 'archiviata') {
            setForm({
                ...form,
                [name]: value
            });
        } else {

            const checked = target.checked;

            setForm({
                ...form,
                lista_utenti: {
                    ...form.lista_utenti,
                    [name]: {
                        ...form.lista_utenti[name],
                        is_checked: checked
                    }
                }
            });
        }
    }

    const caricaAltri = (altri) => {
        activeScroll.current = false; // così non fa scrollTop quando carica altri ma solo quando lancio una nuova ricerca
        dispatch(moduliActions.setRicercaStatus({ carica_altri_status: 'loading' }));
        dispatch(submitRicerca({
            form: form,
            altri: altri,
            richiesta_axios: richiesta_axios.current
        }));
    }

    return (
        <div className="h-100 w-100 bg-white">
            <Navbar expand="xs" id="titolo-lista-chat" className="border-bottom bg-light justify-content-between shadow-sm px-3">
                <Navbar.Brand>
                    Ricerca
                </Navbar.Brand>
                <span className="cursor-pointer text-primary" onClick={() => { indietro() }}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Indietro</span>
            </Navbar>
            <Form id="form-ricerca" autoComplete='off' onSubmit={submitHandler} className="m-0 p-0 w-100">
                <div id="ricerca" className="p-3">
                    <Form.Group className="mb-2">
                        <Form.Label><strong>Cerca testo</strong></Form.Label>
                        <div>
                            <Form.Control type="text" placeholder="Cerca" value={form.testo} name="testo" onChange={onChangeHandler} />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label><strong>Stato</strong></Form.Label>
                        <Form.Control onChange={onChangeHandler} name="filtro_stato" as="select">
                            <option value="">Tutti</option>
                            <option value="1">Aperto</option>
                            <option value="0">Chiuso</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label><strong>Archiviate</strong></Form.Label>
                        <Form.Control onChange={onChangeHandler} name="archiviata" as="select">
                            <option value="1">Tutte</option>
                            <option value="2">Solo Archiviate</option>
                            <option value="3">Solo NON Archiviate</option>
                        </Form.Control>
                    </Form.Group>
                    <RicercaCategorie form={form} setForm={setForm} onChangeHandler={onChangeHandler} />
                    <RicercaUtenti form={form} setForm={setForm} onChangeHandler={onChangeHandler} />
                    <hr></hr>
                    <Risultato caricaAltri={caricaAltri}/>
                </div>
                <div id="confirm" className="shadow-top border-top w-100 confirm-button d-flex justify-content-around align-items-center">
                    <small onClick={reset} className="text-primary cursor-pointer">Reset</small>
                    <Button className="w-50 rounded-pill" disabled={status === 'loading'} type="submit" variant="primary">{status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <> <FontAwesomeIcon className="me-3" icon={faSearch} /> Cerca</>}</Button>
                </div>
            </Form>
        </div>
    )
}

export default Ricerca