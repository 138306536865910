import { Alert, Row, Image } from "react-bootstrap"
import dimmia_titolo_logo from '../../assets/img/logo-sito.png'

const MobileDevice = () => {

    return (
        <div className="bg-secondary px-2 flex-fill">
            <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                <div className="py-4" id="dimmia-titolo-logo"><Image className="w-100" src={dimmia_titolo_logo} rounded /></div>
                <Alert variant={'warning'}>
                    La versione web di Dimmia può funzionare solo su schermi sufficientemente grandi.<br></br>
                    Clicca qui per scaricare l'app e utilizzare Dimmia su smartphone o tablet <Alert.Link href="https://it.wikipedia.org/wiki/Work_in_progress">App Dimmia</Alert.Link>.
                </Alert>
            </div>
        </div>
    )
}

export default MobileDevice