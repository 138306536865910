import chiamataAPI from "../../utility/chiamataAPI";
import costanti from "../../utility/costanti";

import { dettaglioActions } from './dettaglioSlice';
import { feedbackActions } from '../feedback/feedbackSlice';
import { moduliActions } from '../moduli/moduliSlice'

export const chatDettaglioGet = (payload) => {
    return async (dispatch, getState) => {
        dispatch(dettaglioActions.setStatus({ status: 'loading' }));

        const richiesta_axios = payload.richiesta_axios !== undefined ? payload.richiesta_axios : null;

        const chat_active = getState().conversazioni.chat_active;
        const account = getState().user.account;
        const userId = getState().user.userId;
        const token = getState().user.token;

        let data = {
            chat_id: chat_active,
            account: account,
            userId: userId
        }

        chiamataAPI(costanti.api_chat_dettaglio_get, data, token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(feedbackActions.addNotifica({ errori: ['Operazione Interrotta'] }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'chatDettaglioGet',
                        payload: payload
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                dispatch(dettaglioActions.setChatDettaglio({
                    dettagli: r.data.dettaglio,
                    lista_utenti: r.data.utenti,
                    is_amministratore: r.data.is_amministratore,
                    is_silenziata: r.data.is_silenziata,
                    is_archiviata: r.data.is_archiviata
                }))
            } else if (r.stato === 0) {
                if (r.errori[0] !== 'Operazione Interrotta') {
                    dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
                }
            }
        })
    }
};

export const submitSetStato = (payload) => {
    return async (dispatch, getState) => {
        dispatch(moduliActions.setDettaglio({ disable_buttons: true }));

        const richiesta_axios = payload.richiesta_axios;

        // XXX VALIDAZIONE ???

        const user = getState().user;

        let url = costanti.api_chat_dettaglio_modifica;

        let data = {
            chat_id: payload.chat_id,
            account: user.account,
            userId: user.userId,
            stato: payload.stato,
            piattaforma: 'web'
        }

        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(moduliActions.setDettaglio({ disable_buttons: false }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'submitSetStato',
                        payload: payload
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                // NON modifico dettaglio nello store perchè arriverà la modifica sul websocket che triggererà l'api cha aggiorna il chat dettaglio           
                dispatch(moduliActions.setDettaglio({ disable_buttons: false }));
                dispatch(feedbackActions.addNotifica({ success: 'Stato chat modificato correttamente' }));
            } else {
                // gestito da toggleChatDettaglio in conversazioni
                dispatch(moduliActions.setDettaglio({ disable_buttons: false }));
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
            }

        })

    }
};

// XXX qua valutare di spostare submitSetSilenziata e submitSetArchiviata in chat modifica
export const submitSetSilenziata = (payload) => {
    return async (dispatch, getState) => {
        dispatch(moduliActions.setDettaglio({ disable_buttons: true }));

        const richiesta_axios = payload.richiesta_axios;

        // XXX VALIDAZIONE ???

        const user = getState().user;

        let url = costanti.api_chat_utente_is_silenziata_update;

        let data = {
            chat_id: payload.chat_id,
            account: user.account,
            userId: user.userId,
            is_silenziata: payload.is_silenziata,
        }

        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(moduliActions.setDettaglio({ disable_buttons: false }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'submitSetSilenziata',
                        payload: payload
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                // NON modifico dettaglio nello store perchè arriverà la modifica sul websocket che triggererà l'api cha aggiorna il chat dettaglio           
                dispatch(moduliActions.setDettaglio({ disable_buttons: false }));
                dispatch(feedbackActions.addNotifica({ success: 'Impostazione notifiche chat modificata correttamente' }));
            } else {
                // gestito da toggleChatDettaglio in conversazioni
                dispatch(moduliActions.setDettaglio({ disable_buttons: false }));
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
            }
        })
    }
};

export const submitSetArchiviata = (payload) => {
    return async (dispatch, getState) => {
        dispatch(moduliActions.setDettaglio({ disable_buttons: true }));

        const richiesta_axios = payload.richiesta_axios;

        const user = getState().user;

        let url = costanti.api_chat_utente_is_archiviata_update;

        let data = {
            chat_id: payload.chat_id,
            account: user.account,
            userId: user.userId,
            is_archiviata: payload.is_archiviata,
        }

        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(moduliActions.setDettaglio({ disable_buttons: false }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'submitSetArchiviata',
                        payload: payload
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                // NON modifico dettaglio nello store perchè arriverà la modifica sul websocket che triggererà l'api cha aggiorna il chat dettaglio           
                dispatch(moduliActions.setDettaglio({ disable_buttons: false }));
                dispatch(feedbackActions.addNotifica({ success: 'Impostazione chat archivia modificata correttamente' }));
            } else {
                // gestito da toggleChatDettaglio in conversazioni
                dispatch(moduliActions.setDettaglio({ disable_buttons: false }));
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
            }
        })
    }
};