import { useSelector } from "react-redux";

const Partecipanti = (props) =>{  
    const userId = useSelector((state) => state.user.userId);
    const lista_utenti = useSelector((state) => state.servizio.lista_utenti);
    const chat = props.chat;
    let text = '';
    if(chat.utenti_chat?.length === 2 && lista_utenti){
        let id_altro_utente = chat.utenti_chat.find( utente => utente !== userId);
        let altro_utente = lista_utenti?.find(ut => ut.ChiaveOrdinamento == id_altro_utente);
        if(altro_utente == undefined ){
            text = '1 altro partecipante';
        }else{
            text = altro_utente?.nome + ' ' + altro_utente?.cognome;
        }
    }else{
        text = chat.utenti_chat?.length + ' partecipanti'
    }

    return text
}

export default Partecipanti