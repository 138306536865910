import axios from 'axios';
import chiamataAPI from "../../utility/chiamataAPI";
import costanti from "../../utility/costanti";

// actions
import { loginActions } from "./loginSlice";
import { userActions } from "../user/userSlice";
import { feedbackActions } from '../feedback/feedbackSlice';
import { chatDettaglioGet, submitSetStato, submitSetSilenziata } from '../dettaglio/dettaglioActions';
import { submitAggiungiUtenti, submitModificaDettagli, submitModificaAmministratore, submitEliminaUtenti } from '../modifica/modificaActions';
import { submitRicerca } from '../ricerca/ricercaActions';
import { listaUtentiGet, listaCategorieGet } from '../servizio/servizioActions';
import { submitCreaChat } from '../conversazioni/conversazioniActions';

export const servizioTipoGet = (payload) => {
    return async (dispatch) => {
        dispatch(loginActions.setStatus({ status: 'loading' }));

        chiamataAPI(costanti.api_servizio_tipo_get, {}, '', payload.richiesta_axios).then(r => {
            if (r.stato === 1) {
                dispatch(loginActions.setListaServizio({ lista_servizio: r.data }))
            } else {
                if (r.errori[0] !== 'Operazione Interrotta') {
                    dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
                }
            }

        })
    }
};

export const submitAccount = (payload) => {
    return async (dispatch, getState) => {
        dispatch(loginActions.setStatus({ status: 'loading' }));

        const richiesta_axios = payload.richiesta_axios;

        const servizio = getState().login.servizio;
        const account = payload.account;

        let data = {
            account: servizio + '_' + account
        }

        chiamataAPI(costanti.api_servizio_get, data, '', richiesta_axios).then(r => {
            if (r.stato === 1 && r.data.is_abilitato === true) {
                dispatch(loginActions.setAccount({
                    account: servizio + '_' + account,
                    ragione_sociale: r.data.ragione_sociale !== undefined ? r.data.ragione_sociale : account
                })) // qua salverò la ragione sociale ritornada dal backend quando il backend la ritornerà
                dispatch(feedbackActions.addNotifica({ success: 'Account validato correttamente' }));
            } else if (r.stato === 1 && r.data.is_abilitato === false){
                dispatch(feedbackActions.addNotifica({ errori: ["L'account è disabilitato. Abilitare l'account per poter accedere"] }));
                dispatch(loginActions.setStatus({ status: 'ok' }));
            }else{
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
                dispatch(loginActions.setStatus({ status: 'ok' }));
            }
        })
    }
};

export const submitLogin = (payload) => {
    return async (dispatch, getState) => {
        dispatch(loginActions.setStatus({ status: 'loading' }));

        const richiesta_axios = payload.richiesta_axios;

        // XXX VALIDAZIONE ???

        const username = payload.username;
        const password = payload.password;
        const account = getState().login.account;

        let data = {
            account,
            username,
            password
        }

        chiamataAPI(costanti.api_login_account, data, '', richiesta_axios).then(r => {
            if (r.stato === 1) {
                dispatch(loginActions.reset());
                dispatch(userActions.login({
                    username: username,
                    account: r.data.account,
                    userId: r.data.userId,
                    nome: r.data.nome + " " + r.data.cognome,
                    token: r.data.token,
                    ragione_sociale: r.data.ragione_sociale,
                    servizio_libemax: r.data.servizio_libemax
                }));
                dispatch(feedbackActions.addNotifica({ success: 'Login effettuato con successo' }));
            } else {
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
                dispatch(loginActions.setStatus({ status: 'ok' }));
            }
        })
    }
};

export const submitAutologin = (payload) => {
    return async (dispatch, getState) => {
        dispatch(loginActions.setStatus({ status: 'loading' }));

        const richiesta_axios = payload.richiesta_axios;
        const autologin_token = payload.alt;

        let data = {
            autologin_token
        }

        chiamataAPI(costanti.api_autologin, data, '', richiesta_axios).then(r => {
            if (r.stato === 1) {
                dispatch(userActions.login({
                    username: r.data.username,
                    account: r.data.account,
                    userId: r.data.userId,
                    nome: r.data.nome + " " + r.data.cognome,
                    token: r.data.token,
                    ragione_sociale: r.data.ragione_sociale,
                    servizio_libemax: r.data.servizio_libemax
                }));
                dispatch(feedbackActions.addNotifica({ success: 'Login effettuato con successo' }));
                dispatch(loginActions.reset());
            } else {
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
                dispatch(loginActions.reset());
            }
        })
    }
};

export const submitAutocompilazione = (payload) => {
    return async (dispatch, getState) => {
        dispatch(loginActions.setStatus({ status: 'loading' }));

        // in tutti i casi verrà settato poi gestito_autologin: true perchè qua si finisce o nel login.reset() o in setaccount() o in setAutocompilazione()
        const servizio = payload.servizio;
        const account = payload.account;
        const username = payload.username;

        chiamataAPI(costanti.api_servizio_tipo_get, {}, '', payload.richiesta_axios).then(r => {
            if (r.stato === 1) {
                let servizio_selezionato = r.data.find(ser => {
                    return ser.ChiaveOrdinamento === servizio
                })

                if (servizio_selezionato) {
                    let submitAccount = {
                        account: servizio + '_' + account
                    }

                    chiamataAPI(costanti.api_servizio_get, submitAccount, '', payload.richiesta_axios).then(r => {
                        if (r.stato === 1 && r.data.is_abilitato === true) {
                            // settare servizio account e (se c'è) username
                            dispatch(loginActions.setAutocompilazione({
                                servizio_libemax: servizio_selezionato.servizio_libemax,
                                servizio: servizio_selezionato.ChiaveOrdinamento,
                                servizio_nome: servizio_selezionato.descrizione,
                                account: servizio_selezionato.ChiaveOrdinamento + '_' + account,
                                ragione_sociale: r.data.ragione_sociale !== undefined ? r.data.ragione_sociale : account,
                                username: username ? username : ''
                            }))
                        } else {
                            // settare solo servizio
                            dispatch(loginActions.setServizio({
                                servizio: servizio_selezionato.ChiaveOrdinamento,
                                servizio_nome: servizio_selezionato.descrizione,
                                servizio_libemax: servizio_selezionato.servizio_libemax
                            }))
                        }
                    })
                }

            } else {
                dispatch(loginActions.reset());
            }

        })
    }
};

export const submitLoginModale = (payload) => {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(feedbackActions.setModaleTokenScaduto({ ...state.feedback.modale_token_scaduto, status: 'loading' }));

        const richiesta_axios = axios.CancelToken.source();


        const username = state.user.username;
        const password = payload.password;
        const account = state.user.account;

        let data = {
            account,
            username,
            password
        }

        // metterci un bel controllo se effettivamente ho username e account

        chiamataAPI(costanti.api_login_account, data, '', richiesta_axios).then(r => {
            if (r.stato === 1) {
                dispatch(feedbackActions.closeModaleTokenScaduto());
                dispatch(userActions.login({
                    username: username,
                    account: r.data.account,
                    userId: r.data.userId,
                    nome: r.data.nome + " " + r.data.cognome,
                    token: r.data.token,
                    ragione_sociale: r.data.ragione_sociale,
                    servizio_libemax: r.data.servizio_libemax
                }));
                dispatch(feedbackActions.addNotifica({ success: 'Login effettuato con successo' }));

                // CALLBACK - riprendo azione che avevo interrotto
                if (payload.callback_action?.length > 0) {
                    let action = JSON.parse(payload.callback_action);
                    const richiesta_axios = axios.CancelToken.source();
                    switch (action.type) {
                        case 'chatDettaglioGet':
                            dispatch(chatDettaglioGet({
                                ...action.payload,
                                richiesta_axios: richiesta_axios
                            }));
                            break;
                        case 'submitSetStato':
                            dispatch(submitSetStato({
                                ...action.payload,
                                richiesta_axios: richiesta_axios
                            }));
                            break;
                        case 'submitSetSilenziata':
                            dispatch(submitSetSilenziata({
                                ...action.payload,
                                richiesta_axios: richiesta_axios
                            }));
                            break;
                        case 'submitAggiungiUtenti':
                            dispatch(submitAggiungiUtenti({
                                ...action.payload,
                                richiesta_axios: richiesta_axios
                            }));
                            break;
                        case 'submitModificaDettagli':
                            dispatch(submitModificaDettagli({
                                ...action.payload,
                                richiesta_axios: richiesta_axios
                            }));
                            break;
                        case 'submitModificaAmministratore':
                            dispatch(submitModificaAmministratore({
                                ...action.payload,
                                richiesta_axios: richiesta_axios
                            }));
                            break;
                        case 'submitEliminaUtenti':
                            dispatch(submitEliminaUtenti({
                                ...action.payload,
                                richiesta_axios: richiesta_axios
                            }));
                            break;
                        case 'submitRicerca':
                            dispatch(submitRicerca({
                                ...action.payload,
                                richiesta_axios: richiesta_axios
                            }));
                            break;
                        case 'listaUtentiECategorieGet':
                            dispatch(listaCategorieGet());
                            dispatch(listaUtentiGet());
                            break;
                        case 'submitCreaChat':
                            dispatch(submitCreaChat({
                                ...action.payload,
                                richiesta_axios: richiesta_axios
                            }));
                            break;
                        default:
                            break;
                    }
                }

            } else {
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
                dispatch(feedbackActions.setModaleTokenScaduto({ ...state.feedback.modale_token_scaduto, status: 'ok' }));
            }
        })

    }
};
