import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
//bootstrap
import ListGroup from 'react-bootstrap/ListGroup'

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons'

import {submitSetStato} from '../../store/dettaglio/dettaglioActions'

const ChatDettaglioStato = () => {
    const [showAction, setShowAction] = useState(false);

    const is_amministratore = useSelector((state) => state.dettaglio.is_amministratore);
    const stato = useSelector((state) => state.dettaglio.dettagli.stato);
    const chat_id = useSelector((state) => state.dettaglio.dettagli.ChiavePartizione);
    const disable_buttons = useSelector((state) => state.moduli.dettaglio.disable_buttons);

    const dispatch = useDispatch();

    const richiesta_axios = useRef(axios.CancelToken.source());

    useEffect(() => {
        return () => {
            try {
                // XXX qua ha senso che se uno cambia chat a metà dell'operazione di silezia/attiva notifiche gli annullo l'operazione??
                // richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch {}
        }
    }, [])

    const submitHandler = () => {
        dispatch(submitSetStato({
            stato: stato === 0 ? 1 : 0, 
            chat_id: chat_id,
            richiesta_axios: richiesta_axios.current
        }))
    }


    return (
        <>
            <div className="py-2">
                <h6 className="w-100 text-center">Stato</h6>
                <ListGroup className="my-2" >
                    <ListGroup.Item action disabled={!is_amministratore || disable_buttons} onClick={() => { submitHandler() }} onMouseEnter={() => setShowAction(true)} onMouseLeave={() => setShowAction(false)}>
                        {showAction && is_amministratore && <><FontAwesomeIcon className={`me-2 ${stato === 0 ? 'text-success' : 'text-danger'}`} icon={stato === 0 ? faLockOpen : faLock} />{stato === 0 ? 'Riapri Chat' : 'Chiudi Chat'}</>}
                        {(!showAction || !is_amministratore) && <><FontAwesomeIcon className={`me-2 text-dark`} icon={stato === 0 ? faLock : faLockOpen} /><span className="text-capitalize">{stato === 0 ? 'chiusa' : 'aperta'}</span></>}
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </>
    )
}

export default ChatDettaglioStato