import { useSelector, useDispatch } from "react-redux"

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faBellSlash, faLock, faArchive } from '@fortawesome/free-solid-svg-icons'

// react flip
import { Flipped } from 'react-flip-toolkit'

// components
import Partecipanti from "../tools/partecipanti"
import PrintCategorie from "../tools/print_categorie"

// utility
import { formatData } from "../../utility/functions"

//img
import logino from '../../assets/img/dimmia-def-icona-bianco_512.png'

import { Col, Row, Figure } from "react-bootstrap"

import { conversazioniActions } from "../../store/conversazioni/conversazioniSlice"

const ElencoChat = () => {
    const elenco_chat = useSelector((state) => state.archiviate?.lista_chat);
    const chat_active = useSelector((state) => state.conversazioni.chat_active);
    const userId = useSelector((state) => state.user.userId);
    const dispatch = useDispatch();

    const setChatActive = (chat_id) => {
        dispatch(conversazioniActions.setChatActive({ chat_active: chat_id, message_active: null }));
    }

    return (
        <>
            {elenco_chat === [] && (
                <div className="hw-center">
                    <Figure className="text-center">
                        <Figure.Image
                            width={60}
                            alt="Logo Dimmia"
                            src={logino}
                        />
                        <Figure.Caption className="text-center">
                            Nessuna Chat Archiviata
                        </Figure.Caption>
                    </Figure>
                </div>
            )}
            {elenco_chat === null && (
                <div className="pt-5 text-center">
                    <FontAwesomeIcon spin size='lg' className="text-primary fs-2" icon={faSpinner} />
                </div>
            )}
            {elenco_chat !== null && elenco_chat !== [] && (
                elenco_chat.map(chat => {
                    let is_archiviata = chat.utenti_is_archiviata?.includes(userId);
                    if (is_archiviata) {
                        return (
                            <Row key={chat.ChiavePartizione} className={`lista py-2 rounded mx-0 px-0 ${chat_active === chat.ChiavePartizione ? 'active shadow-sm' : ''} ${parseInt(chat.stato) === 0 ? 'locked' : ''}`}>
                                <Col onClick={() => { setChatActive(chat.ChiavePartizione) }} className="text-truncate" xs="12">
                                    <Row className="m-0 p-0">
                                        <Col className="m-0 p-0" xs={10}>
                                            <p className="mb-0 text-truncate">
                                                {parseInt(chat.stato) === 0 ? <FontAwesomeIcon className="me-1" style={{ fontSize: 13 }} icon={faLock} /> : ''}
                                                {is_archiviata === true ? <FontAwesomeIcon className="me-1" style={{ fontSize: 13 }} icon={faArchive} /> : ''}
                                                {chat.titolo}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="m-0 p-0">
                                        <Col className="m-0 p-0" xs={10}><small className="text-muted"><Partecipanti chat={chat} /></small></Col>
                                        <Col className="m-0 p-0 xs-1 text-end" xs={2}>
                                            {chat.is_silenziata ? <FontAwesomeIcon style={{ fontSize: 12 }} className="align-sub me-2" icon={faBellSlash} /> : ''}
                                        </Col>
                                    </Row>
                                    <Row className="m-0 p-0">
                                        <Col className="m-0 p-0 xs-11 text-truncate" xs={6} lg={8}><PrintCategorie chat={chat} /></Col>
                                        <Col className="m-0 p-0 xs-1 text-end" xs={6} lg={4}><small className="data text-truncate">{formatData(chat.last_updated)}</small></Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    } else {
                        return ''
                    }
                })
            )}
        </>
    )
}

export default ElencoChat