import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy, faThumbsUp } from '@fortawesome/free-solid-svg-icons'

import { messaggioElimina } from '../../store/messaggi/messaggiActions';

const MessaggioButtons = (props) => {
    const dispatch = useDispatch();
    const [copied, setCopied] = useState(false);
    const [openModalElimina, setOpenModalElimina] = useState(false);
    const target = useRef(null);
    const msg = props.msg;
    const setIsHover = props.setIsHover;

    const copyText = () => {
        navigator.clipboard.writeText(msg.testo);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }

    const submitEliminaMessaggio = () =>{
        dispatch(messaggioElimina({ chat_id: msg.ChiavePartizione, messaggio_chiave_ordinamento: msg.ChiaveOrdinamento }));
        setOpenModalElimina(false);
    }

    return (
        <div className={`messaggio-buttons d-flex align-items-center px-2`}>
            {msg.tipo == 'testo' && navigator.clipboard && (
                <>
                    <div ref={target} onClick={copyText} className="shadow btn-action text-light cursor-pointer ms-1">
                        <FontAwesomeIcon size={'sm'} icon={copied ? faThumbsUp : faCopy} />
                    </div>
                </>
            )}
            <div onClick={() => { setOpenModalElimina(true) }} className="shadow btn-action text-light cursor-pointer ms-1"><FontAwesomeIcon size={'sm'} icon={faTrash} /></div>
            <Modal show={openModalElimina} onExiting={()=>{setIsHover(false)}}>
                <Modal.Body>Eliminare <strong>DEFINITIVAMENTE</strong> il messaggio per <strong>TUTTI</strong> gli utenti?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className={'rounded-pill'} onClick={() => {setOpenModalElimina(false)} }>
                        Annulla
                    </Button>
                    <Button variant="danger" className={'rounded-pill'} onClick={submitEliminaMessaggio}>
                        Elimina
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )


}

export default MessaggioButtons