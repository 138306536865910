import { useState } from "react";
import { useSelector } from 'react-redux';
// utility
import { formatData } from '../../utility/functions';
// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faSpinner } from '@fortawesome/free-solid-svg-icons'

import MessaggioButtons from "./messaggio_buttons";

// logo pdf
import logoPdf from '../../assets/img/adobe-pdf-icon.svg'

const MessaggioFile = (props) => {
    const [ isHover, setIsHover ] = useState(false);
    const is_amministratore = useSelector((state) => state.dettaglio.is_amministratore);
    const msg = props.msg;

    return (
        <div 
            id={msg.ChiaveOrdinamento} 
            className={`w-100 d-flex py-2 msg_${msg.who}`}
            onMouseEnter={()=>{setIsHover(true)}}
            onMouseLeave={()=>{setIsHover(false)}}
        >
            <div className={`msg border shadow-sm rounded ${msg.active && 'active'} ${msg.eliminazione_in_corso === true && 'opacity-25'}`}>
                <div className="nome"><strong>{msg.who !== 'admin' && msg.who !== 'out' && msg.from_name}</strong></div>
                {msg.url === '' && (
                    <div className="file_wrapper px-4 py-4 text-center">
                        <FontAwesomeIcon spin size="lg" className="text-primary" icon={faSpinner} />
                        {/* <div className="text-break">
                            {msg.nome_originale}
                        </div> */}
                    </div>
                )}
                {msg.url !== '' && (
                    <div className="file_wrapper px-3 pt-3 text-center">
                        <a target="_blank" donwload={msg.nome_originale} href={msg.url} rel="noreferrer">
                            {
                                msg.tipo === 'pdf' ?
                                    <img src={logoPdf} alt="File PDF" />
                                    :
                                    <FontAwesomeIcon style={{ fontSize: 50 }} icon={faFile} />
                            }
                            <p className="mt-2 mb-0 text-break">{msg.nome_originale}</p>
                        </a>
                    </div>
                )}

                <span className="data">{msg.who !== 'admin' ? formatData(msg.messaggio_data_ora) : ''}</span>
            </div>
            {isHover && is_amministratore && msg.who !== 'admin' && <MessaggioButtons msg={msg} setIsHover={setIsHover}/>}
        </div>
    )


}

export default MessaggioFile