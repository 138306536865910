import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

//bootstrap
import { Button, Figure } from "react-bootstrap"

// utility
import message from '../../utility/ws_message'

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots, faSpinner } from '@fortawesome/free-solid-svg-icons'

//components
import logino from '../../assets/img/dimmia-def-icona-bianco_512.png'
import TitoloChat from "./titolo_chat"
import InputMessaggio from "../input_messaggio"
import ElencoMessaggi from "./elenco_messaggi"

// actions
import { moduliActions } from "../../store/moduli/moduliSlice"

const Messaggi = () => {
    const lista_messaggi = useSelector((state) => state.messaggi.lista_messaggi);
    const riferimento_div_scroll = useSelector((state) => state.messaggi.riferimento_div_scroll);
    const chat_messaggi = useSelector((state) => state.messaggi.chat_messaggi);
    const user = useSelector((state) => state.user);
    const messaggiLastEvaluatedKey = useSelector((state) => state.messaggi.messaggiLastEvaluatedKey);
    const foward_messaggiLastEvaluatedKey = useSelector((state) => state.messaggi.foward_messaggiLastEvaluatedKey);
    const status = useSelector((state) => state.moduli.lista_messaggi.status);
    const btn_carica_altri_status = useSelector((state) => state.moduli.lista_messaggi.btn_carica_altri_status);
    const textMultiline = useSelector((state) => state.moduli.input_messaggio.multiline);

    const [stoCaricandoAltri, setStoCaricandoAltri] = useState(true);

    const dispatch = useDispatch();

    // XXX qua parte il carica altri a random!!!!!! Gestire!!!
    const caricaAltri = (foward = false) => {
        setStoCaricandoAltri(true);
        dispatch({ type: 'WS_SEND', payload: { msg: message.chat_messaggi_get(chat_messaggi, user.userId, user.account, !foward ? messaggiLastEvaluatedKey :  foward_messaggiLastEvaluatedKey, foward) } });
        dispatch(moduliActions.setMessaggiStatus({ btn_carica_altri_status: 'loading' }));
    }

    const scrollToDiv = () => {
        let element = document.getElementById('lista-messaggi');
        let HeightNewMsg = 0;
        if (lista_messaggi?.length > 0 && element) {
            HeightNewMsg = document.getElementById(lista_messaggi[lista_messaggi.length - 1].ChiaveOrdinamento)?.offsetHeight;
        }
        // qua è difficile capire se l'utente sa leggendo messaggi sopra o cosa: se inserisco un nuovo messaggio singolo prima stampo poi entro qua. Se ero in fondo e ho inserito un immagien lo scroll dal fondo sarà 300px( altezza immagine) + padding vari. se metto un messaggio di testo sarà alto pixel diversi a seconda della lunghezza del testo. La soluzione precisa passa sicuramente attraverso andare a prendere l'ultimo messaggio e vedere quanto è alto
        // se c'è un div di riferimento lo mostro, altrimenti se sei in fondo e inserisco il nuovo messaggio lo porto dentro altrimenti ti lascio dove sei (con carica altri react da solo non ti lascia dove sei)

        if (riferimento_div_scroll !== null) {
            let divPos = document.getElementById(riferimento_div_scroll)?.offsetTop;
            element.scrollTop = divPos - 170;
        } else if (element.scrollHeight - element.scrollTop < (element.clientHeight + 180 + HeightNewMsg)) {
            element.scrollTop = element.scrollHeight;
        }
    }
    
    useEffect(() => {
        scrollToDiv();
        setStoCaricandoAltri(false);
    }, [lista_messaggi]);

    const openDropZone = () => {
        dispatch(moduliActions.setInputType({ type: 'file' }));
    }

    const scrollHandler = (event) =>{
        const target = event.target;
        
        if(target.scrollTop === 0 && stoCaricandoAltri === false && messaggiLastEvaluatedKey !== null && status === 'ok'){ // ho dovuto aggiungere status ok perchè quando cambiava lo status cambiava il contenuto del div veniva visto come uno scroll e quindi partiva la richiesta di altri messaggi
            caricaAltri();
        }else if(target.scrollTop + target.clientHeight === target.scrollHeight && stoCaricandoAltri === false && foward_messaggiLastEvaluatedKey !== null && status === 'ok'){
            caricaAltri(true);
        }
    }

    return (
        <>
            <TitoloChat />
            <div id="lista-messaggi" onDragEnter={openDropZone} onScroll={scrollHandler} className={`p-3 ${textMultiline ? 'multiline' : ''}`}>
                {lista_messaggi?.length > 0 && status === 'ok' && (
                    <>
                        {messaggiLastEvaluatedKey !== null && (
                            <div className="text-center py-2">
                                <Button disabled={btn_carica_altri_status === 'loading'} onClick={() => { caricaAltri() }} className="btn btn-primary btn-sm  rounded-pill" >{btn_carica_altri_status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <><FontAwesomeIcon icon={faCommentDots} /> Carica Altri</>}</Button>
                            </div>
                        )}
                        <ElencoMessaggi />
                        {foward_messaggiLastEvaluatedKey !== null && (
                            <div className="text-center py-2">
                                <Button disabled={btn_carica_altri_status === 'loading'} onClick={() => { caricaAltri(true) }} className="btn btn-primary btn-sm  rounded-pill" >{btn_carica_altri_status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <><FontAwesomeIcon icon={faCommentDots} /> Carica Altri</>}</Button>
                            </div>
                        )}
                    </>
                )}
                {!lista_messaggi?.length > 0 && status === 'ok' && (
                    <div className="whcenter">
                        <Figure className="text-center">
                            <Figure.Image
                                width={60}
                                alt="Logo Dimmia"
                                src={logino}
                            />
                            <Figure.Caption className="text-center">
                                Nessun Messaggio
                            </Figure.Caption>
                        </Figure>
                    </div>
                )}
                {status === 'loading' && (
                    <div className="whcenter text-primary">
                        <FontAwesomeIcon className="fs-2" spin size="lg" icon={faSpinner} />
                    </div>
                )}
            </div>
            <InputMessaggio />
        </>
    )
}

export default Messaggi