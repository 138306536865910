import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Select from 'react-select';

import { Nav } from 'react-bootstrap'

import { getLetterColor } from '../../utility/functions'

import { moduliActions } from '../../store/moduli/moduliSlice'

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const selectStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return{
            ...styles,
            width: '100%'
        }
    },
    menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return{
            ...styles,
            width: 'auto',
            minWidth: '100%',
            padding: '0 5px 0 5px',
            webkitScrollbar: 'display: none;'
        }
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.value ? getLetterColor(data.value) : null;

        if (color) {
            return {
                ...styles,
                color: '#000',
                ...dot(color)
            };
        } else {
            return {
                ...styles
            };
        }
    },
    input: styles => ({ ...styles, overflow: 'ellipsis' }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => {
        const color = data.value !== '' ? getLetterColor(data.value) : null;

        if (color) {
            return {
                ...styles,
                ...dot(color)
            };
        } else {
            return {
                ...styles
            };
        }
    },
};

const Filtri = () => {
    const lista_chat = useSelector((state) => state.conversazioni.lista_chat);
    const column = useSelector((state) => state.moduli.lista_chat.filtri.column);
    const stato = useSelector((state) => state.moduli.lista_chat.filtri.stato);

    const divFiltri = useRef(null);
    
    const getListaCategorie = (lista) => {
        let categorie = [];
        let ritorno = [{
            value: '',
            label: 'Tutte le Categorie'
        }];

        lista?.forEach(chat => {
            categorie = [...new Set(categorie.concat(chat.categoria))];
        })

        categorie.forEach(cat => {
            ritorno.push({
                value: cat,
                label: cat
            })
        });

        return ritorno;
    }

    const [listaCategorie, setListaCategorie] = useState(getListaCategorie(lista_chat));

    

    const dispatch = useDispatch();

    useEffect(()=>{
        if(divFiltri.current?.offsetWidth < 360){
            dispatch(moduliActions.setFiltriListaChat({column: true}))
        }else{
            dispatch(moduliActions.setFiltriListaChat({column: false}))
        }
    }, [divFiltri.current])

    useEffect(() => {
        setListaCategorie(getListaCategorie(lista_chat));
    }, [lista_chat])

    //XXX fare lista categorie dinamica
    const onChange = (e) => {
        dispatch(moduliActions.setFiltriListaChat({
            categoria: e.value
        }));
    }

    const onSelect = (e) => {
        dispatch(moduliActions.setFiltriListaChat({
            stato: e === '-1' ? null : parseInt(e)
        }));
    }

    return (
        <div id="filtri"  ref={divFiltri} className={` ${ column ? 'flex-column' : 'flex-row'} d-flex`}>
            <Nav variant="tabs" className="w-auto flex-nowrap" defaultActiveKey={stato !== null ? stato : -1 } onSelect={onSelect} >
                <Nav.Item>
                    <Nav.Link eventKey={1} >Aperte</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={0} >Chiuse</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={-1}>Tutte</Nav.Link>
                </Nav.Item>
            </Nav>
            <div  className={` ${  column ? 'ps-0' : 'ps-2'} flex-fill  border-bottom d-flex align-items-center`}>
                <Select className="filtro-categorie w-100" classNamePrefix="react-select" defaultValue={listaCategorie[0]} options={listaCategorie} onChange={onChange} styles={selectStyles} />
            </div>
        </div>
    )
}

export default Filtri