import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

// react flip
import { Flipper } from 'react-flip-toolkit'
import { Flipped } from 'react-flip-toolkit';

//bootstrap
import { Navbar, Button, Container } from 'react-bootstrap'

//components
import Filtri from './filtri';
import ElencoChat from './elenco_chat';

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faPlus, faFilter, faUserCircle, faSpinner, faCommentDots, faArchive } from '@fortawesome/free-solid-svg-icons'

//actions
import { logout } from '../../store/user/userActions';
import { moduliActions } from '../../store/moduli/moduliSlice'

import message from '../../utility/ws_message';

const ListaChat = () => {
    const user = useSelector((state) => state.user);
    const lista_chat = useSelector((state) => state.conversazioni.lista_chat);
    const filtri = useSelector((state) => state.moduli.lista_chat.filtri);
    const chatLastEvaluatedKey = useSelector((state) => state.conversazioni.chatLastEvaluatedKey);
    const btn_status = useSelector((state) => state.moduli.lista_chat.carica_altre_status);
    const column = useSelector((state) => state.moduli.lista_chat.filtri.column);

    const [stoCaricandoAltri, setStoCaricandoAltri] = useState(false);

    const dispatch = useDispatch();

    useEffect(()=>{
        setStoCaricandoAltri(false);
    },[lista_chat])

    const openCreaChat = () => {
        dispatch(moduliActions.toggleCreaChat());
    }

    const openRicerca = () => {
        dispatch(moduliActions.toggleRicerca());
    }

    const openArchiviate = () => {
        dispatch(moduliActions.toggleArchiviate());
    }

    const Logout = () => {
        dispatch(logout());
    }

    const caricaAltre = () => {
        setStoCaricandoAltri(true);
        dispatch(moduliActions.setListaCaricaAltreStatus({ carica_altre_status: 'loading' }));

        dispatch({
            type: 'WS_SEND',
            payload: {
                msg: message.chat_lista_get(user.userId, user.account, chatLastEvaluatedKey)
            }
        });
    }

    const onScrollHandler = (e) =>{
        const target = e.target;

        if(target.scrollHeight - target.scrollTop === target.clientHeight && chatLastEvaluatedKey !== null && stoCaricandoAltri === false){
            caricaAltre();
        }
    }

    return (
        <div className="h-100 w-100">
            <Navbar expand="xs" id="titolo-lista-chat" className="border-bottom px-2 py-1 shadow-sm bg-light justify-content-between px-3 flex-nowrap">
                <div className="d-flex align-items-center text-truncate m-0 p-0">
                    <div>
                        <FontAwesomeIcon className="text-secondary fs-3" icon={faUserCircle}></FontAwesomeIcon>
                    </div>
                    <div className="ms-2 text-truncate">
                        <p className="m-0 text-truncate" style={{ fontSize: 18, fontWeight: 500 }}>{user.nome}</p>
                        <p className="m-0 text-truncate" style={{ fontSize: 16, fontWeight: 500 }}><small>{user.ragione_sociale}</small></p>
                    </div>
                </div>
                <div style={{ width: 70 }} className="text-nowrap cursor-pointer text-primary " onClick={() => { Logout() }}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</div>
            </Navbar>
            <div id="lista-chat-wrapper">
                <Filtri />
                <Container id="lista-chat" fluid onScroll={onScrollHandler} className={` ${column ? 'column' : ''} px-0 pt-2`}>
                    <Flipper flipKey={JSON.stringify(lista_chat) + JSON.stringify(filtri)}>
                        <ElencoChat />
                        {chatLastEvaluatedKey !== null && (
                            <Flipped key={'bottone_carica_altre'} flipId={'bottone_carica_altre'}>
                                <div className="py-3 text-center">
                                    <Button disabled={btn_status === 'loading'} onClick={caricaAltre} className="btn btn-primary btn-sm  rounded-pill" >{btn_status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <><FontAwesomeIcon icon={faCommentDots} /> Carica Altre</>}</Button>
                                </div>
                            </Flipped>
                        )}
                    </Flipper>
                </Container>
                <div id="btn-actions-wrapper" className="d-flex justify-content-around align-items-center border-top shadow-top">
                    <div id="btn-archiviate" onClick={() => { openArchiviate() }} title="Archiviate" className="shadow text-white cursor-pointer btn-primary"><FontAwesomeIcon icon={faArchive} /></div>
                    <div id="btn-filtra-lista-chat" onClick={() => { openRicerca() }} title="Ricerca" className="shadow text-white cursor-pointer btn-primary"><FontAwesomeIcon icon={faFilter} /></div>
                    <div id="btn-nuova-chat" onClick={() => { openCreaChat() }} title="Crea nuova chat" className="shadow text-white cursor-pointer btn-primary"><FontAwesomeIcon icon={faPlus} /></div>
                </div>
            </div>
        </div>
    )
}

export default ListaChat