import { createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = {
  notifiche_dimmia: [],
  wsStatus: 'waiting',
  modale_token_scaduto: {
    show: false,
    text: '',
    callback_action: '',
    status: 'ok'
  }
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    reset: (state) => {
      return initialState
    },
    addNotifica: (state, action) => {
      let errori = action.payload.errori;
      let success = action.payload.success;
      let notifiche = [...state.notifiche_dimmia];

      if (errori) { // errori arriva tipicamente in un array
        errori.forEach(err => {
          notifiche.push({
            id: nanoid(),
            type: 'error',
            text: err
          })
        })
      }

      if (success) { // success arriva un messaggio dritto
        notifiche.push({
          id: nanoid(),
          type: 'success',
          text: success
        })
      }

      notifiche = notifiche.slice(-3); // max 3 notifiche

      return {
        ...state,
        notifiche_dimmia: notifiche
      }

    },
    removeNotifica: (state, action) => {
      let id = action.payload.id;
      let notifiche = [...state.notifiche_dimmia];

      notifiche = notifiche.filter(notifica => notifica.id !== id);

      return {
        ...state,
        notifiche_dimmia: notifiche
      }
    },
    setWsStatus: (state, action) => {
      return {
        ...state,
        wsStatus: action.payload
      }
    },
    setModaleTokenScaduto: (state, action) => {

      return {
        ...state,
        modale_token_scaduto: action.payload
      }
    },
    closeModaleTokenScaduto: (state, action) => {

      return {
        ...state,
        modale_token_scaduto: initialState.modale_token_scaduto
      }
    }
  }
});

export const feedbackActions = feedbackSlice.actions;

export default feedbackSlice;