import { useSelector, useDispatch } from 'react-redux';

// bootstrap
import {ToastContainer, Toast} from 'react-bootstrap'

// actions
import { feedbackActions } from '../../store/feedback/feedbackSlice';

const NotificheDimmia = () => {
    const notifiche = useSelector((state) => state.feedback.notifiche_dimmia);
    const dispatch = useDispatch();

    const close = (id) => {
        dispatch(feedbackActions.removeNotifica({id: id}));
    }
    return (
        <ToastContainer id="feedback-notifiche" position="top-center" className="fixed-top w-100"  style={{zIndex: 11}} >
            {
                notifiche.map( notifica => {
                    return (
                        <Toast key={notifica.id} onClose={() => {close(notifica.id)}} animation={true} autohide delay={notifica.type === 'success' ? 2000 : 15000} className={`my-2 feedback-notifica-${notifica.type}`}>
                            <div className="d-flex align-items-center justify-content-between fs-6">
                                <Toast.Body>{notifica.text}</Toast.Body>
                                <button type="button" onClick={() => {close(notifica.id)}} className="btn-close p-2" aria-label="Close"></button>
                            </div>
                        </Toast>
                    )
                })
            }
        </ToastContainer>
    )
}

export default NotificheDimmia