import { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import axios from 'axios';

import { listaCategorieGet, listaUtentiGet } from '../../store/servizio/servizioActions'

import { Navbar, Form, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleLeft, faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons"

import { moduliActions } from "../../store/moduli/moduliSlice"
import { submitCreaChat } from "../../store/conversazioni/conversazioniActions"

import FormCategorie from "./form_categorie"
import FormUtenti from './form_utenti'

const CreaChat = () => {
    const lista_utenti = useSelector((state) => state.servizio.lista_utenti);
    const lista_categorie = useSelector((state) => state.servizio.lista_categorie);
    const userId = useSelector((state) => state.user.userId);
    const status = useSelector((state) => state.moduli.crea_chat.status);
    const init = useSelector((state) => state.moduli.crea_chat.init);

    const dispatch = useDispatch();

    const richiesta_axios = useRef(axios.CancelToken.source());

    const formatListaUtenti = (lista_utenti, init_utenti = [], amministratori = []) => {
        let lista_utenti_form = {};

        lista_utenti?.forEach((utente) => {
            if (utente.ChiaveOrdinamento !== userId && !init_utenti.includes(utente.ChiaveOrdinamento)) {
                lista_utenti_form[utente.ChiaveOrdinamento] = {
                    ...utente,
                    is_checked: false,
                    is_amministratore: false
                };
            } else {
                lista_utenti_form[utente.ChiaveOrdinamento] = {
                    ...utente,
                    is_checked: true,
                    is_amministratore: utente.ChiaveOrdinamento !== userId ? amministratori.includes(utente.ChiaveOrdinamento) : true
                };
            }
        });

        return lista_utenti_form
    }

    const initialState = {
        titolo: init.titolo !== '' ? init.titolo + ' (copy)' : '',
        descrizione: init.descrizione,
        categoria: [...init.categoria],
        input_categoria: '',
        lista_categorie: lista_categorie,
        lista_utenti: formatListaUtenti(lista_utenti, init.utenti, init.amministratori),
        filtro_utenti: ''
    };

    const [form, setForm] = useState(initialState);

    const resetInit = () => {
        dispatch(moduliActions.setCreaChatInit({
            init: {
                titolo: "",
                descrizione: "",
                categoria: [],
                utenti: []
            }
        }))
    }

    useEffect(() => {
        setForm({
            ...form,
            titolo: init.titolo !== '' ? init.titolo + ' (copy)' : '',
            descrizione: init.descrizione,
            categoria: [...init.categoria],
            lista_utenti: formatListaUtenti(lista_utenti, init.utenti, init.amministratori)
        });
    }, [init]);

    useEffect(() => {

        return () => {
            resetInit()
            try {
                richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch { }
        }
    }, [])

    useEffect(() => {
        // questo aggiornamento di utenti e categorie si potrebbe evitare tanto sono già abbastanza aggiornate e c'è il tasto eventualmente
        dispatch(listaCategorieGet());
        dispatch(listaUtentiGet());
    }, [dispatch]);

    useEffect(() => {
        setForm({
            ...form,
            lista_utenti: formatListaUtenti(lista_utenti, init.utenti, init.amministratori)
        })
    }, [lista_utenti])

    useEffect(() => {
        setForm({
            ...form,
            lista_categorie: lista_categorie
        })
    }, [lista_categorie])

    const indietro = () => {
        dispatch(moduliActions.toggleCreaChat());
    }

    const onChangeHandler = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (target.type !== 'checkbox') {
            setForm({
                ...form,
                [name]: name == 'input_categoria' ? value.toLowerCase() : value
            });
        } else {

            const checked = target.checked;

            setForm({
                ...form,
                lista_utenti: {
                    ...form.lista_utenti,
                    [name]: {
                        ...form.lista_utenti[name],
                        is_checked: checked
                    }
                }
            });

            let tutti_utenti = document.getElementById('tutti_utenti');

            if (tutti_utenti.checked === true && checked === false) {
                // sto scheccando dopo aver checcato il tutti amm
                tutti_utenti.checked = false;
            }
        }
    }

    const onAmmHandler = (event) => {
        const target = event.target;
        const name = target.name;

        const checked = target.checked;

        setForm({
            ...form,
            lista_utenti: {
                ...form.lista_utenti,
                [name]: {
                    ...form.lista_utenti[name],
                    is_amministratore: checked
                }
            }
        });

        let tutti_amm = document.getElementById('tutti_amministratori');
        if (tutti_amm.checked === true && checked === false) {
            // sto scheccando dopo aver checcato il tutti amm
            tutti_amm.checked = false;
        }else if(tutti_amm.checked === false && checked === true){
            // controllo se ho messo tutti amm checco anche tutti_amm
            // ciclare ogni volta su tutti gli utenti per checcare la tutti amministratori mi sembra un po' uno spreco di risorse anche se uno magari si aspetta se li seleziona tutti che si auto selezioni la tutti amministratori. Però poi dovrei riare lo stesso cineam all'avvio e per il check sull'utente
            // let uno_non = false;
            // Object.keys(form.lista_utenti).forEach(key => {
            //     console.log(form.lista_utenti[key], form.lista_utenti[key].is_amministratore, form.lista_utenti[key].is_checked)
            //     if(form.lista_utenti[key].is_amministratore === false && form.lista_utenti[key].is_checked === true && key !== name){
            //         uno_non = true;
            //     }
            // });

            // if(!uno_non){
            //     tutti_amm.checked = true;
            // }
        }

    }

    const submitHandler = (e) => {
        let element = e.currentTarget;

        if (status === 'loading') {
            e.preventDefault()
        } else if (element.checkValidity() === true) {
            e.preventDefault();
            dispatch(submitCreaChat({
                form: form,
                richiesta_axios: richiesta_axios.current
            }));
        }
    }

    const reset = () => {
        try {
            richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
        } finally {
            richiesta_axios.current = axios.CancelToken.source();
        }
        resetInit()
        setForm(initialState);
    }

    const tuttiAmm = (event) => {
        const target = event.target;
        const checked = target.checked;

        let nuova_lista = { ...form.lista_utenti }
        Object.keys(nuova_lista).map(key => {
            if(key !== userId){
                nuova_lista[key].is_amministratore = checked
            }
        });

        setForm({
            ...form,
            lista_utenti: nuova_lista
        });
    }

    const tuttiUtenti = (event) => {
        const target = event.target;
        const checked = target.checked;

        let nuova_lista = { ...form.lista_utenti }
        Object.keys(nuova_lista).map(key => {
            nuova_lista[key].is_checked = checked
        });

        setForm({
            ...form,
            lista_utenti: nuova_lista
        });
    }

    return (
        <div className="h-100 w-100 bg-white">
            <Navbar expand="xs" id="titolo-lista-chat" className="border-bottom bg-light justify-content-between shadow-sm px-3">
                <Navbar.Brand>
                    Crea Nuova Chat
                </Navbar.Brand>
                <span className="cursor-pointer text-primary" onClick={() => { indietro() }}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Indietro</span>
            </Navbar>
            <Form id="form-crea-chat" autoComplete="off" className="m-0 p-0 w-100" onSubmit={submitHandler}>
                <div id="crea-chat" className="p-3">
                    <Form.Group className="mb-2">
                        <Form.Label><strong>Titolo Chat</strong></Form.Label>
                        <Form.Control required type="text" placeholder="Titolo Chat" value={form.titolo} name="titolo" onChange={onChangeHandler} />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label><strong>Descrizione</strong></Form.Label>
                        <Form.Control as="textarea" rows={3} value={form.descrizione} name="descrizione" onChange={onChangeHandler} />
                    </Form.Group>
                    <FormCategorie form={form} setForm={setForm} onChangeHandler={onChangeHandler} />
                    <FormUtenti tuttiAmm={tuttiAmm} form={form} onChangeHandler={onChangeHandler} onAmmHandler={onAmmHandler} tuttiUtenti={tuttiUtenti} />
                </div>
                <div id="confirm" className="shadow-top border-top w-100 confirm-button d-flex justify-content-around align-items-center">
                    <small onClick={reset} className="text-primary cursor-pointer">Reset</small>
                    <Button className="w-50 rounded-pill" disabled={status === 'loading'} type="submit" variant="primary">{status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <> <FontAwesomeIcon className="me-3" icon={faCheck} /> Conferma</>}</Button>
                </div>
            </Form>
        </div>
    )
}

export default CreaChat