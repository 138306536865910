import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';

import { Card, Form, FloatingLabel, Button, Row, Col } from 'react-bootstrap'

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'

// actions
import { loginActions } from '../../store/login/loginSlice';
import { submitAccount } from '../../store/login/loginActions'

const LoginAccount = () => {
    const status = useSelector((state) => state.login.status);
    const servizio_nome = useSelector((state) => state.login.servizio_nome);
    const servizio_libemax = useSelector((state) => state.login.servizio_libemax);

    const richiesta_axios = useRef(axios.CancelToken.source());

    const [input, setInput] = useState({
        account: ''
    });

    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setInput({
            ...input,
            [name]: value
        });
    }

    const submitHandler = (e) => {
        let form = e.currentTarget;

        if (status === 'loading') {
            e.preventDefault()
        } else if (form.checkValidity() === true) {
            e.preventDefault();
            dispatch(submitAccount({ account: input.account, richiesta_axios: richiesta_axios.current }));
        }
    }

    const indietro = () => {
        dispatch(loginActions.setServizio({ servizio: '' }));
    }

    useEffect(() => {

        return () => {
            try {
                richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch { }
        }
    }, [])


    return (
        <Card className="shadow-lg login-card rounded">
            <Card.Header className="rounded text-truncate">
                <h5 className="my-1 text-truncate">{servizio_nome}</h5>
            </Card.Header>
            <Card.Body className="login-card-body d-flex justify-content-center align-items-center">
                <Form className="my-3 form-login" onSubmit={submitHandler}>
                    <Row className="w-100 align-items-stretch">
                        <Col xs>
                            <Form.Group>
                                <FloatingLabel label="Account">
                                    <Form.Control autoFocus type="text" placeholder="Nome account" value={input.account} name="account" onChange={handleInputChange} required />
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        {servizio_libemax && (
                            <Col xs="auto" className="px-0 d-flex align-items-end" >
                                <h5 className="mb-1 fw-normal">.libemax.com</h5>
                            </Col>
                        )}
                    </Row>
                    <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                        <Button className="confirm-button px-4 mb-2  rounded-pill" disabled={status === 'loading'} type="submit" variant="primary">{status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <> <FontAwesomeIcon className="me-3" icon={faCheck} /> Conferma</>}</Button>
                        <span onClick={indietro} className="cursor-pointer text-primary">Indietro</span>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default LoginAccount