import chiamataAPI from "../../utility/chiamataAPI";
import costanti from "../../utility/costanti";

import { feedbackActions } from '../feedback/feedbackSlice';
import { moduliActions } from '../moduli/moduliSlice';
import { archiviateActions } from './archiviateSlice';

export const getArchiviate = (payload) => {
    return async (dispatch, getState) => {
        const richiesta_axios = payload.richiesta_axios;

        const user = getState().user;
        const LastEvaluatedKey = getState().archiviate.archiviateLastEvaluatedKey;

        let url = costanti.api_chat_ricerca;

        let data = {
            userId: user.userId,
            account: user.account,
            archiviata: true
        }

        if(payload.altri === true && LastEvaluatedKey !== null){
            data.ExclusiveStartKey = JSON.stringify(LastEvaluatedKey);
        }

        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(feedbackActions.addNotifica({ errori: ['Operazione Interrotta'] }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'getArchiviate'
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                dispatch(moduliActions.setArchiviateStatus({ status: 'ok', carica_altri_status: 'ok' }));
                dispatch(archiviateActions.setListaChat({
                    lista_chat: r.data.chat,
                    archiviateLastEvaluatedKey: r.data.LastEvaluatedKey,
                    altri: r.data.altri
                }))
            } else if (r.stato === 0) {
                dispatch(moduliActions.setArchiviateStatus({ status: 'ok', carica_altri_status: 'ok'  }));
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
            }

        })

    }
};