import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import axios from 'axios';

import { moduliActions } from "../../store/moduli/moduliSlice"
import { getArchiviate } from '../../store/archiviate/archiviateActions'

import { Button, Navbar } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleLeft, faSpinner, faSyncAlt, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons"

//components
import ElencoChat from "./elenco_chat";

const Archiviate = () => {
    const status = useSelector((state) => state.moduli.archiviate.status);

    const btn_status = useSelector((state) => state.moduli.archiviate.btn_carica_altri_status);
    const last_evaluated_key = useSelector((state) => state.archiviate.archiviateLastEvaluatedKey);
    const lista_chat = useSelector((state) => state.archiviate.lista_chat);

    const [stoCaricandoAltri, setStoCaricandoAltri] = useState(false);

    const dispatch = useDispatch();
    const richiesta_axios = useRef(axios.CancelToken.source());

    useEffect(()=>{
        if(stoCaricandoAltri){
            setStoCaricandoAltri(false);
        }
    },[lista_chat])

    useEffect(() => {
        dispatch(moduliActions.setArchiviateStatus({ status: 'loading' }));
        submitGetArchiviate();

        return () => {
            try {
                richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch { }
        }
    }, [])

    const submitGetArchiviate = () => {
        dispatch(moduliActions.setArchiviateStatus({ status: 'loading' }));
        dispatch(getArchiviate({ altri: false, richiesta_axios: richiesta_axios.current }));
    }

    const indietro = () => {
        dispatch(moduliActions.toggleArchiviate());
    }

    const refresh = () =>{
        dispatch(moduliActions.setArchiviateStatus({ status: 'loading' }));
        dispatch(getArchiviate({ richiesta_axios: richiesta_axios.current }));
    }

    const caricaAltri = () => {
        setStoCaricandoAltri(true);
        dispatch(moduliActions.setArchiviateStatus({ carica_altri_status: 'loading'  }));
        dispatch(getArchiviate({ altri: true, richiesta_axios: richiesta_axios.current }));
    }

    const onScrollHandler = (e) =>{
        const target = e.target;

        if(target.scrollHeight - target.scrollTop === target.clientHeight && last_evaluated_key !== null && stoCaricandoAltri === false){
            caricaAltri();
        }
    }

    return (
        <div className="h-100 w-100 bg-white">
            <Navbar expand="xs" id="titolo-lista-chat" className="border-bottom bg-light justify-content-between shadow-sm px-3">
                <Navbar.Brand>
                    Chat Archiviate
                </Navbar.Brand>
                <span className="cursor-pointer text-primary" onClick={() => { indietro() }}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Indietro</span>
            </Navbar>
            <div id="archiviate-wrapper" onScroll={onScrollHandler}>
                <div className="w-100 py-3 d-flex justify-content-around align-items-center">
                    <Button size='sm' className="rounded-pill" onClick={refresh} disabled={status === 'loading'} variant="primary">{status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <> <FontAwesomeIcon className="me-2" icon={faSyncAlt} /> Refresh</>}</Button>
                </div>
                {(status !== 'ok') && (
                    <div className="pt-5 text-center">
                        <FontAwesomeIcon spin size='lg' className="text-primary fs-2" icon={faSpinner} />
                    </div>
                )}
                {status === 'ok' &&
                    (
                        <>
                        <div className="w-100">
                            <ElencoChat />
                        </div>
                        {
                            last_evaluated_key !== null && <div className="py-3 text-center">
                                <Button disabled={btn_status === 'loading'} onClick={caricaAltri} className="btn btn-primary btn-sm  rounded-pill" >{btn_status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <><FontAwesomeIcon icon={faChevronCircleDown} /> Carica Altro</>}</Button>
                            </div>
                        }
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default Archiviate