import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faLock, faBellSlash, faArchive, faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";

import { formatData } from "../../utility/functions";

// components
import PrintCategorie from '../tools/print_categorie'
import Partecipanti from "../tools/partecipanti"

import { Col, Row, Button } from "react-bootstrap";

import { conversazioniActions } from "../../store/conversazioni/conversazioniSlice";

const Risultato = (props) => {
    const caricaAltri = props.caricaAltri;
    const testo_cercato = useSelector((state) => state.ricerca.init.testo);
    const lista_chat = useSelector((state) => state.ricerca.risultato.lista_chat);
    const lista_messaggi = useSelector((state) => state.ricerca.risultato.lista_messaggi);
    const status = useSelector((state) => state.moduli.ricerca.status);
    const chat_active = useSelector((state) => state.conversazioni.chat_active);
    const message_active = useSelector((state) => state.conversazioni.message_active);
    const userId = useSelector((state) => state.user.userId);
    const btn_status = useSelector((state) => state.moduli.ricerca.btn_carica_altri_status);
    const from_messaggi = useSelector((state) => state.ricerca.from_messaggi);
    const from_chat = useSelector((state) => state.ricerca.from_chat);

    const dispatch = useDispatch();

    const setChatActive = (chat_id) => {
        dispatch(conversazioniActions.setChatActive({ chat_active: chat_id, message_active: null }))
    }

    const setMessageActive = (chat_id, msg_id) => {
        dispatch(conversazioniActions.setChatActive({ chat_active: chat_id, message_active: msg_id }))
    }

    return (
        <div id="risultato" className="flex-fill">
            {status === 'loading' && (
                <div className="p-4 text-center">
                    <FontAwesomeIcon size="lg" spin className="text-primary fs-4" icon={faSpinner} />
                </div>
            )}
            {(lista_messaggi === null && lista_chat === null) && status !== 'loading' && (
                <h6 className="p-2 text-center mt-3">Seleziona filtri e clicca "Cerca" per iniziare la ricerca</h6>
            )}
            {(lista_messaggi !== null || lista_chat !== null) && status === 'ok' && (
                <>
                    <h5 className="text-center mt-3">Chat</h5>
                    {
                        lista_chat.length > 0 && (
                            lista_chat.map(chat => {
                                return (
                                    <Row key={chat.ChiavePartizione} className={`lista py-2 rounded ${chat_active === chat.ChiavePartizione ? 'active shadow-sm' : ''} ${parseInt(chat.stato) === 0 ? 'locked' : ''}`}>
                                        <Col onClick={() => { setChatActive(chat.ChiavePartizione) }} className="text-truncate" xs="12">
                                            <Row className="m-0 p-0">
                                                <Col className="m-0 p-0" xs={10}>
                                                    <p className="mb-0 text-truncate">
                                                        {parseInt(chat.stato) === 0 ? <FontAwesomeIcon className="me-1" style={{ fontSize: 13 }} icon={faLock} /> : ''}
                                                        {chat.utenti_is_archiviata?.includes(userId) ? <FontAwesomeIcon className="me-1" style={{ fontSize: 13 }} icon={faArchive} /> : ''}
                                                        {chat.titolo}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="m-0 p-0">
                                                <Col className="m-0 p-0  text-truncate" xs={10}><small className="text-muted"><Partecipanti chat={chat} /></small></Col>
                                                <Col className="m-0 p-0 xs-1 text-end" xs={2}>
                                                    {chat.is_silenziata ? <FontAwesomeIcon style={{ fontSize: 12 }} className="align-sub me-2" icon={faBellSlash} /> : ''}
                                                </Col>
                                            </Row>
                                            <Row className="m-0 p-0">
                                                <Col className="m-0 p-0 xs-11 text-truncate" xs={6} lg={8}><PrintCategorie chat={chat} /></Col>
                                                <Col className="m-0 p-0 xs-1 text-end" xs={6} lg={4}><small className="data text-truncate">{formatData(chat.last_updated)}</small></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })
                        )
                    }
                    {lista_chat.length === 0 && <small>Nessuna chat trovata</small>}
                    {
                        from_chat !== null && <div className="py-3 text-center">
                            <Button disabled={btn_status === 'loading'} onClick={() => {caricaAltri('chat')}} className="btn btn-primary btn-sm  rounded-pill" >{btn_status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <><FontAwesomeIcon icon={faChevronCircleDown} /> Carica Altre Chat</>}</Button>
                        </div>
                    }
                    {testo_cercato.length > 0 && <h5 className="text-center mt-3">Messaggi</h5>}
                    {
                        lista_messaggi.length > 0 && (
                            lista_messaggi.map(msg => {
                                return (
                                    <Row key={msg.ChiaveOrdinamento} className={`lista py-2 rounded ${message_active === msg.ChiaveOrdinamento ? 'active shadow-sm' : ''}`}>
                                        <Col onClick={() => { setMessageActive(msg.ChiavePartizione, msg.ChiaveOrdinamento) }} className="text-truncate" xs="12">
                                            <Row className="m-0 p-0">
                                                <Col className="m-0 p-0" xs={10}><p className="mb-0 text-truncate">da: <strong>{msg.from_name}</strong></p></Col>
                                            </Row>
                                            <Row className="m-0 p-0">
                                                <Col className="m-0 p-0 text-truncate" xs={8}>{msg.testo}</Col>
                                                <Col className="m-0 p-0 text-end" xs={4}><small className="data text-truncate">{formatData(msg.messaggio_data_ora)}</small></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })
                        )
                    }
                    {lista_messaggi.length === 0 && testo_cercato.length > 0 && <small>Nessun messaggio trovato</small>}
                    {
                        from_messaggi !== null && <div className="py-3 text-center">
                            <Button disabled={btn_status === 'loading'} onClick={() => {caricaAltri('messaggi')}} className="btn btn-primary btn-sm  rounded-pill" >{btn_status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <><FontAwesomeIcon icon={faChevronCircleDown} /> Carica Altri Messaggi</>}</Button>
                        </div>
                    }
                </>
            )}
        </div>
    )
}

export default Risultato