import chiamataAPI from "../../utility/chiamataAPI";
import costanti from "../../utility/costanti";

import { feedbackActions } from '../feedback/feedbackSlice';
import { moduliActions } from '../moduli/moduliSlice';
import { ricercaActions } from './ricercaSlice';

export const submitRicerca = (payload) => {
    return async (dispatch, getState) => {
        const form = payload.form;

        const richiesta_axios = payload.richiesta_axios;

        let utente = [];

        if(form.lista_utenti){
            Object.values(form.lista_utenti).forEach(ut => {
                if (ut.is_checked) {
                    utente.push(ut.ChiaveOrdinamento);
                }
            });
        }
        
        const user = getState().user;
        const from_chat = getState().ricerca.from_chat;
        const from_messaggi = getState().ricerca.from_messaggi;

        let url = costanti.api_chat_ricerca;

        let data = {
            opensearch: true,
            userId: user.userId,
            account: user.account,
            categoria: form.categoria,
            stato: form.filtro_stato,
            testo: form.testo,
            utente: utente,
        }

        if(form.archiviata == 2){
            data.archiviata = true;
        }else if(form.archiviata == 3){
            data.archiviata = false;
        }

        if(form.chat_id){
            data.chat_id = form.chat_id
        }

        if(payload.altri === 'chat'){
            data.from_chat = from_chat;
        }else if (payload.altri === 'messaggi'){
            data.from_messaggi = from_messaggi;
        }


        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(feedbackActions.addNotifica({ errori: ['Operazione Interrotta'] }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'submitRicerca',
                        payload: payload
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                dispatch(moduliActions.setRicercaStatus({ status: 'ok', carica_altri_status: 'ok' }));
                dispatch(ricercaActions.setRisultato({
                    init: {
                        categoria: form.categoria ? [...form.categoria] : [],
                        filtro_stato: form.filtro_stato ? form.filtro_stato : '',
                        testo: form.testo ? form.testo : '',
                        lista_utenti: form.lista_utenti ? form.lista_utenti : null,
                    },
                    lista_chat: r.data.chat.map(chat => chat['_source']),
                    lista_messaggi: r.data.messaggi.map(messaggio => messaggio['_source']),
                    // se altri è null mi prendo i due from_chat e from_messaggi. arriva un numero se ci sono altre chat/messaggi o null se ho finito tutta la ricerca. 
                    //se no se sto cercando gli altri lascio stare l'altro e setto solo chat/messaggi a seconda di cosa ho cercato
                    from_chat: r.data.from_chat,
                    from_messaggi: r.data.from_messaggi,
                    altri: payload.altri
                }))
            } else if (r.stato === 0) {
                dispatch(moduliActions.setRicercaStatus({ status: 'ok', carica_altri_status: 'ok' }));
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
            }

        })

    }
};