import { useSelector } from 'react-redux';

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const WebsocketOverlay = () => {
    const wsStatus = useSelector((state) => state.feedback.wsStatus);
    
    return (
        <>
            {wsStatus === 'connecting' && (
                <div id="websocket-overlay" className="whcenter">
                    <div className="text-center">
                        <FontAwesomeIcon spin className="text-light fs-2"  icon={faSpinner}/>
                        <p className="text-light">Connecting...</p>
                    </div>
                </div>
            )}
        </>
    )
}

export default WebsocketOverlay