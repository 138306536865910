import { useState } from 'react';
import { useSelector } from 'react-redux';

import { TransitionGroup, CSSTransition } from "react-transition-group";

import { Image, Row, Spinner } from 'react-bootstrap'
import dimmia_titolo_logo from '../../assets/img/logo-sito.png'

import LoginServizio from './login_servizio';
import LoginAccount from './login_account';
import LoginForm from './login_form';

import { useEffect } from 'react';

const Login = () => {
    const login = useSelector((state) => state.login);
    const [page, setPage] = useState('servizio');

    useEffect(() => {
        if (login.servizio.length === 0) {
            setPage('servizio')
        } else if (login.account.length === 0) {
            setPage('account')
        } else {
            setPage('form')
        }
    }, [login.servizio.length, login.account.length])


    return (

        <Row className="h-100">
            <div id="login" className="whcenter d-flex flex-column justify-content-start align-items-center pt-4">
                <div id="dimmia-titolo-logo" className="mb-4"><Image className="w-100" src={dimmia_titolo_logo} rounded /></div>
                {!login.gestito_autologin && <div className="whcenter my-2 py-5"><Spinner animation="border" variant="primary" /></div>}
                {login.gestito_autologin && (
                    <div id="login-wrapper">
                        <TransitionGroup>
                            <CSSTransition key={page} timeout={500} classNames="fade" >
                                {page === 'servizio' ? <LoginServizio /> : page === 'account' ? <LoginAccount /> : <LoginForm />}
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                )}
            </div>
        </Row>


    )
}

export default Login