import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
//bootstrap
import { ListGroup } from 'react-bootstrap'

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons'

//actions
import { submitSetSilenziata } from '../../store/dettaglio/dettaglioActions';

const DettaglioIsSilenziata = () => {
    const [showAction, setShowAction] = useState(false);

    const is_silenziata = useSelector((state) => state.dettaglio.is_silenziata);
    const chat_id = useSelector((state) => state.dettaglio.dettagli.ChiavePartizione);
    const disable_buttons = useSelector((state) => state.moduli.dettaglio.disable_buttons);

    const dispatch = useDispatch();

    const richiesta_axios = useRef(axios.CancelToken.source());

    useEffect(() => {
        return () => {
            try {
                // XXX qua ha senso che se uno cambia chat a metà dell'operazione di silezia/attiva notifiche gli annullo l'operazione??
                // richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch {}
        }
    }, [])

    const submitHandler = () => {
        dispatch(submitSetSilenziata({
            is_silenziata: is_silenziata ? false : true, 
            chat_id: chat_id, 
            richiesta_axios: richiesta_axios.current
        }));
    }

    return (
        <>
            <div className="py-2">
                <h6 className="w-100 text-center">Notifiche</h6>
                <ListGroup className="my-2" >
                    <ListGroup.Item disabled={disable_buttons} action onClick={() => { submitHandler() }} onMouseEnter={() => setShowAction(true)} onMouseLeave={() => setShowAction(false)}>
                        {showAction && <><FontAwesomeIcon className={`me-2 ${is_silenziata ? 'text-success' : 'text-danger'}`} icon={is_silenziata ? faBell : faBellSlash} />{is_silenziata ? 'Attiva Notifiche' : 'Silenzia Chat'} </>}
                        {!showAction && <><FontAwesomeIcon className={`me-2 text-dark`} icon={is_silenziata ? faBellSlash : faBell} /><span className="text-capitalize">{is_silenziata ? 'Chat Silenziata' : 'Notifiche Attive'}</span></>}
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </>
    )
}

export default DettaglioIsSilenziata