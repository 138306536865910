import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lista_utenti: [],
    lista_categorie: []
};

const servizioSlice = createSlice({
    name: 'servizio',
    initialState,
    reducers: {
        reset: (state) => {
            return initialState
        },
        setListaUtenti: (state, action) => {
            return {
                ...state,
                lista_utenti: action.payload.lista_utenti
            }
        },
        setListaCategorie: (state, action) => {
            return {
                ...state,
                lista_categorie: action.payload.lista_categorie
            }
        }
    }
});

export const servizioActions = servizioSlice.actions;

export default servizioSlice;