import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    crea_chat: {
        open: false,
        status: 'ok',
        init: {
            titolo: "",
            descrizione: "",
            categoria: [],
            utenti: [],
            amministratori: []
        }
    },
    archiviate:{
        open: false,
        status: 'ok',
        btn_carica_altri_status: 'ok'
    },
    ricerca: {
        open: false,
        status: 'ok',
        btn_carica_altri_status: 'ok'
    },
    input_messaggio: {
        type: 'testo',
        multiline: false,
        xs: true,
        showEmoji: false,
        status: 'ok',
        statusFiles: {},
        completati: []
    },
    dettaglio: {
        open: true,
        page: 'dettaglio',
        disable_buttons: false,
        btn_clona_status: 'ok'
    },
    lista_chat: {
        filtri: {
            stato: 1,
            categoria: '',
            column: false
        },
        carica_altre_status: 'ok'
    },
    lista_messaggi: {
        status: 'ok',
        btn_carica_altri_status: 'ok'
    },
    servizio: {
        lista_utenti_status: 'ok',
        lista_categorie_status: 'ok'
    },
    modifica: {
        status: 'ok'
    }
};

const moduliSlice = createSlice({
    name: 'moduli',
    initialState,
    reducers: {
        reset: (state) => {
            return initialState;
        },
        toggleArchiviate: (state) => {
            return {
                ...state,
                archiviate: {
                    ...state.archiviate,
                    open: !state.archiviate.open
                },
                ricerca: {
                    ...state.ricerca,
                    open: !state.archiviate.open ? false : state.ricerca.open // se sto aprendo crea chat mi assicuro di chiudere ricerca
                },
                crea_chat: {
                    ...state.crea_chat,
                    open: !state.archiviate.open ? false : state.crea_chat.open // se sto aprendo crea chat mi assicuro di chiudere ricerca
                }
            }
        },
        toggleCreaChat: (state) => {
            return {
                ...state,
                crea_chat: {
                    ...state.crea_chat,
                    open: !state.crea_chat.open
                },
                ricerca: {
                    ...state.ricerca,
                    open: !state.crea_chat.open ? false : state.ricerca.open // se sto aprendo crea chat mi assicuro di chiudere ricerca
                },
                archiviate: {
                    ...state.archiviate,
                    open: !state.crea_chat.open ? false : state.archiviate.open // se sto aprendo crea chat mi assicuro di chiudere ricerca
                }
            }
        },
        toggleRicerca: (state) => {
            return {
                ...state,
                crea_chat: {
                    ...state.crea_chat,
                    open: !state.ricerca.open ? false : state.crea_chat.open // se sto aprendo ricerca mi assicuro di chiudere crea chat
                },
                ricerca: {
                    ...state.ricerca,
                    open: !state.ricerca.open
                },
                archiviate: {
                    ...state.archiviate,
                    open: !state.ricerca.open ? false : state.archiviate.open // se sto aprendo crea chat mi assicuro di chiudere ricerca
                }
            }
        },
        setFiltriListaChat: (state, action) => {
            return {
                ...state,
                lista_chat: {
                    ...state.lista_chat,
                    filtri: {
                        stato: action.payload.stato !== undefined ? action.payload.stato : state.lista_chat.filtri.stato,
                        categoria: action.payload.categoria !== undefined ? action.payload.categoria : state.lista_chat.filtri.categoria,
                        column: action.payload.column !== undefined ? action.payload.column : state.lista_chat.filtri.column
                    }
                }
            }
        },
        toggleDettaglio: (state) => {
            return {
                ...state,
                dettaglio: {
                    ...state.dettaglio,
                    open: !state.dettaglio.open,
                    page: 'dettaglio'
                }
            }
        },
        setDettaglio: (state, action) => {
            return {
                ...state,
                dettaglio: {
                    ...state.dettaglio,
                    page: action.payload.page ? action.payload.page : state.dettaglio.page,
                    disable_buttons: action.payload.disable_buttons !== undefined ? action.payload.disable_buttons : state.dettaglio.disable_buttons,
                    btn_clona_status: action.payload.btn_clona_status !== undefined ? action.payload.btn_clona_status : state.dettaglio.btn_clona_status
                }
            }
        },
        setMessaggiStatus: (state, action) => {
            return {
                ...state,
                lista_messaggi: {
                    ...state.lista_messaggi,
                    status: action.payload.status ? action.payload.status : state.lista_messaggi.status,
                    btn_carica_altri_status: action.payload.btn_carica_altri_status ? action.payload.btn_carica_altri_status : state.lista_messaggi.btn_carica_altri_status
                }
            }
        },
        setServizioStatus: (state, action) => {
            return {
                ...state,
                servizio: {
                    ...state.servizio,
                    lista_utenti_status: action.payload.lista_utenti_status ? action.payload.lista_utenti_status : state.servizio.lista_utenti_status,
                    lista_categorie_status: action.payload.lista_categorie_status ? action.payload.lista_categorie_status : state.servizio.lista_categorie_status
                }
            }
        },
        setCreaChatStatus: (state, action) => {
            return {
                ...state,
                crea_chat: {
                    ...state.crea_chat,
                    status: action.payload.status !== undefined ? action.payload.status : state.crea_chat.status,
                    open: action.payload.open !== undefined ? action.payload.open : state.crea_chat.open,
                }
            }
        },
        setRicercaStatus: (state, action) => {
            return {
                ...state,
                ricerca: {
                    ...state.ricerca,
                    status: action.payload.status !== undefined ? action.payload.status : state.ricerca.status,
                    open: action.payload.open !== undefined ? action.payload.open : state.ricerca.open,
                    btn_carica_altri_status: action.payload.carica_altri_status !== undefined ? action.payload.carica_altri_status : state.ricerca.carica_altri_status,
                }
            }
        },
        setArchiviateStatus: (state, action) => {
            return {
                ...state,
                archiviate: {
                    ...state.archiviate,
                    status: action.payload.status !== undefined ? action.payload.status : state.archiviate.status,
                    open: action.payload.open !== undefined ? action.payload.open : state.archiviate.open,
                    btn_carica_altri_status: action.payload.carica_altri_status !== undefined ? action.payload.carica_altri_status : state.archiviate.carica_altri_status,
                }
            }
        },
        setModificaStatus: (state, action) => {
            return {
                ...state,
                modifica: {
                    ...state.modifica,
                    status: action.payload.status
                }
            }
        },
        toggleEmoji: (state, action) => {
            return {
                ...state,
                input_messaggio: {
                    ...state.input_messaggio,
                    showEmoji: !state.input_messaggio.showEmoji
                }
            }
        },
        setInputVisualization: (state, action) => {
            return {
                ...state,
                input_messaggio: {
                    ...state.input_messaggio,
                    multiline: action.payload.multiline !== undefined ? action.payload.multiline : state.input_messaggio.multiline,
                    xs: action.payload.xs !== undefined ? action.payload.xs : state.input_messaggio.xs
                }
            }
        },
        setInputType: (state, action) => {
            return {
                ...state,
                input_messaggio: {
                    ...state.input_messaggio,
                    type: action.payload.type
                }
            }
        },
        setInputStatus: (state, action) => {
            return {
                ...state,
                input_messaggio: {
                    ...state.input_messaggio,
                    status: action.payload.status !== undefined ? action.payload.status : state.input_messaggio.status,
                    statusFiles: action.payload.statusFiles !== undefined ? action.payload.statusFiles : state.input_messaggio.statusFiles,
                    completati: action.payload.completati !== undefined ? action.payload.completati : state.input_messaggio.completati
                }
            }
        },
        setInputFileStatus: (state, action) => {
            return {
                ...state,
                input_messaggio: {
                    ...state.input_messaggio,
                    statusFiles: {
                        ...state.input_messaggio.statusFiles,
                        [action.payload.key]: action.payload.status
                    }
                }
            }
        },
        setFileCompletato: (state, action) => {
            return {
                ...state,
                input_messaggio: {
                    ...state.input_messaggio,
                    completati: [...state.input_messaggio.completati, action.payload.file]
                }
            }
        },
        setListaCaricaAltreStatus: (state, action) => {
            return {
                ...state,
                lista_chat: {
                    ...state.lista_chat,
                    carica_altre_status: action.payload.carica_altre_status
                }
            }
        },
        setCreaChatInit: (state, action) => {
            return {
                ...state,
                crea_chat: {
                    ...state.crea_chat,
                    init: action.payload.init
                }
            }
        }
    }
});

export const moduliActions = moduliSlice.actions;

export default moduliSlice;