import { nanoid } from '@reduxjs/toolkit';

import message from '../../utility/ws_message';

import uploadFile from '../../utility/uploadFile';
import imageCompression from 'browser-image-compression';
import { getFileType } from '../../utility/functions';

import { messaggiActions } from './messaggiSlice';
import { moduliActions } from '../moduli/moduliSlice';
import { feedbackActions } from '../feedback/feedbackSlice';
import { inviaNotifica } from '../notificaWeb/notificaWebActions';
import { conversazioniActions } from '../conversazioni/conversazioniSlice';
import { messaggioInserisci as conversazioniMessaggioInserisci } from '../conversazioni/conversazioniActions';

export const inviaMessaggio = (payload) => {
    return async (dispatch, getState) => {

        const chat_active = getState().messaggi.chat_messaggi;
        const user = getState().user;

        let hash = nanoid();
        let datetime = new Date().toISOString();

        let msg = {
            ChiaveOrdinamento: "messaggio_" + datetime + '_' + hash,
            ChiavePartizione: chat_active,
            account: user.account,
            message_id: hash,
            entity_type: "messaggio",
            from: user.userId,
            from_name: user.nome,
            messaggio_data_ora: datetime,
            testo: payload.testo,
            tipo: payload.tipo
        };

        dispatch(messaggiActions.messaggioInserisciInput(msg));

        dispatch({
            type: "WS_SEND",
            payload: {
                msg: message.messaggio_inserisci(msg)
            }
        });

    }
};

export const inviaFile = (payload) => {
    return async (dispatch, getState) => {

        const user = getState().user;
        const chat_messaggi = getState().messaggi.chat_messaggi;
        const richiesta_axios = payload.richiesta_axios;

        payload.form.file.forEach((file) => {
            let data = file;
            let metadata = {
                account: user.account,
                from: user.userId,
                from_name: encodeURI(user.nome),
                chat_id: chat_messaggi,
                message_id: file.id,
                content_type: file.type,
                nome: encodeURI(file.name),
                da_convertire: file.type.startsWith('video') && file.type !== 'video/mp4' ? true : false
            };

            const onProgress = (progressEvent) => {
                dispatch(moduliActions.setInputFileStatus({
                    key: file.id,
                    status: {
                        action: 'uploading',
                        progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                }));
            }

            const onProgressCompress = (perc) => {
                dispatch(moduliActions.setInputFileStatus({
                    key: file.id,
                    status: {
                        action: 'converting',
                        progress: perc
                    }
                }));
            }

            if (file.tipo === 'immagine' && file.size > 2621440) { // se immagine e maggiore di 2.5 mb la comprimo
                imageCompression(data, {
                    maxSizeMB: 2.5,
                    maxWidthOrHeight: 1500,
                    onProgress: onProgressCompress,
                }).then((compressedFile) => {

                    uploadFile(compressedFile, metadata, onProgress, user.token, richiesta_axios).then(r => {
                        // qua serve useRef per avere lo stato sempre aggiornato altrimenti ti mette lo stato inizale e aggiorna un dato resettando gli altri
                        if (r.stato === 1) {

                            let msg = {
                                ChiaveOrdinamento: 'messaggio_' + new Date().toISOString() + '_' + metadata.message_id,
                                ChiavePartizione: metadata.chat_id,
                                account: metadata.account,
                                entity_type: "messaggio",
                                from: metadata.from,
                                message_id: metadata.message_id,
                                from_name: decodeURI(metadata.from_name),
                                nome_originale: decodeURI(metadata.nome),
                                messaggio_data_ora: new Date().toISOString(),
                                testo: 'Nuovo/a ' + getFileType(metadata.content_type),
                                tipo: getFileType(metadata.content_type),
                                url: '',
                                url_relativo: '',
                                preview: file.preview,
                                attributi: file.attributi
                            };

                            dispatch(moduliActions.setFileCompletato({ file: metadata.message_id }));
                            dispatch(messaggiActions.messaggioInserisciInput(msg));

                        } else {
                            // FEEDBACK NOTIFICA
                            dispatch(moduliActions.setFileCompletato({ file: metadata.message_id }));
                            if(r.errori[0] === 'Operazione Interrotta'){
                                dispatch(feedbackActions.addNotifica({ errori: ['Impossibile caricare immagine ' + decodeURI(metadata.nome) + '. Operazione Interrotta'] }));
                            }else{
                                dispatch(feedbackActions.addNotifica({ errori: ['Impossibile caricare immagine ' + decodeURI(metadata.nome)] }));
                            }
                        }
                    });
                }).catch((error) => {
                    console.log(error.message);
                    //FEEDBACK NOTIFICA COMPRESSIONE FALLITA
                    dispatch(feedbackActions.addNotifica({ errori: ['Impossibile comprimere immagine ' + decodeURI(metadata.nome)] }));
                });
            } else {

                uploadFile(data, metadata, onProgress, user.token, richiesta_axios).then(r => {
                    // qua serve useRef per avere lo stato sempre aggiornato altrimenti ti mette lo stato inizale e aggiorna un dato resettando gli altri
                    if (r.stato === 1) {

                        let msg = {
                            ChiaveOrdinamento: metadata.message_id,
                            ChiavePartizione: metadata.chat_id,
                            account: metadata.account,
                            entity_type: "messaggio",
                            from: metadata.from,
                            message_id: metadata.message_id,
                            from_name: decodeURI(metadata.from_name),
                            nome_originale: decodeURI(metadata.nome),
                            messaggio_data_ora: new Date().toISOString(),
                            testo: 'Nuovo/a ' + getFileType(metadata.content_type),
                            tipo: getFileType(metadata.content_type),
                            url: '',
                            url_relativo: ''
                        };

                        dispatch(moduliActions.setFileCompletato({ file: metadata.message_id }));
                        dispatch(messaggiActions.messaggioInserisciInput(msg));
                    } else {
                        //FEEDBACK NOTIFICA
                        dispatch(moduliActions.setFileCompletato({ file: metadata.message_id }));
                        if(r.errori[0] === 'Operazione Interrotta'){
                            dispatch(feedbackActions.addNotifica({ errori: ['Impossibile caricare file ' + decodeURI(metadata.nome) + '. Operazione Interrotta'] }));
                        }else{
                            dispatch(feedbackActions.addNotifica({ errori: ['Impossibile caricare file ' + decodeURI(metadata.nome)] }));
                        }
                    }
                });
            }
        });
    }
};

export const inviaAudio = (payload) => {
    return async (dispatch, getState) => {

        const user = getState().user;
        const chat_messaggi = getState().messaggi.chat_messaggi;

        let data = payload.form.file;

        let metadata = {
            account: user.account,
            from: user.userId,
            from_name: encodeURI(user.nome),
            chat_id: chat_messaggi,
            message_id: nanoid(),
            content_type: data.type,
            nome: encodeURI('Registrazione Web ' + new Date().toISOString()),
            da_convertire: true
        }

        const onProgress = (progressEvent) => {
            dispatch(moduliActions.setInputFileStatus({
                key: 'audio',
                status: {
                    status: 'uploading',
                    progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                }
            }));
        }

        uploadFile(data, metadata, onProgress, user.token, payload.richiesta_axios).then(r => {
            // qua serve useRef per avere lo stato sempre aggiornato altrimenti ti mette lo stato inizale e aggiorna un dato resettando gli altri
            if (r.stato === 1) {

                let msg = {
                    ChiaveOrdinamento: metadata.message_id,
                    ChiavePartizione: metadata.chat_id,
                    account: metadata.account,
                    entity_type: "messaggio",
                    message_id: metadata.message_id,
                    from: metadata.from,
                    from_name: decodeURI(metadata.from_name),
                    nome_originale: decodeURI(metadata.nome),
                    messaggio_data_ora: new Date().toISOString(),
                    testo: 'Nuovo/a ' + getFileType(metadata.content_type),
                    tipo: getFileType(metadata.content_type),
                    url: '',
                    url_relativo: ''
                };
                dispatch(moduliActions.setInputStatus({ statusFiles: {}, completati: [] }));
                dispatch(moduliActions.setInputType({ type: 'testo' }));
                dispatch(messaggiActions.messaggioInserisciInput(msg));
            } else {
                //FEEDBACK NOTIFICA
                if(r.errori[0] === 'Operazione Interrotta'){
                    dispatch(feedbackActions.addNotifica({ errori: ['Impossibile caricare file ' + decodeURI(metadata.nome) + '. Operazione Interrotta'] }));
                }else{
                    dispatch(feedbackActions.addNotifica({ errori: ['Impossibile caricare file ' + decodeURI(metadata.nome)] }));
                }
            }
        });
    }
};

export const messaggioInserisci = (payload) => {
    return async (dispatch, getState) => {

        const user = getState().user;
        const chat_active = getState().messaggi.chat_messaggi;
        const conversazioni = getState().conversazioni;

        let chat_id = payload.data.ChiavePartizione.S;
        let utente_from = payload.data.from.S
        let admin = utente_from === 'dimmia_admin' ? true : false; // se c'è una messaggio tipo aggiunto utente o eliminato utente lo stampo se hai la chat aperta ma non aggiorno la lista chat
        let utente_loggato = user.userId;
        if (!admin && utente_loggato !== utente_from) {
            conversazioni.lista_chat?.forEach(conv => {
                if (conv.ChiavePartizione === chat_id && !conv.is_silenziata) {
                    let titolo = 'Nuovo Messaggio da ' + payload.data.from_name.S;
                    let messaggio = payload.data.testo.S;
                    dispatch(inviaNotifica({ titolo, messaggio, chat_id }));
                }
            });
        }
        if (chat_active === chat_id) {
            // mando messaggio websocket per far sapere al server che ho letto il messaggio
            dispatch({
                type: 'WS_SEND',
                payload: {
                    msg: message.utente_last_online_update(user.userId, user.account, chat_active)
                }
            })
            dispatch(messaggiActions.messaggioInserisci({ msg: payload.data }))
            if (!admin) {
                dispatch(conversazioniActions.aggiornaDataListaChat({
                    chat_id: chat_id,
                    last_updated: payload.data.messaggio_data_ora.S
                }));
            }
        } else {
            if (!admin) {
                dispatch(conversazioniMessaggioInserisci({msg: payload.data}));
            }
        }

    }
};

export const messaggioRimuovi = (payload) => {
    return async (dispatch, getState) => {
        const chat_active = getState().messaggi.chat_messaggi;

        let chat_id = payload.data.ChiavePartizione.S;

        if (chat_active === chat_id) {
            dispatch(messaggiActions.messaggioRimuovi({ msg_chiave_ordinamento: payload.data.ChiaveOrdinamento.S }))
        }
    }
};

export const messaggioElimina = (payload) => {
    return async (dispatch, getState) => {
        const messaggio_chiave_ordinamento = payload.messaggio_chiave_ordinamento;
        const chat_id = payload.chat_id;
        const user = getState().user;

        dispatch(messaggiActions.messaggioSetInRimozione(messaggio_chiave_ordinamento));

        dispatch({
            type: "WS_SEND",
            payload: {
                msg: message.messaggio_elimina(user.userId, messaggio_chiave_ordinamento, chat_id)
            }
        });
    }
};

