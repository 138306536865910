import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

// components
import logo from '../../assets/img/logo-sito.png'
import Messaggi from "../lista_messaggi";
import Dettaglio from "../dettaglio";
import Modifica from '../modifica'

// bootstrap
import { Figure, Col, Row } from 'react-bootstrap'

const PannelloRight = () => {
    const lista_messaggi = useSelector((state) => state.messaggi.lista_messaggi);
    const messaggi_status = useSelector((state) => state.moduli.lista_messaggi.status);
    const dettaglio_open = useSelector((state) => state.moduli.dettaglio.open);
    const page = useSelector((state) => state.moduli.dettaglio.page);
    return (
        <>
            {lista_messaggi === null && messaggi_status === 'ok' && (
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <Figure className="text-center">
                        <Figure.Image
                            width={300}
                            alt="Logo Dimmia"
                            src={logo}
                        />
                        <Figure.Caption className="text-center">
                            Nessuna chat selezionata
                        </Figure.Caption>
                    </Figure>
                </div>
            )}
            {(lista_messaggi !== null || messaggi_status !== 'ok') && (
                <Row className="h-100 m-0 p-0 overflow-hidden">
                    <Col lg={dettaglio_open ? '8' : '12'} id="pannello-messaggi" style={{transition: 'all .2s'}} className={` ${dettaglio_open ? 'd-none d-lg-block' : ''} h-100 m-0 p-0 position-relative`}>
                        <Messaggi />
                    </Col>
                    <CSSTransition timeout={500} unmountOnExit exit={false} in={dettaglio_open} classNames="fade-slow" >
                        <Col className="h-100 border-start border-end m-0 p-0 position-relative" lg={4}>
                            <CSSTransition timeout={300} unmountOnExit appear={false} in={page === 'dettaglio'} classNames="display" >
                                <Dettaglio />
                            </CSSTransition>
                            <Modifica />
                        </Col>
                    </CSSTransition>
                </Row>
            )}

        </>
    )
}

export default PannelloRight;