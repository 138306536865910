import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
//bootstrap
import { ListGroup } from 'react-bootstrap'

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen, faArchive } from '@fortawesome/free-solid-svg-icons'

//actions
import { submitSetArchiviata } from '../../store/dettaglio/dettaglioActions';

const DettaglioIsArchiviata = () => {
    const [showAction, setShowAction] = useState(false);

    const is_archiviata = useSelector((state) => state.dettaglio.is_archiviata);
    const chat_id = useSelector((state) => state.dettaglio.dettagli.ChiavePartizione);
    const disable_buttons = useSelector((state) => state.moduli.dettaglio.disable_buttons);

    const dispatch = useDispatch();

    const richiesta_axios = useRef(axios.CancelToken.source());

    useEffect(() => {
        return () => {
            try {
                // XXX qua ha senso che se uno cambia chat a metà dell'operazione di archivia/disarchivia notifiche gli annullo l'operazione??
                // richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch {}
        }
    }, [])

    const submitHandler = () => {
        dispatch(submitSetArchiviata({
            is_archiviata: is_archiviata ? false : true, 
            chat_id: chat_id, 
            richiesta_axios: richiesta_axios.current
        }));
    }

    return (
        <>
            <div className="py-2">
                <h6 className="w-100 text-center">Archivia</h6>
                <ListGroup className="my-2" >
                    <ListGroup.Item disabled={disable_buttons} action onClick={() => { submitHandler() }} onMouseEnter={() => setShowAction(true)} onMouseLeave={() => setShowAction(false)}>
                        {showAction && <><FontAwesomeIcon className={`me-2 ${is_archiviata ? 'text-success' : 'text-danger'}`} icon={is_archiviata ? faFolderOpen : faArchive } />{is_archiviata ? 'Ripristina Chat' : 'Archivia Chat'} </>}
                        {!showAction && <><FontAwesomeIcon className={`me-2 text-dark`} icon={is_archiviata ? faArchive : faFolderOpen} /><span className="text-capitalize">{is_archiviata ? 'Chat Archiviata' : 'Chat Non Archiviata'}</span></>}
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </>
    )
}

export default DettaglioIsArchiviata