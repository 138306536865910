import { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import axios from "axios"

import { Navbar, InputGroup, Form, FormControl, Button } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight, faSpinner, faSearch, faCheck } from "@fortawesome/free-solid-svg-icons"


import { moduliActions } from "../../store/moduli/moduliSlice"
import { listaUtentiGet } from '../../store/servizio/servizioActions'
import { submitEliminaUtenti } from "../../store/modifica/modificaActions"

const EliminaUtenti = () => {
    const utenti_chat = useSelector((state) => state.dettaglio.lista_utenti);
    const status = useSelector((state) => state.moduli.modifica.status);
    const dettaglio = useSelector((state) => state.dettaglio);

    const dispatch = useDispatch();

    const richiesta_axios = useRef(axios.CancelToken.source());

    useEffect(() => {
        return () => {
            try {
                richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch {}
        }
    }, [])

    useEffect(() => {
        // questo aggiornamento di utenti e categorie si potrebbe evitare tanto sono già abbastanza aggiornate e c'è il tasto eventualmente
        dispatch(listaUtentiGet());
    }, [dispatch]);

    const creaListaCheckbox = (lista_utenti_chat) => {
        let lista_utenti = [...lista_utenti_chat];

        lista_utenti.forEach((utente_lista, index) => {
            lista_utenti[index] = {
                ...lista_utenti[index],
                checked: false
            }
        })

        return lista_utenti;
    }

    const [form, setForm] = useState({
        checkbox: creaListaCheckbox(utenti_chat),
        filtro_utenti: ''
    });

    const indietro = () => {
        dispatch(moduliActions.setDettaglio({ page: 'dettaglio' }));
    }

    useEffect(() => {
        if (!dettaglio.is_amministratore) {
            indietro();
        }
    })

    useEffect(() => {
        setForm({
            checkbox: creaListaCheckbox(utenti_chat),
            filtro_utenti: ''
        });
    }, [dettaglio])

    const submitHandler = (e) => {
        let element = e.currentTarget;

        if (status === 'loading') {
            e.preventDefault()
        } else if (element.checkValidity() === true) {
            e.preventDefault();
            dispatch(submitEliminaUtenti({ 
                form: form,
                richiesta_axios: richiesta_axios.current 
            }));
        }
    }

    const onChangeHandler = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (target.type !== 'checkbox') {
            setForm({
                ...form,
                [name]: value
            });
        } else {

            const checked = target.checked;

            let new_checkbox = [...form.checkbox];

            new_checkbox[parseInt(name)] = {
                ...new_checkbox[parseInt(name)],
                checked: checked
            }

            setForm({
                ...form,
                checkbox: new_checkbox
            });
        }
    }

    return (
        <div id="modifica" className="h-100 w-100 bg-white">
            <Navbar expand="xs" id="titolo_pannello_generico" className="border-bottom shadow-sm px-3 bg-light">
                <Navbar.Brand>
                    Elimina Utenti
                </Navbar.Brand>
                <span className="cursor-pointer text-primary" onClick={() => { indietro() }}>Indietro <FontAwesomeIcon icon={faArrowAltCircleRight} /></span>
            </Navbar>
            <Form id="form-elimina-utenti" onSubmit={submitHandler}>
                <div id="elimina-utenti" className="p-3">
                    <InputGroup className="mx-auto pb-3 pt-2 w-75">
                        <FormControl value={form.filtro_utenti} onChange={onChangeHandler} placeholder="Filtra Utenti" name="filtro_utenti" />
                        <InputGroup.Text ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    </InputGroup>
                    <Form.Group>
                        {
                            form.checkbox.map((utente, index) => {
                                let nome = utente.nome;
                                let cognome = utente.cognome;

                                if (utente.nome.toLowerCase().startsWith(form.filtro_utenti.toLowerCase()) || utente.cognome.toLowerCase().startsWith(form.filtro_utenti.toLowerCase())) {
                                    return (
                                        <Form.Check key={utente.ChiaveOrdinamento} className="py-1" onChange={onChangeHandler} checked={utente.checked} id={`checkbox_utenti_${utente.ChiaveOrdinamento}`} name={index} type="checkbox" label={`${nome} ${cognome}`} />
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </Form.Group>
                </div>
                <div id="confirm" className="shadow-top rounded border-top w-100 confirm-button d-flex justify-content-around align-items-center">
                    {/* <small onClick={reset} className="text-primary cursor-pointer">Reset</small> */}
                    <Button className="w-50 rounded-pill" disabled={status === 'loading'} type="submit" variant="primary">{status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <> <FontAwesomeIcon className="me-3" icon={faCheck} /> Conferma</>}</Button>
                </div>
            </Form>
        </div>
    )
}

export default EliminaUtenti