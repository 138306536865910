import { useSelector, useDispatch } from 'react-redux';

// bootstrap
import { Navbar, Button } from 'react-bootstrap'

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faSpinner, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons'

// actions
import { moduliActions } from '../../store/moduli/moduliSlice';
import { conversazioniActions } from '../../store/conversazioni/conversazioniSlice';

const TitoloChat = () => {
    const dettaglio = useSelector((state) => state.dettaglio);
    const dettaglio_open = useSelector((state) => state.moduli.dettaglio.open);
    const dispatch = useDispatch();

    let lista_utenti = '';

    dettaglio.lista_utenti.forEach(utente => {
        lista_utenti += utente.nome + ' ' + utente.cognome + ', ';
    });

    lista_utenti = lista_utenti.slice(0, -2);

    const close = () => {
        dispatch(conversazioniActions.setChatActive({ chat_active: '', message_active: null }));
    }

    return (
        <Navbar bg="light" id="titolo-chat" className="border-bottom shadow-sm px-3 justify-content-between">
            <Navbar.Brand className="text-truncate w-75 pb-0">
                {dettaglio.status === 'loading' && (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-start text-primary">
                        <FontAwesomeIcon spin icon={faSpinner} />
                    </div>
                )}

                {dettaglio.status === 'ok' && (
                    <>
                        <div className="d-flex">
                            <div className="d-md-none pe-3 d-flex align-items-center">
                                <div className="cursor-pointer text-primary" onClick={() => { close() }}><FontAwesomeIcon className="fs-5" icon={faTimes} /></div>
                            </div>
                            <div className="h-100">
                                {dettaglio.dettagli.titolo}
                                <p className="text-truncate w-100 mb-1" style={{ fontSize: 13 }}>{lista_utenti}</p>
                            </div>
                        </div>

                    </>
                )}
            </Navbar.Brand>
            <Button onMouseDown={(event) => event.preventDefault()} className="btn btn-primary btn-sm rounded-pill" onClick={() => { dispatch(moduliActions.toggleDettaglio()) }}><FontAwesomeIcon icon={dettaglio_open ? faAngleDoubleRight : faInfoCircle} />{/*dettaglio_open ? ' Chiudi' : ' Info'*/}</Button>
        </Navbar>
    )
}

export default TitoloChat;