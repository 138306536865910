import { useSelector, useDispatch } from "react-redux"
import { useEffect, useRef } from "react"

import { Col, Row } from "react-bootstrap"

import axios from "axios"

import PannelloLeft from "./pannello_left"
import PannelloRight from "./pannello_right"

import message from '../../utility/ws_message'

import { chatDettaglioGet } from '../../store/dettaglio/dettaglioActions'
import { conversazioniActions } from "../../store/conversazioni/conversazioniSlice"
import { moduliActions } from "../../store/moduli/moduliSlice"
import { listaCategorieGet, listaUtentiGet } from '../../store/servizio/servizioActions'
import { chiediPermessiNotificheWeb } from "../../store/notificaWeb/notificaWebActions"
import { messaggiActions } from "../../store/messaggi/messaggiSlice"
import { dettaglioActions } from "../../store/dettaglio/dettaglioSlice"
import { submitRicerca } from "../../store/ricerca/ricercaActions"

const Dimmia = () => {
    const wsStatus = useSelector((state) => state.feedback.wsStatus);
    const chat_active = useSelector((state) => state.conversazioni.chat_active);
    const message_active = useSelector((state) => state.conversazioni.message_active);
    const chat_messaggi = useSelector((state) => state.messaggi.chat_messaggi);
    const user = useSelector((state) => state.user);
    const chat_dettaglio = useSelector((state) => state.dettaglio.dettagli.ChiavePartizione);

    const richiesta_axios = useRef(axios.CancelToken.source());

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(listaUtentiGet());
        dispatch(listaCategorieGet());
        dispatch(chiediPermessiNotificheWeb());
    }, [dispatch])

    useEffect(() => {
        dispatch({ type: 'WS_CONNECT' });

        return () => {
            dispatch({ type: 'WS_DISCONNECT' })
        }
    }, [user.userId, user.account, user.token, dispatch]) // triggera la riconnessione al websocket in caso di login o cambiamento token (login da modale token scaduto)


    useEffect(() => {
        if (message_active === null && chat_active !== chat_messaggi && chat_active !== '') { // caso normale clicco su una chat senza nessun messaggio selezionato carico gli utlimi messaggi di quella chat
            dispatch(moduliActions.setMessaggiStatus({ status: 'loading' }));
            dispatch({
                type: 'WS_SEND',
                payload: {
                    msg: message.chat_messaggi_get(chat_active, user.userId, user.account, null)
                }
            });
        }

        if (chat_active !== chat_dettaglio && chat_active !== '') {
            try {
                richiesta_axios.current?.cancel('michele approved'); // cancello eventuali richieste già in corso
            } finally {
                richiesta_axios.current = axios.CancelToken.source();
                dispatch(chatDettaglioGet({ richiesta_axios: richiesta_axios.current }));
            }
        }

        if (chat_active === '') {
            // se ritorno a chat active nessuna azzero anche messaggi e dettaglio
            dispatch(messaggiActions.reset());
            dispatch(dettaglioActions.reset())
        }

    }, [chat_active, dispatch])

    useEffect(() => {
        // se ho già selezionato la chat e clicco per scegliere un messaggio faccio ripartire la get message con centra messaggio
        if (message_active !== null) {
            dispatch(moduliActions.setMessaggiStatus({ status: 'loading' }));
            // se uno ha scelto un messaggio carico messaggi in avanti e indietro. Poi nella chat l'utente "naviga" man mano sopra e sotto con i carica altri
            dispatch({
                type: 'WS_SEND',
                payload: {
                    msg: message.chat_messaggi_get(chat_active, user.userId, user.account, null, null, message_active) 
                }
            });
        }
    }, [message_active]);

    useEffect(()=>{
        // caso ultrararo che clicco su diverse chat quindi richiedo i messaggi di chat diverse e arrivano per ultimi i messaggi che ho chiesto per primi quindi non quelli dell'ultima chat selezionata. Ho provato a creare questo errore anche con la chat lenta e non viene, però metti caso. In generale evito non ci sia corrispondenza tra chat_active e chat_messaggi
        if (chat_active !== chat_messaggi && chat_active !== '' && chat_messaggi !== '') {
            dispatch(moduliActions.setMessaggiStatus({ status: 'loading' }));
            dispatch({
                type: 'WS_SEND',
                payload: {
                    msg: message.chat_messaggi_get(chat_active, user.userId, user.account, null)
                }
            });
        }
    }, [chat_messaggi])

    useEffect(() => {
        if (wsStatus === 'connesso') { 
            // quando mi connetto sia che sia la prima volta sia che sia una riconnessione chiedo lista chat e lista messaggi (se serve). Piccolo rischio: se mi sto riconnettendo io chiedo la lista delle prime 20 chat. Se non secondo che sono stato sconnesso (la riconnessione al websocket quando va in timeout è circa un secondo) hanno scritto in 30 chat mi arrivano le prime 20 e 10 rimangono in lista non aggiornate (perchè la lista sarà un merge delle vecchie più le nuove). Per ora è un caso così raro che lo ignoriamo.
            const Params = new URLSearchParams(window.location.search);
            let chat_id = Params.has('chat_id') ? Params.get('chat_id') : false;

            if (chat_id) {
                window.history.replaceState(null, null, window.location.pathname);
                dispatch(moduliActions.toggleRicerca());
                dispatch(submitRicerca({altri: false, form: {chat_id: chat_id}}));
                dispatch(conversazioniActions.setChatActive({ chat_active: chat_id, message_active: null }));
            }else{
                // fatta la login pulisco l'url da eventuali robe lasciate in giro
                window.history.replaceState(null, null, window.location.pathname);
            } 
                
            dispatch({
                type: 'WS_SEND',
                payload: {
                    msg: message.chat_lista_get(user.userId, user.account)
                }
            });
            
            if (chat_active !== '') {
                dispatch({
                    type: 'WS_SEND',
                    payload: {
                        msg: message.chat_messaggi_get(chat_active, user.userId, user.account, null)
                    }
                });
                dispatch(moduliActions.setMessaggiStatus({ status: 'loading' }));
            }
            
        }
    }, [wsStatus])

    return (
        <div id="flottante">
            <Row className="h-100 shadow-lg rounded overflow-hidden">
                <Col md={6} lg={3} id="pannello-left" className={` ${chat_active !== '' ? 'd-none d-md-block' : ''} h-100 border  px-0 bg-white border-end-0`}>
                    <PannelloLeft />
                </Col>
                <Col md={6} lg={9} id="pannello-right" className={` ${chat_active === '' ? 'd-none d-md-block' : ''} h-100  px-0 bg-white border`}>
                    <PannelloRight />
                </Col>
            </Row>
        </div>
    )
}

export default Dimmia