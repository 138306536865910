import { useSelector } from "react-redux";

import MessaggioVideo from './messaggio_video'
import MessaggioTesto from './messaggio_testo'
import MessaggioAudio from './messaggio_audio'
import MessaggioFile from './messaggio_file'
import MessaggioImmagine from './messaggio_immagine'

const Messaggio = (props) => {
    let msg = {...props.msg};
    
    const userId = useSelector((state) => state.user.userId);
    const active = useSelector((state) => state.conversazioni.message_active);

    if (msg.from === 'dimmia_admin') {
        msg.who = 'admin';
    } else if (msg.from === userId) {
        msg.who = 'out';
    } else {
        msg.who = 'in';
    }

    if(msg.ChiaveOrdinamento === active){
        msg.active = true;
    }else{
        msg.active = false;
    }

    switch (msg.tipo) {
        case 'testo':
            return <MessaggioTesto msg={msg} />
        case 'immagine':
            return <MessaggioImmagine msg={msg} />
        case 'file':
            return <MessaggioFile msg={msg} />
        case 'pdf':
            return <MessaggioFile msg={msg} />
        case 'video':
            return <MessaggioVideo msg={msg} />
        case 'audio':
            return <MessaggioAudio msg={msg} />
        default:
            return <MessaggioTesto msg={msg} />
    }
}

export default Messaggio