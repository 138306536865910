import { useSelector, useDispatch } from "react-redux"

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faBellSlash, faLock } from '@fortawesome/free-solid-svg-icons'

// react flip
import { Flipped } from 'react-flip-toolkit'

// components
import Partecipanti from "../tools/partecipanti"
import PrintCategorie from "../tools/print_categorie"

// utility
import { formatData } from "../../utility/functions"

//img
import logino from '../../assets/img/dimmia-def-icona-bianco_512.png'

import { Col, Row, Badge, Figure } from "react-bootstrap"

import { conversazioniActions } from "../../store/conversazioni/conversazioniSlice"

const ElencoChat = () => {
    const conversazioni = useSelector((state) => state.conversazioni.lista_chat);
    const chat_active = useSelector((state) => state.conversazioni.chat_active);
    const filtri = useSelector((state) => state.moduli.lista_chat.filtri);

    const dispatch = useDispatch();

    const setChatActive = (chat_id) => {
        dispatch(conversazioniActions.setChatActive({ chat_active: chat_id, message_active: null }));
    }

    return (
        <>
            {conversazioni === [] && (
                <div className="hw-center">
                    <Figure className="text-center">
                        <Figure.Image
                            width={60}
                            alt="Logo Dimmia"
                            src={logino}
                        />
                        <Figure.Caption className="text-center">
                            Nessuna Chat
                        </Figure.Caption>
                    </Figure>
                </div>
            )}
            {conversazioni === null && (
                <div className="pt-5 text-center">
                    <FontAwesomeIcon spin size='lg' className="text-primary fs-2" icon={faSpinner} />
                </div>
            )}

            {conversazioni !== null && conversazioni !== [] && (
                conversazioni.map(chat => {
                    if ((filtri.stato === chat.stato || filtri.stato === null) && (filtri.categoria === '' || chat.categoria?.includes(filtri.categoria)) && !chat.is_archiviata) {
                        return (
                            <Flipped key={chat.ChiavePartizione} flipId={chat.ChiavePartizione} >
                                <Row id={chat.ChiavePartizione} className={`lista mx-0 px-3 rounded ${chat_active === chat.ChiavePartizione ? 'active shadow-sm' : ''} ${chat.stato === 0 ? 'locked text-muted' : ''}`}>
                                    <Col onClick={() => { setChatActive(chat.ChiavePartizione) }} className="text-truncate py-1" xs="12">
                                        <Row className="m-0 p-0">
                                            <Col className="m-0 p-0" xs={10}><p className="mb-0 text-truncate">{chat.stato === 0 ? <FontAwesomeIcon className="me-1" style={{ fontSize: 13 }} icon={faLock} /> : ''}{chat.titolo}</p></Col>
                                            <Col className="m-0 p-0 text-end" xs={2}>{chat.nuovi_messaggi > 0 ? <Badge className="notifica" pill bg="secondary" style={{verticalAlign: 'bottom'}} >{chat.nuovi_messaggi}</Badge> : ''}</Col>
                                        </Row>
                                        <Row className="m-0 p-0">
                                            <Col className="m-0 p-0 text-truncate" xs={10}><small className="text-muted"><Partecipanti chat={chat} /></small></Col>
                                            <Col className="m-0 p-0 xs-1 text-end" xs={2}>
                                                {chat.is_silenziata ? <FontAwesomeIcon style={{ fontSize: 12 }} className="align-sub me-2" icon={faBellSlash} /> : ''}
                                            </Col>
                                        </Row>
                                        <Row className="m-0 p-0">
                                            <Col className="m-0 p-0 text-truncate" xs={6} lg={8}><PrintCategorie chat={chat} /></Col>
                                            <Col className="m-0 p-0 text-end" xs={6} lg={4}>
                                                <small className="data text-end text-truncate">{formatData(chat.last_updated)}</small>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Flipped>
                        )
                    } else {
                        return ''
                    }
                })
            )}
        </>
    )
}

export default ElencoChat