import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dettagli: {},
  lista_utenti: [],
  is_amministratore: false,
  is_silenziata: false,
  is_archiviata: false,
  status: 'loading'
};

const dettaglioSlice = createSlice({
  name: 'dettaglio',
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
    setChatDettaglio: (state, action) => {
      let payload = action.payload;

      return {
        ...state,
        dettagli: payload.dettagli,
        lista_utenti: payload.lista_utenti,
        is_amministratore: payload.is_amministratore,
        is_silenziata: payload.is_silenziata,
        is_archiviata: payload.is_archiviata,
        status: 'ok'
      }
    },
    setStatus: (state, action) => {
      return {
        ...state,
        status: action.payload.status
      }
    }
  }
});

export const dettaglioActions = dettaglioSlice.actions;

export default dettaglioSlice;