import { useState } from "react";
import { useSelector } from 'react-redux';
// utility
import { formatData } from '../../utility/functions';

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import MessaggioButtons from "./messaggio_buttons";

const MessaggioAudio = (props) => {
    const [ isHover, setIsHover ] = useState(false);
    const is_amministratore = useSelector((state) => state.dettaglio.is_amministratore);
    const msg = props.msg;

    return (
        <div 
            id={msg.ChiaveOrdinamento} 
            className={`w-100 d-flex py-2 msg_${msg.who}`}
            onMouseEnter={()=>{setIsHover(true)}}
            onMouseLeave={()=>{setIsHover(false)}}
        >
            <div className={`msg border shadow-sm rounded ${msg.active && 'active'} ${msg.eliminazione_in_corso === true && 'opacity-25'}`}>
                <div className="nome"><strong>{msg.who !== 'admin' && msg.who !== 'out' && msg.from_name}</strong></div>
                {msg.url === '' && (
                    <div className="audio_wrapper px-4 py-4 text-center">
                        <FontAwesomeIcon size="lg" className="text-primary" spin icon={faSpinner} />
                        {/* <div>
                            {msg.nome_originale}
                        </div> */}
                    </div>
                )}
                {msg.url !== '' && (
                    <div className="audio_wrapper px-3 pt-3 text-center">
                        <audio controls preload="auto" src={msg.url}>Impossibile riprodurre file audio. Formato audio non supportato dal browser in uso.</audio>
                    </div>
                )}
                <span className="data">{msg.who !== 'admin' ? formatData(msg.messaggio_data_ora) : ''}</span>
            </div>
            {isHover && is_amministratore && msg.who !== 'admin' && <MessaggioButtons msg={msg} setIsHover={setIsHover}/>}
        </div>
    )

}

export default MessaggioAudio