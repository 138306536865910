import { useSelector, useDispatch } from "react-redux"

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faUserPlus, faUserMinus, faUserCog, faEdit, faClone, faTimes } from "@fortawesome/free-solid-svg-icons";

import { Navbar, ListGroup, Button } from "react-bootstrap";

//components
import DettaglioDescrizione from "./dettaglio_descrizione";
import DettaglioListaUtenti from "./dettaglio_lista_utenti";
import DettaglioStato from './dettaglio_stato'
import DettaglioIsSilenziata from './dettaglio_is_silenziata'
import DettaglioIsArchiviata from './dettaglio_is_archiviata'

import PrintCategorie from '../tools/print_categorie'
import { moduliActions } from "../../store/moduli/moduliSlice";
import { submitClonaChat } from "../../store/conversazioni/conversazioniActions";

const Dettaglio = () => {
    const dettaglio = useSelector((state) => state.dettaglio);
    const btn_clona_status = useSelector((state) => state.moduli.dettaglio.btn_clona_status);
    const dispatch = useDispatch()

    const NavTo = (pagina) => {
        dispatch(moduliActions.setDettaglio({ page: pagina }));
    }

    const clonaChat = () => {
        dispatch(submitClonaChat());
    }

    const close = () => {
        dispatch(moduliActions.toggleDettaglio());
    }

    return (
        <div className='h-100'>
            <Navbar expand="xs" id="titolo_pannello_generico" className="border-bottom shadow-sm px-3 bg-light">
                <Navbar.Brand>
                    Dettaglio Chat
                </Navbar.Brand>
                <span className="d-lg-none cursor-pointer text-primary" onClick={() => { close() }}><FontAwesomeIcon className="fs-5" icon={faTimes} /></span>
            </Navbar>
            <div id="chat-dettaglio">
                {dettaglio.status === 'loading' && (
                    <div className="whcenter">
                        <FontAwesomeIcon size="lg" icon={faSpinner} spin className="text-primary" />
                    </div>
                )}
                {dettaglio.status !== 'loading' && (
                    <>
                        <h3 id="chat-dettaglio-titolo" className={`px-2 pt-4 pb-2`}>{dettaglio.dettagli.titolo}</h3>
                        <div className="px-2 pb-2">
                            <PrintCategorie chat={dettaglio.dettagli} />
                        </div>
                        <DettaglioDescrizione descrizione={dettaglio.dettagli.descrizione ? dettaglio.dettagli.descrizione : null} />
                        <ListGroup className="my-2" >
                            <ListGroup.Item action disabled={!dettaglio.is_amministratore} onClick={() => NavTo('edit')}>
                                <FontAwesomeIcon className={`me-2 ${dettaglio.is_amministratore ? 'text-primary' : 'text-muted'}`} icon={faEdit} />Modifica Dettagli
                            </ListGroup.Item>
                        </ListGroup>
                        <DettaglioStato />
                        <DettaglioIsSilenziata />
                        <DettaglioIsArchiviata />
                        <h6 className="pt-2 w-100 text-center">Utenti</h6>
                        <ListGroup className="my-2" >
                            <ListGroup.Item action disabled={!dettaglio.is_amministratore} onClick={() => NavTo('aggiungi')}>
                                <FontAwesomeIcon className={`me-2 ${dettaglio.is_amministratore ? 'text-primary' : 'text-muted'}`} icon={faUserPlus} />Aggiungi Utenti
                            </ListGroup.Item>
                            <ListGroup.Item action disabled={!dettaglio.is_amministratore} onClick={() => NavTo('elimina')}>
                                <FontAwesomeIcon className={`me-2 ${dettaglio.is_amministratore ? 'text-primary' : 'text-muted'}`} icon={faUserMinus} />Elimina Utenti
                            </ListGroup.Item>
                            <ListGroup.Item action disabled={!dettaglio.is_amministratore} onClick={() => NavTo('amministratore')}>
                                <FontAwesomeIcon className={`me-2 ${dettaglio.is_amministratore ? 'text-primary' : 'text-muted'}`} icon={faUserCog} />Cambia amministratori
                            </ListGroup.Item>
                        </ListGroup>
                        <DettaglioListaUtenti />
                        <div className="w-100 pt-5 pb-3 d-none d-md-flex justify-content-around align-items-center">
                            <Button size='sm' className="rounded-pill d-none d-md-block" onClick={clonaChat} disabled={btn_clona_status === 'loading'} variant="primary">{btn_clona_status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner} /> : <> <FontAwesomeIcon className="me-2" icon={faClone} /> Clona Chat</>}</Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Dettaglio