import { getLetterColor } from "../../utility/functions"
import Badge from 'react-bootstrap/Badge'

const PrintCategorie = (props) => {
    const chat = props.chat;
    
    if(chat.categoria){
        return chat.categoria?.map( (cat, index) => {
            return <Badge key={index} className="me-1 text-light border"  style={{backgroundColor: getLetterColor(cat)}} >{cat}</Badge>
        })
    }else{
        return ''
    }
    
}

export default PrintCategorie