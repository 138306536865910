import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userActions } from '../store/user/userSlice'
import { submitAutologin } from '../store/login/loginActions';

// bootstrap
import { Container, Spinner } from 'react-bootstrap'

import Login from './login/index'
import Dimmia from './dimmia/index'
import Feedback from './feedback/index'
import MobileDevice from './feedback/mobile_device'
import { moduliActions } from '../store/moduli/moduliSlice';
import { submitAutocompilazione } from '../store/login/loginActions'
import { loginActions } from '../store/login/loginSlice';

const App = () => {
    const logged = useSelector((state) => state.user.logged);
    const gestito_autologin = useSelector((state) => state.login.gestito_autologin);

    const checkViewPort = () => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

       return vw
    }

    const [viewportAccettabile, setViewportAccettabile] = useState(checkViewPort());

    const dispatch = useDispatch();

    useLayoutEffect(() => {
        window.addEventListener('resize', () => {
            setViewportAccettabile(checkViewPort() > 270);
        });
    })

    useEffect(() => {
        
        if(checkViewPort() < 577){
            dispatch(moduliActions.toggleDettaglio())
        }
        // GESTIONE AUTOLOGIN
        const Params = new URLSearchParams(window.location.search);

        if(Params.has('alt')){
            dispatch(submitAutologin({alt: Params.get('alt')}));
            Params.delete('alt');
            window.history.replaceState({}, '', Params.toString().length > 0 ? `${window.location.pathname}?${Params}` : window.location.pathname);
        }else if(localStorage.getItem('user') !== null){
            // priorità al token di autologin. Se non c'è il token prendo dal local storage lo user (se c'è)
            dispatch(userActions.getLocalStorageLogin());
        }else if(Params.has('servizio')){
            let servizio = Params.get('servizio');
            let account = Params.get('account');
            let username = Params.get('username');

            dispatch(submitAutocompilazione({servizio, account, username}))

            Params.delete('servizio')
            Params.delete('account')
            Params.delete('username')

            window.history.replaceState({}, '',  Params.toString().length > 0 ? `${window.location.pathname}?${Params}` : window.location.pathname);
        }else{
            dispatch(loginActions.reset())
        }

    }, [dispatch])

    return (
        <>
            {viewportAccettabile && (
                
                <Container fluid id="app-container">
                    <div id="ellipsis"></div>
                    {!logged && <Login />}
                    {logged && <Dimmia />}
                    <Feedback />
                </Container>
            )}
            {!viewportAccettabile && (
                <div className="d-flex" style={{ width: '100%', minHeight: '100vh' }}>
                    <MobileDevice />
                </div>
            )}
        </>

    )
}

export default App