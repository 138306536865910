import { feedbackActions } from "../feedback/feedbackSlice";
import onMessage from "./websocket_onmessage";

import costanti from "../../utility/costanti"

const websocketMiddleware = (store) => {
    let socket = null;

    const onOpen = (store, next) => (event) => {
        if(process.env.REACT_APP_STAGE !== 'prod'){
            console.log('connesso ' + new Date().toISOString());
        }
        store.dispatch(feedbackActions.setWsStatus('connesso'))
    };

    const onClose = (store) => (e) => {
        //if(process.env.REACT_APP_STAGE !== 'prod'){
            console.log('disconnesso ' + new Date().toISOString(), e);
        //}
        store.dispatch(feedbackActions.setWsStatus('closed'));
        // la chiusra del websocket ha un codice che ne indica la ragione. il ws.close che faccio io al dismount del componente chat ho messo codice 1000. il codice del timeout è il 1001. quindi se la connessione si è chiusa per il timeout la riapro con un nuovo websocket
        if (e.code !== 1000 && e.code === 1001) {
            store.dispatch(feedbackActions.setWsStatus('connecting'));
            store.dispatch({ type: 'WS_CONNECT' })
        } else if (e.code === 1006) {
            store.dispatch(feedbackActions.setModaleTokenScaduto({
                show: true,
                text: 'Sessione scaduta. Inserire password per effettuare il login',
                callback_action: '',
                status: 'ok'
            }))
        }
    }

    return (next) => (action) => {
        const { type } = action;

        const user = store.getState().user;

        if (type === 'WS_CONNECT') {

            if (socket !== null) socket.close();
            socket = new WebSocket(`${costanti.websocket_url}?token=${user.token}&userId=${user.userId}&account=${user.account}`);
            socket.onopen = onOpen(store, next);
            socket.onclose = onClose(store);
            socket.onmessage = onMessage(store);

        } else if (type === 'WS_SEND') {
            if(process.env.REACT_APP_STAGE !== 'prod'){
                console.log('ws send ' + new Date().toISOString(), action.payload.msg);
            }
            if (socket && socket.readyState === 1) {
                socket.send(action.payload.msg)
            }
        } else if (type === 'WS_DISCONNECT') {
            if (socket) {
                socket.close(1000, "Bye bye from Dimmia!");
            }
        }
        next(action);
    }
}

export default websocketMiddleware

