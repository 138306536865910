import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";

import AggiungiUtenti from "./aggiungi_utenti";
import EliminaUtenti from './elimina_utenti';
import ModificaAmministratore from './modifica_amministratore';
import ModificaDettagli from "./modifica_dettagli";

const Modifica = () => {
    const page = useSelector((state) => state.moduli.dettaglio.page);
    return (
        <>
            <CSSTransition timeout={300} unmountOnExit in={page === 'aggiungi'} classNames="slide-right" >
                <AggiungiUtenti />
            </CSSTransition>
            <CSSTransition timeout={300} unmountOnExit in={page === 'elimina'} classNames="slide-right" >
                <EliminaUtenti />
            </CSSTransition>
            <CSSTransition timeout={300} unmountOnExit in={page === 'amministratore'} classNames="slide-right" >
                <ModificaAmministratore />
            </CSSTransition>
            <CSSTransition timeout={300} unmountOnExit in={page === 'edit'} classNames="slide-right" >
                <ModificaDettagli />
            </CSSTransition>
        </>
    )
}

export default Modifica