import { useSelector, useDispatch } from 'react-redux';
import { Form, InputGroup, FormControl, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faSyncAlt, faSpinner } from "@fortawesome/free-solid-svg-icons"

import { listaUtentiGet } from '../../store/servizio/servizioActions'

const FormUtenti = (props) => {
    const form = props.form;
    const onChangeHandler = props.onChangeHandler;
    const onAmmHandler = props.onAmmHandler;
    const tuttiAmm = props.tuttiAmm;
    const tuttiUtenti = props.tuttiUtenti;

    const lista_utenti_status = useSelector((state) => state.moduli.servizio.lista_utenti_status);
    const userId = useSelector((state) => state.user.userId);

    const dispatch = useDispatch();

    const refreshUtenti = () => {
        dispatch(listaUtentiGet());
    }

    return (
        <Form.Group className="mb-2 mt-1">
            <Form.Label><strong>Partecipanti</strong><FontAwesomeIcon onClick={() => { refreshUtenti() }} className="ms-2 text-primary cursor-pointer" spin={lista_utenti_status === 'loading'} size="sm" icon={faSyncAlt} /></Form.Label>
            <InputGroup className="mb-3 w-75">
                <FormControl placeholder="Filtra Utenti" onChange={onChangeHandler} name="filtro_utenti" value={form.filtro_utenti} />
                <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
            </InputGroup>
            {lista_utenti_status === 'loading' && (
                <div className="text-primary text-center py-2">
                    <FontAwesomeIcon spin className="text-primary" icon={faSpinner} />
                </div>
            )}
            {lista_utenti_status === 'ok' && (
                <Row className="pb-2">
                    <Col className="d-flex justify-content-start align-items-start">
                        <strong><Form.Check  className="py-1" onChange={tuttiUtenti}  id={`tutti_utenti`}  type="checkbox" label={`Utenti`} /></strong>
                    </Col>
                    <Col xs="auto" className="d-flex justify-content-end align-items-center flex-nowrap">
                        <Form.Label className="mt-0 mb-0 ms-0 me-2 text-truncate"><strong>Tutti</strong></Form.Label>
                        <Form.Check onChange={tuttiAmm} id={`tutti_amministratori`}  type="checkbox" />
                    </Col>
                </Row>
            )}
            {lista_utenti_status === 'ok' && (
                Object.entries(form.lista_utenti).map((obj) => {
                    const [key, value] = obj;

                    let nome = value.nome;
                    let cognome = value.cognome;
                    let filtro_utenti = form.filtro_utenti;

                    if (key !== userId && (nome.toLowerCase().startsWith(filtro_utenti.toLowerCase()) || cognome.toLowerCase().startsWith(filtro_utenti.toLowerCase()))) {
                        return (
                            <Row key={key} className="utente">
                                <Col>
                                    <Form.Check  className="py-1" onChange={onChangeHandler} checked={value.is_checked} id={`checkbox_crea_${key}`} name={value.ChiaveOrdinamento} type="checkbox" label={`${value.nome} ${value.cognome}`} />
                                </Col>
                                <Col xs="auto" className="d-flex justify-content-end align-items-center flex-nowrap">
                                    {
                                        value.is_checked && 
                                        (
                                            <>
                                                <Form.Label  className="mt-0 mb-0 ms-0 me-2 text-truncate">Amm</Form.Label>
                                                <Form.Check aria-label={`checkbox_amministratore_${key}`} className="py-1" onChange={onAmmHandler} checked={value.is_amministratore} id={`checkbox_amministratore_${key}`} name={value.ChiaveOrdinamento} type="checkbox"/>
                                            </>
                                        )
                                    }
                                </Col>
                            </Row>
                        )
                    } else {
                        return null
                    }
                })
            )}
        </Form.Group>
    )
}

export default FormUtenti