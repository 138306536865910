import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    servizio: '',
    account: '',
    servizio_libemax: null,
    ragione_sociale: '',
    servizio_nome: '',
    username: '',
    gestito_autologin: false,
    lista_servizio: undefined,
    status: 'ok'
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setServizio: (state, action) => {
      state.servizio_libemax = action.payload.servizio_libemax;
      state.servizio = action.payload.servizio;
      state.servizio_nome = action.payload.servizio_nome;
      state.gestito_autologin = true;
      state.status = 'ok';
    },
    reset: (state) => {

      return {
        ...initialState,
        gestito_autologin: true
      };
    },
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    setListaServizio: (state, action) => {
      state.gestito_autologin = true;
      state.status = 'ok';
      state.lista_servizio = action.payload.lista_servizio;
    },
    setAccount: (state, action) => {
      state.gestito_autologin = true;
      state.account = action.payload.account;
      state.ragione_sociale = action.payload.ragione_sociale;
      state.username = action.payload.username !== undefined ? action.payload.username : state.username;
      state.status = 'ok';
    },
    setAutocompilazione: (state, action) => {
      state.servizio_libemax = action.payload.servizio_libemax;
      state.servizio = action.payload.servizio;
      state.servizio_nome = action.payload.servizio_nome;
      state.gestito_autologin = true;
      state.account = action.payload.account;
      state.ragione_sociale = action.payload.ragione_sociale;
      state.username = action.payload.username;
      state.status = 'ok';
    }
  }
});

export const loginActions = loginSlice.actions;

export default loginSlice;