// utility
import logino from '../../assets/img/dimmia-def-icona-bianco_512.png'

import { conversazioniActions } from '../conversazioni/conversazioniSlice';

import { feedbackActions } from '../feedback/feedbackSlice';
export const inviaNotifica = (payload) => {
    return async (dispatch, getState) => {

        if (!document.hasFocus() && ("Notification" in window)) {
            let notifica = new Notification(payload.titolo, {
                body: payload.messaggio,
                icon: logino
            });

            notifica.onclick = () => {
                dispatch(conversazioniActions.setChatActive({ chat_active: payload.chat_id, message_active: null }));
                window.focus();
            }
        }

    }
};

export const chiediPermessiNotificheWeb = () => {
    return async (dispatch, getState) => {
        if (("Notification" in window)) {
            if (Notification.permission !== "denied" && Notification.permission !== "granted") {
                Notification.requestPermission().then(permission => {
                    if (permission === "granted") {
                        dispatch(feedbackActions.addNotifica({ success: 'Ottimo! Da adesso potrai ricevere notifiche Dimmia direttamente dal tuo browser' }));
                    }
                    if (permission === "denied") {
                        dispatch(feedbackActions.addNotifica({ success: 'Se vorrai ricevere notifiche da Dimmia in futuro cambia i permessi nelle impostazioni del tuo browser!' }));
                    }
                })
            };
        }
    }
};

