import { useSelector } from 'react-redux';

import CreaChat from '../crea_chat/index';
import Ricerca from '../ricerca/index';
import ListaChat from '../lista_chat/index';
import Archiviate from '../archiviate/index';

import { CSSTransition } from 'react-transition-group';


const PannelloLeft = () => {
    const open_crea_chat = useSelector((state) => state.moduli.crea_chat.open);
    const open_ricerca = useSelector((state) => state.moduli.ricerca.open);
    const open_archiviate = useSelector((state) => state.moduli.archiviate.open);
    const open_lista_chat = !open_crea_chat && !open_ricerca && !open_archiviate;
    
    return (
        <>
            <CSSTransition unmountOnExit timeout={300} in={open_lista_chat} classNames="display" >
                <ListaChat/> 
            </CSSTransition>
            <CSSTransition unmountOnExit timeout={300}  in={open_crea_chat} classNames="slide" >
                <CreaChat/>
            </CSSTransition>
            <CSSTransition timeout={300}  unmountOnExit in={open_ricerca} classNames="slide" >
                <Ricerca/>
            </CSSTransition>
            <CSSTransition timeout={300}  unmountOnExit in={open_archiviate} classNames="slide" >
                <Archiviate/>
            </CSSTransition>
        </>
    )
}

export default PannelloLeft;