import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"

import axios from 'axios';
import vmsg from "vmsg";

import { Form, Button, ProgressBar } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPaperPlane, faTrash, faMicrophone, faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";

//action
import { moduliActions } from '../../store/moduli/moduliSlice';
import { feedbackActions } from '../../store/feedback/feedbackSlice';
import { inviaAudio } from "../../store/messaggi/messaggiActions";

//XXX  VISTO CHE QUA TANTO LA CONVERSIONE LA FA IL SERVER (MA LA FA IL SERVER?) SI POTREBBE USERA SEMPLICEMENTE IL GETUSERMEDIA E MANDARE I FILE IN OCUS (O QUELLO CHE è IN BASE AL BROWSER) SENZA USARE LIBRERIE PER FARE MP3 CHE TANTO POI VENGONO CONVERTITI (QUESTA LIBRERIA DA GIà UN WARNING DI UN MODULO DEPRECATO)
//XXX usando solo media user su safari non va. Questa libreria l'ho provata e fa l'mp3 ma sembra più semplice da leggere e scrivere 'mic-recorder-to-mp3' poi c'è un altro metodo che permette di ottenere un wav e poi trasformarlo in mp3. servono le librerie 'extendable-media-recorder', 'extendable-media-recorder-wav-encoder' e per trasformare in mp3 "lamejs"; ci sarebbe da fare alcune prove di compatibilità
const InputAudio = () => {
    const statusFiles = useSelector((state) => state.moduli.input_messaggio.statusFiles);
    const xs = useSelector((state) => state.moduli.input_messaggio.xs);
    const dettaglio_open = useSelector((state) => state.moduli.dettaglio.open);

    const divInput = useRef(null);

    const [form, setForm] = useState({
        file: null,
        url: null
    })

    const richiesta_axios = useRef(axios.CancelToken.source());

    useEffect(()=>{
        // devo dare il tempo all'animazione di apertura del dettaglio se no ho la vecchia width. Comunque questo resize all'apertura del dettaglio si fa solo in rare width intermedie tra il desktop e il mobile
        setTimeout(() => {
            if(divInput.current?.offsetWidth < 550){
                dispatch(moduliActions.setInputVisualization({xs: true}))
            }else{
                dispatch(moduliActions.setInputVisualization({xs: false}))
            }
        }, 301)
        
    }, [divInput.current, dettaglio_open])
    
    useEffect(() => {
        return () => {
            try {
                richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch { }
        }
    }, [])

    const dispatch = useDispatch();

    const abort = async () => {

        if (statusFiles.audio?.status === 'recording') await recorder.current?.stopRecording();

        try {
            richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
        } finally {
            richiesta_axios.current = axios.CancelToken.source();
        }
        dispatch(moduliActions.setInputStatus({ statusFiles: {}, completati: [] }));
        dispatch(moduliActions.setInputType({ type: 'testo' }));
    }

    const reset = () => {
        setForm({
            file: null,
            url: null
        });
    }

    const recorder = useRef(new vmsg.Recorder({
        wasmURL: process.env.REACT_APP_STAGE === 'local' ? 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm' : '/libs/vmsg.wasm', // messo in cartella public così me lo ritrovo nella build
        shimURL: "https://unpkg.com/wasm-polyfill.js@0.2.0/wasm-polyfill.js"
    }));

    const startRecording = async () => {
        try {
            await recorder.current.initAudio();
            await recorder.current.initWorker();
            recorder.current.startRecording();
            dispatch(moduliActions.setInputStatus({
                statusFiles: {
                    audio: {
                        status: 'recording'
                    }
                }
            }));
        } catch (e) {
            console.error(e);
            dispatch(moduliActions.setInputStatus({
                statusFiles: {
                    audio: {
                        status: 'error'
                    }
                }
            }));
            dispatch(feedbackActions.addNotifica({ errori: ["Si è verificato un errore. Microfono non trovato o funzionalità non supportata dal browser"] }));
        }
    }

    const stopRecording = async () => {
        const blob = await recorder.current.stopRecording();
        await recorder.current.stopTracks();
        let url = URL.createObjectURL(blob);

        // set status ok
        dispatch(moduliActions.setInputStatus({
            statusFiles: {
                audio: {
                    status: 'ok'
                }
            }
        }));

        setForm({
            ...form,
            file: blob,
            url: url
        })
    }

    const submitHandler = () => {
        dispatch(inviaAudio({
            form: form,
            richiesta_axios: richiesta_axios.current
        }));
    }


    return (
        <div ref={divInput} className=" w-100 shadow-top border-top" id="input-messaggio">
            <Form onSubmit={(e) => { e.preventDefault() }} id="input-audio" className="form-inline w-100 h-100 ">
                <Form.Group className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <div className={` ${xs ? 'd-flex justify-content-center align-items-center w-25' : ' w-15'} text-end `}>
                        <FontAwesomeIcon onClick={() => { abort() }} style={{ fontSize: 25 }} className="icona-close me-3  fs-5 align-middle text-primary me-3 cursor-pointer" icon={faTimes} />
                    </div>
                    <div className="flex-fill h-100 position-relative d-flex align-items-center justify-content-center">
                        {form.file === null && (
                            <>
                                {
                                    statusFiles.audio?.status === 'error' ?
                                        <Button onMouseDown={(e) => e.preventDefault()} disabled={true} className={`btn rounded-pill cursor-pointer btn-primary`}><FontAwesomeIcon icon={faMicrophoneSlash} /></Button>
                                        :
                                        <Button onMouseDown={(e) => e.preventDefault()} onClick={statusFiles.audio?.status === 'recording' ? stopRecording : startRecording} className={`btn rounded-pill cursor-pointer btn-primary ${statusFiles.audio?.status === 'recording' ? 'pulse' : ''}`}><FontAwesomeIcon size="lg" icon={faMicrophone} /></Button>
                                }
                            </>
                        )}
                        {form.file !== null && (
                            <>
                                {
                                    statusFiles.audio?.status === 'uploading' ?
                                        <div style={{ width: '70%' }} className=" h-100 position-relative flex-column d-flex align-items-center justify-content-center">
                                            <h6 className="text-primary">Uploading...</h6>
                                            <ProgressBar style={{ height: 6 }} now={statusFiles.audio?.progress} />
                                        </div>
                                        :
                                        <div>
                                            <audio style={{ height: 35, maxWidth: '100%' }} className="align-middle" controls src={form.url}>Non è possibile riprodurre il file. Formato audio non supportato dal browser in uso</audio>
                                            <span onClick={reset} className="ms-3"><FontAwesomeIcon className="text-primary cursor-pointer" icon={faTrash} /></span>
                                        </div>
                                }
                            </>
                        )}
                    </div>
                    <div className={` ${xs ? 'd-flex justify-content-center align-items-center w-25' : 'text-start w-15'}  `}>
                        <Button onClick={submitHandler} className={` ${xs ? '' : 'ms-3 px-4'}  cursor-pointer rounded-pill`} disabled={form.file === null}><FontAwesomeIcon icon={faPaperPlane} /></Button>
                    </div>
                </Form.Group>
            </Form>
        </div>
    )
}

export default InputAudio