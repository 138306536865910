const message = {
    chat_lista_get: (userId, account, ExclusiveStartKey = null) => {
        return JSON.stringify({
            "tipo_richiesta": "ws-chat-lista-get",
            "account": account,
            "utente": userId,
            "ExclusiveStartKey": ExclusiveStartKey
        });
    },
    chat_messaggi_get: (chat_id, userId, account, ExclusiveStartKey, foward = false, centrare_messaggio = null) => {
        return JSON.stringify({
                "tipo_richiesta": "ws-chat-messaggi-get",
                "chat_id": chat_id,
                "account": account,
                "utente": userId,
                "ExclusiveStartKey": ExclusiveStartKey,
                "centrare_messaggio": centrare_messaggio,
                "foward": foward,
                "app" : 0
            })
    },
    messaggio_inserisci: (msg) => {
        return  JSON.stringify({
                "tipo_richiesta": "ws-messaggio-inserisci",
                "account": msg.account,
                "from": msg.from,
                "chat_id": msg.ChiavePartizione,
                "from_name": msg.from_name,
                'testo': msg.testo,
                'message_id': msg.message_id
            });
    },
    utente_last_online_update: (userId, account, chat_active) => {
        return JSON.stringify({
            "tipo_richiesta": "ws-utente-last-online-update",
            "chat_id": chat_active,
            "utente": userId,
            "account": account
        });
    },
    messaggio_elimina: (userId, messaggio_chiave_ordinamento, chat_id) => {
        return JSON.stringify({
            "tipo_richiesta": "ws-messaggio-elimina",
            "messaggio_chiave_ordinamento": messaggio_chiave_ordinamento,
            "utente": userId,
            "chat_id": chat_id
        });
    }

}

export default message;