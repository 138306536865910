import { useState } from "react";
import { useSelector } from "react-redux";

import { InputGroup, FormControl, ListGroup, Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const DettaglioListaUtenti = () => {
    const [filtro, setFiltro] = useState('')
    const lista_utenti = useSelector((state) => state.dettaglio.lista_utenti);

    const onChangeHandler = (e) => {
        const target = e.target;
        const value = target.value;

        setFiltro(value.toLowerCase());
    }

    return (
        <>
            <InputGroup className="mx-auto py-2 w-75">
                <FormControl value={filtro} onChange={onChangeHandler} placeholder="Filtra Utenti" name="filtro_utenti" />
                <InputGroup.Text ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
            </InputGroup>
            <ListGroup variant="flush" className="px-3">
                {lista_utenti?.map(utente => {
                    const nome = utente.nome;
                    const cognome = utente.cognome;

                    if (nome.toLowerCase().includes(filtro) || cognome.toLowerCase().includes(filtro)) {
                        return (
                            <ListGroup.Item key={utente.ChiaveOrdinamento} className="px-1 d-flex justify-content-between align-items-center">
                                <div>
                                    {nome + ' ' + cognome}
                                </div>
                                {utente.is_amministratore && <Badge bg="secondary">amministratore</Badge>}
                            </ListGroup.Item>
                        )
                    }else{
                        return null
                    }
                })}
            </ListGroup>
        </>
    )
}

export default DettaglioListaUtenti