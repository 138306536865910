import { configureStore } from '@reduxjs/toolkit';
import websocketMiddleware from './websocket/websocket_middleware';

import loginSlice from './login/loginSlice'
import userSlice from './user/userSlice';
import feedbackSlice from './feedback/feedbackSlice';
import conversazioniSlice from './conversazioni/conversazioniSlice';
import messaggiSlice from './messaggi/messaggiSlice';
import dettaglioSlice from './dettaglio/dettaglioSlice';
import moduliSlice from './moduli/moduliSlice';
import servizioSlice from './servizio/servizioSlice';
import ricercaSlice from './ricerca/ricercaSlice';
import archiviateSlice from './archiviate/archiviateSlice';

const store = configureStore({
    reducer: { 
        login: loginSlice.reducer, 
        user: userSlice.reducer,
        feedback: feedbackSlice.reducer,
        conversazioni: conversazioniSlice.reducer,
        messaggi: messaggiSlice.reducer,
        dettaglio: dettaglioSlice.reducer,
        moduli: moduliSlice.reducer,
        servizio: servizioSlice.reducer,
        ricerca: ricercaSlice.reducer,
        archiviate: archiviateSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(websocketMiddleware),
    devTools: process.env.REACT_APP_STAGE === 'prod' ? false : true
});
  
export default store;