import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import axios from 'axios';

//bootstrap
import { Card, Form, FloatingLabel, Button } from 'react-bootstrap'

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'

//actions
import { loginActions } from '../../store/login/loginSlice';
import { submitLogin } from '../../store/login/loginActions'

const LoginForm = () => {
    const status = useSelector((state) => state.login.status);
    const servizio_nome = useSelector((state) => state.login.servizio_nome);
    const ragione_sociale = useSelector((state) => state.login.ragione_sociale);

    const richiesta_axios = useRef(axios.CancelToken.source());

    const [input, setInput] = useState({
        username: useSelector((state) => state.login.username),
        password: ''
    });

    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setInput({
            ...input, 
            [name]: value 
        });
    }

    const indietro  = () => {
        dispatch(loginActions.setAccount({account: '', username: ''}));
    }

    const submitHandler = (e) => {
        let form = e.currentTarget;

        if(status === 'loading'){
            e.preventDefault()
        }else if (form.checkValidity() === true) {
            e.preventDefault();
            dispatch(submitLogin({
                ...input,
                richiesta_axios: richiesta_axios.current
            }));
        }
    }

    useEffect(() => {
        return () => {
            try {
                richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch {}
        }
    }, [])


    return (
        <Card className="shadow-lg login-card rounded">
            <Card.Header className="rounded text-truncate">
                <h5 className="my-1 text-truncate">{`${servizio_nome} - ${ragione_sociale}`}</h5>
            </Card.Header>
            <Card.Body className="login-card-body d-flex justify-content-center align-items-center">
                <Form className="my-3 form-login" onSubmit={submitHandler}>
                    <Form.Group>
                        <FloatingLabel label="Username">
                            <Form.Control autoFocus={input.username?.length === 0} required className="mb-3" type="text" placeholder="Username" value={input.username} name="username" onChange={handleInputChange}/>
                        </FloatingLabel>
                        <FloatingLabel label="Password">
                            <Form.Control required autoFocus={input.username?.length !== 0} type="password" placeholder="Password" value={input.password} name="password" onChange={handleInputChange}/>
                        </FloatingLabel>
                        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                            <Button className="confirm-button px-4 mb-2 rounded-pill" disabled={status === 'loading'} type="submit" variant="primary">{status === 'loading' ? <FontAwesomeIcon spin icon={faSpinner}/> : <> <FontAwesomeIcon className="me-3" icon={faCheck}/> Conferma</> }</Button>
                            <span onClick={indietro}  className="cursor-pointer text-primary">Indietro</span>
                        </div>
                    </Form.Group>
                </Form> 
            </Card.Body>
        </Card>
    )
}

export default LoginForm