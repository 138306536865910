import { createSlice } from '@reduxjs/toolkit';

import { sortMessaggi } from '../../utility/functions';

const initialState = {
  lista_messaggi: null,
  chat_messaggi: '',
  messaggiLastEvaluatedKey: null,
  foward_messaggiLastEvaluatedKey: null,
  riferimento_div_scroll: ''
};

const messaggiSlice = createSlice({
  name: 'messaggi',
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
    chatMessaggiGet: (state, action) => {
      if (action.payload.data.data.altri && state.chat_messaggi === action.payload.data.data.chat_id) { // faccio un check perchè se clicco a manetta le chat può darsi che mi torna il carica altri di una chat che è già cambiata. Faccio la concat dei messaggi solo se effettivamente tornano altri messaggi ma della chat giusta
        let messaggi = sortMessaggi([...state.lista_messaggi].concat(action.payload.data.data.messaggi));
        let foward =  action.payload.data.data.foward !== undefined ? action.payload.data.data.foward : false;
        return {
          ...state,
          lista_messaggi: messaggi,
          chat_messaggi: action.payload.data.data.chat_id,
          messaggiLastEvaluatedKey:  !foward ? action.payload.data.data.LastEvaluatedKey : state.messaggiLastEvaluatedKey,
          foward_messaggiLastEvaluatedKey: foward ? action.payload.data.data.foward_LastEvaluatedKey : state.foward_messaggiLastEvaluatedKey,
          riferimento_div_scroll: !foward ? state.messaggiLastEvaluatedKey?.ChiaveOrdinamento : null // se clicchi continuamente cambia chat può darsi che risponde un carica altri mentre è già cambiata la chat. In quel caso la lastEvaluatedKey non corrisponderebbe o potrebbe mancare
        }
      } else {
        let messaggi = sortMessaggi(action.payload.data.data.messaggi);
        let riferimento_div_scroll = null;

        if (action.payload.riferimento_div_scroll !== null) {
          riferimento_div_scroll = action.payload.riferimento_div_scroll;
        } else {
          riferimento_div_scroll = messaggi.length > 1 ? messaggi[messaggi.length - 1].ChiaveOrdinamento : null;
        }

        return {
          ...state,
          lista_messaggi: messaggi,
          chat_messaggi: action.payload.data.data.chat_id,
          messaggiLastEvaluatedKey: action.payload.data.data.LastEvaluatedKey,
          foward_messaggiLastEvaluatedKey: action.payload.data.data.foward_LastEvaluatedKey, // qua dovrebbe settare tutto correttamente il server. Siamo nelle mani del server
          riferimento_div_scroll: riferimento_div_scroll
        }
      }

    },
    messaggioInserisciInput: (state, action) => {
      let payload = action.payload;

      if(state.lista_messaggi){
        return {
          ...state,
          lista_messaggi: [
            ...state.lista_messaggi,
            payload
          ],
          riferimento_div_scroll: payload.ChiaveOrdinamento // XXX da valutare: se scrivo io mi butta sul fondo anche se sono posizionato su messaggi più in alto??
        }
      }else{
        return state
      }
      
    },
    messaggioRimuovi: (state, action) => {
      let chiaveOrdinamento = action.payload.msg_chiave_ordinamento;
      let nuova_lista = [...state.lista_messaggi];

      console.log(action, chiaveOrdinamento);
      nuova_lista = nuova_lista.filter((msg) => msg.ChiaveOrdinamento !== chiaveOrdinamento);
      return {
        ...state,
        lista_messaggi: nuova_lista,
        riferimento_div_scroll: null
      }
    },
    messaggioSetInRimozione: (state, action) => {
      const messaggio_chiave_ordinamento = action.payload;

      let nuova_lista = [...state.lista_messaggi];

      nuova_lista = nuova_lista.map((messaggio, index) => {
        if(messaggio.ChiaveOrdinamento === messaggio_chiave_ordinamento){
          return {
            ...messaggio,
            eliminazione_in_corso: true
          }
        }else{
          return messaggio
        }
      });

      return {
        ...state,
        lista_messaggi: nuova_lista,
        riferimento_div_scroll: null
      }      
    },
    messaggioInserisci: (state, action) => {
      let payload = action.payload;
      let esiste = false;
      let nuova_lista = [...state.lista_messaggi];
      nuova_lista = nuova_lista.map((messaggio, index) => {
        if (messaggio.ChiaveOrdinamento === payload.msg.ChiaveOrdinamento.S || messaggio.message_id === payload.msg.message_id.S) {
          esiste = true;
          return {
            ChiaveOrdinamento: payload.msg.ChiaveOrdinamento.S,
            ChiavePartizione: payload.msg.ChiavePartizione.S,
            account: payload.msg.account.S,
            entity_type: payload.msg.entity_type.S,
            from: payload.msg.from.S,
            from_name: payload.msg.from_name.S,
            message_id: payload.msg.message_id.S,
            messaggio_data_ora: payload.msg.messaggio_data_ora.S,
            testo: payload.msg.testo.S,
            tipo: payload.msg.tipo?.S,
            url: payload.msg.url?.S,
            url_relativo: payload.msg.url_relativo?.S,
            nome_originale: payload.msg.nome_originale?.S,
            attributi: {
              height: payload.msg.attributi?.M?.height?.N,
              size: payload.msg.attributi?.M?.size?.N,
              width: payload.msg.attributi?.M?.width?.N,
              ratio: payload.msg.attributi?.M?.ratio?.N,
              length: payload.msg.attributi?.M?.length?.N
            }
          }
        }else{
          return messaggio
        }
      });

      if (!esiste) {
        let msg = {
          ChiaveOrdinamento: payload.msg.ChiaveOrdinamento.S,
          ChiavePartizione: payload.msg.ChiavePartizione.S,
          account: payload.msg.account.S,
          entity_type: payload.msg.entity_type.S,
          message_id: payload.msg.message_id.S,
          from: payload.msg.from.S,
          from_name: payload.msg.from_name.S,
          messaggio_data_ora: payload.msg.messaggio_data_ora.S,
          testo: payload.msg.testo.S,
          tipo: payload.msg.tipo?.S,
          url: payload.msg.url?.S,
          url_relativo: payload.msg.url_relativo?.S,
          nome_originale: payload.msg.nome_originale?.S,
          attributi: {
            height: payload.msg.attributi?.M?.height?.N,
            size: payload.msg.attributi?.M?.size?.N,
            width: payload.msg.attributi?.M?.width?.N,
            ratio: payload.msg.attributi?.M?.ratio?.N,
            length: payload.msg.attributi?.M?.length?.N
          }
        }

        return {
          ...state,
          lista_messaggi: sortMessaggi([
            ...state.lista_messaggi,
            msg
          ]),
          riferimento_div_scroll: null
        }

      } else {
        // se esiste già aggiorno
        return {
          ...state,
          lista_messaggi: sortMessaggi(nuova_lista),
          riferimento_div_scroll: null
        }
      }
    }
  }
});

export const messaggiActions = messaggiSlice.actions;

export default messaggiSlice;