import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';

// bootstrap
import { Card, Spinner, ListGroup, Image, Button } from 'react-bootstrap'

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

//actions
import { loginActions } from '../../store/login/loginSlice';
import { servizioTipoGet } from '../../store/login/loginActions'


const LoginServizio = () => {
    const lista_servizio = useSelector((state) => state.login.lista_servizio);
    const status = useSelector((state) => state.login.status);
    
    const richiesta_axios = useRef(axios.CancelToken.source());

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            try {
                richiesta_axios.current.cancel('michele approved'); // cancello eventuali richieste già in corso
            } catch {}
        }
    }, [])

    useEffect(() => {
        if(!lista_servizio && status !== 'error' && status !== 'loading'){
            dispatch(servizioTipoGet({richiesta_axios: richiesta_axios.current}));
        }
    }, [dispatch, status, lista_servizio])

    const setServizio = (servizio, servizio_nome, servizio_libemax) => {
        dispatch(loginActions.setServizio({servizio, servizio_nome, servizio_libemax}))
    }

    const refresh = () => {
        dispatch(loginActions.setListaServizio({lista_servizio: null}))
    }
    
    return (
        <Card className="shadow-lg rounded login-card">
            <Card.Header className="rounded text-truncate">
                <h5 className="my-1 text-truncate">Scegli Servizio</h5>
            </Card.Header>
            <Card.Body className="login-card-body">
                {status === 'loading' && <div className="whcenter my-2 py-5"><Spinner animation="border" variant="primary" /></div>}
                {status === 'ok' && (
                   lista_servizio?.map((servizio) =>
                    <ListGroup.Item key={servizio.ChiaveOrdinamento} action className="servizio" onClick={() => {setServizio(servizio.ChiaveOrdinamento, servizio.descrizione, servizio.servizio_libemax)}}>
                        <Image className="login-card-img" src={servizio.url_icona} alt={servizio.descrizione} thumbnail/>
                        <span className="d-block d-sm-inline ps-2">{servizio.descrizione}</span>
                    </ListGroup.Item>)
                )}
            </Card.Body>
            <Card.Footer className="border-0 bg-transparent d-flex justify-content-around align-items-around">
                <Button onClick={refresh} className="rounded-pill shadow-sm" onMouseDown={(e)=>e.preventDefault()} variant="primary"><FontAwesomeIcon className="mx-2" icon={faSyncAlt}/> Ricarica Servizi</Button>
            </Card.Footer>
        </Card>
    )
}

export default LoginServizio