import { createSlice } from '@reduxjs/toolkit';

import { sortChat } from '../../utility/functions';

const initialState = {
  lista_chat: null,
  chat_active: '',
  message_active: null,
  chatLastEvaluatedKey: null
};

const conversazioniSlice = createSlice({
  name: 'conversazioni',
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
    chatListaGet: (state, action) => {
      let data = action.payload.data;

      if (data.chat !== undefined) {
        if(state.lista_chat !== null){
          let ids = [];
          data.chat.forEach(ct => {
            ids.push(ct.ChiavePartizione)
          });

          let chat_filtrate = [...state.lista_chat].filter(chat =>{
            return !ids.includes(chat.ChiavePartizione)
          });
          return {
            ...state,
            lista_chat: sortChat(data.chat.concat(chat_filtrate)), // qua faccio un merge delle chat unendo i due array. new Set rimuove i duplicati poi sort le riordina
            chatLastEvaluatedKey: data.LastEvaluatedKey
          }
        }else{
          return {
            ...state,
            lista_chat: sortChat(data.chat),
            chatLastEvaluatedKey: data.LastEvaluatedKey
          }
        }
      } else {
        return state
      }
    },
    setChatActive: (state, action) => {

      let nuovaLista = state.lista_chat !== null ? [...state.lista_chat] : null;

      if (nuovaLista !== null) {
        nuovaLista = nuovaLista.map(chat => {
          if (chat.ChiavePartizione === action.payload.chat_active) {
            return {
              ...chat,
              nuovi_messaggi: 0
            }
          } else {
            return chat
          }
        });

        // se ci sono già le chat
        // document.getElementById(action.payload.chat_active)?.scrollIntoView();
      }

      return {
        ...state,
        lista_chat: nuovaLista,
        chat_active: action.payload.chat_active,
        message_active: action.payload.message_active !== undefined ? action.payload.message_active : null
      }
    },
    utenteInserisci: (state, action) => {
      const payload = action.payload;

      if (state.lista_chat !== null) {
        let esiste = false;
        state.lista_chat.forEach(chat => {
          if (chat.ChiavePartizione === payload.chat_detail.ChiavePartizione) {
            esiste = true;
          }
        })

        if (!esiste) {
          let conversazioni = sortChat([
            ...state.lista_chat,
            payload.chat_detail
          ]);

          return {
            ...state,
            lista_chat: conversazioni
          }
        } else {
          return state
        }

      } else {
        return state
      }
    },
    conversazioneUpdate: (state, action) => {
      const data = action.payload.data;

      if (data !== undefined) {
        let lista_chat = [...state.lista_chat];
        let index = ''
        lista_chat.forEach((chat, i) => {
          if (chat.ChiavePartizione === data.ChiavePartizione.S) {
            index = i
          }
        });

        lista_chat[index] = {
          ...lista_chat[index],
          titolo: data.titolo?.S,
          descrizione: data.descrizione?.S,
          categoria: data.categoria?.SS,
          stato: data.stato?.N ? parseInt(data.stato.N) : 1,
          utenti_chat: data.utenti_chat?.SS,
          //utenti_dettaglio: puliziaUtentiDettaglio(data.utenti_dettaglio), utenti dettaglio è stata tolto! i nomi si prendono dalla lista utenti
        }

        return {
          ...state,
          lista_chat: lista_chat
        }

      } else {
        return state
      }
    },
    utenteModifica: (state, action) => {
      const data = action.payload.data;

      let lista_chat = [...state.lista_chat];
      if (lista_chat !== null && data) {
        let index = '';

        lista_chat.forEach((conv, i) => {
          if (conv.ChiavePartizione === data.ChiavePartizione.S) {
            index = i;
          }
        })

        lista_chat[index] = {
          ...lista_chat[index],
          is_silenziata: data.is_silenziata.BOOL,
          is_archiviata: data.is_archiviata?.BOOL
        }

        return {
          ...state,
          lista_chat: lista_chat
        }
      } else {
        return state
      }
    },
    aggiornaDataListaChat: (state, action) => {
      let nuovaLista = [...state.lista_chat];
      let esiste = false;

      nuovaLista = nuovaLista.map(chat => {
        if (chat.ChiavePartizione === action.payload.chat_id) {
          esiste = true;
          return {
            ...chat,
            last_updated: action.payload.last_updated
          }
        } else {
          return chat
        }
      });

      nuovaLista = sortChat(nuovaLista);

      if (esiste) {
        return {
          ...state,
          lista_chat: nuovaLista
        }
      } else {
        return state;
      }
    },
    utenteElimina: (state, action) => {
      let payload = action.payload;
      if (state.lista_chat !== null) {
        let conversazioni = [...state.lista_chat];

        conversazioni.forEach((conv, index) => {
          if (conv.ChiavePartizione === payload.chat_detail.ChiavePartizione) {
            conversazioni.splice(index, 1);
          }
        })

        return {
          ...state,
          lista_chat: conversazioni,
          chat_active: state.chat_active === payload.chat_detail.ChiavePartizione ? '' : state.chat_active
        }
      } else {
        return state
      }
    }
  }
});

export const conversazioniActions = conversazioniSlice.actions;

export default conversazioniSlice;