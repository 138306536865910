import { createSlice } from '@reduxjs/toolkit';
import { sortChat } from '../../utility/functions';

const initialState = {
    lista_chat: null,
    archiviateLastEvaluatedKey: null
};

const archiviateSlice = createSlice({
    name: 'archiviate',
    initialState,
    reducers: {
        reset: (state) => {
            return initialState
        },
        setListaChat: (state, action) => {
            return {
                ...state,
                lista_chat: action.payload.altri ? sortChat([...new Set([...action.payload.lista_chat, ...state.lista_chat])]) : sortChat(action.payload.lista_chat),
                archiviateLastEvaluatedKey: action.payload.archiviateLastEvaluatedKey
            }
        }
    }
});

export const archiviateActions = archiviateSlice.actions;

export default archiviateSlice;