import { useSelector } from "react-redux"

import InputTesto from './input_testo';
import InputAudio from './input_audio';
import InputFile from './input_file';

const InputMessaggio = () => {
    const stato = useSelector((state) => state.dettaglio.dettagli.stato);
    const type = useSelector((state) => state.moduli.input_messaggio.type);

    return (
        <>
            {stato === 0 && (
                <div className=" w-100 border-top d-flex justify-content-center align-items-center" id="input-messaggio">
                    <h6>Chat Chiusa. Riaprire chat per continuare la conversazione</h6>
                </div>
            )}
            {stato === 1 && type === 'testo' && (
                <InputTesto />
            )}
            {stato === 1 && type === 'audio' && (
                <InputAudio />
            )}
            {stato === 1 && type === 'file' && (
                <InputFile />
            )}
        </>

    )
}

export default InputMessaggio