import {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';

// bootstrap
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck,faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

//actions
import { submitLoginModale } from '../../store/login/loginActions'
import { logout } from '../../store/user/userActions';

const ModaleTokenScaduto = () => {
    const modale = useSelector((state) => state.feedback.modale_token_scaduto);
    const servizio_libemax = useSelector((state) => state.user.servizio_libemax);
    const show = modale.show;
    const text = modale.text;
    const callback_action = modale.callback_action;
    const status = modale.status;

    const dispatch = useDispatch();

    const [input, setInput] = useState({
        password: ''
    });

    const onChangeHandler = (event) => {
        const { name, value } = event.target
    
        setInput({ 
            ...input, 
            [name]: value 
        });
    }

    const submitHandler = (e) => {
        let form = e.currentTarget;

        if(status === 'loading'){
            e.preventDefault();
        }else if (form.checkValidity() === true) {
            e.preventDefault();
            dispatch(submitLoginModale({password: input.password, callback_action: callback_action}));
        }
        
    }

    return (
        <Modal show={show} backdrop="static" keyboard={false} onHide={() => {}} centered >
        <Modal.Body className="justify-content-center">
            <Alert  variant="warning">
                {text}
            </Alert>
            <Form onSubmit={submitHandler} className="my-3 d-flex justify-content-center">
                <Form.Group className="w-75">
                    <FloatingLabel label="Password">
                        <Form.Control type="password" onChange={onChangeHandler} placeholder="Password" value={input.password} name="password" />
                    </FloatingLabel>
                    {servizio_libemax && <div className='mt-2 text-muted' style={{ fontSize: 12 }}><FontAwesomeIcon icon={faInfoCircle} className='me-1'/>Oppure torna su App Rilevazione Presenze ed apri una chat di Dimmia per rieffettuare automaticamente il login</div>}
                    <div className="d-flex flex-column justify-content-center align-items-center mt-2">
                        <Button type="submit" className="my-2  rounded-pill" disabled={status === 'loading'} >
                            { status === 'loading' ? <FontAwesomeIcon icon={faSpinner} /> : <><FontAwesomeIcon icon={faCheck} /> Conferma Login</>}
                        </Button>
                        <span onClick={ () => { dispatch(logout()) } }  className="cursor-pointer text-primary">Logout</span>
                    </div>
                </Form.Group>
            </Form>
        </Modal.Body>
        </Modal>
    );
}

export default ModaleTokenScaduto