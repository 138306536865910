import { useSelector } from 'react-redux';

import NotificheDimmia from './notifiche_dimmia';
import WebsocketOverlay from './websocket_overlay';
import ModaleTokenScaduto from './modale_token_scaduto';

const Feedback = () => {
    const logged = useSelector((state) => state.user.logged);

    return (
        <>
            <NotificheDimmia/>
            {
                logged && <WebsocketOverlay/> // feedback da dare solo ad utente loggato
            } 
            <ModaleTokenScaduto/>
        </>
    )
}

export default Feedback