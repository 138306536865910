import { conversazioniActions } from '../conversazioni/conversazioniSlice';
import { dettaglioActions } from '../dettaglio/dettaglioSlice';
import { feedbackActions } from '../feedback/feedbackSlice';
import { loginActions } from '../login/loginSlice';
import { messaggiActions } from '../messaggi/messaggiSlice'
import { moduliActions } from '../moduli/moduliSlice';
import { ricercaActions } from '../ricerca/ricercaSlice';
import { servizioActions } from '../servizio/servizioSlice';
import { userActions } from '../user/userSlice';

export const logout = () => {
    return async (dispatch, getState) => {
        localStorage.removeItem('user');
        dispatch(conversazioniActions.reset());
        dispatch(dettaglioActions.reset());
        dispatch(feedbackActions.reset());
        dispatch(loginActions.reset());
        dispatch(messaggiActions.reset());
        dispatch(moduliActions.reset());
        dispatch(ricercaActions.reset());
        dispatch(servizioActions.reset());
        dispatch(userActions.reset());
    }
};