import { useSelector } from "react-redux"
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { datesAreOnSameDay, ieriOggi } from "../../utility/functions";

import Messaggio from '../messaggio'

const ElencoMessaggi = () => {
    const lista_messaggi = useSelector((state) => state.messaggi.lista_messaggi);

    return (
        <TransitionGroup exit={false} appear={true}>
            {
                lista_messaggi.map((msg, index) => {
                    let stampa_data = false;
                    let data_msg = null;
                    if(lista_messaggi[index - 1]){
                        data_msg = new Date(msg.messaggio_data_ora);
                        let msg_prev = new Date(lista_messaggi[index - 1].messaggio_data_ora);
                        if(!datesAreOnSameDay(data_msg, msg_prev)){
                            stampa_data = true;
                        }
                    }else{
                        data_msg = new Date(msg.messaggio_data_ora);
                        stampa_data = true;
                    }

                    if(stampa_data){
                        if(ieriOggi(data_msg) === 'ieri'){
                            data_msg = 'Ieri'
                        }else if(ieriOggi(data_msg) === 'oggi'){
                            data_msg = 'Oggi'
                        }else{
                            data_msg = data_msg?.toLocaleDateString('it-IT', { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit' });
                        }
                    }
                    
                    return (
                        <CSSTransition key={msg.ChiaveOrdinamento} timeout={100} classNames="msg-fade">
                            <div>
                                {stampa_data && (
                                    <div className="p-2 d-flex justify-content-center align-items-center">
                                        <div className="bg-white border rounded-pill py-1 px-3 shadow-sm text-capitalize">
                                            <strong><small>{data_msg}</small></strong>
                                        </div>
                                    </div>
                                )}
                                <Messaggio msg={msg} />
                            </div>
                        </CSSTransition>
                    )
                })
            }
        </TransitionGroup>
    )
}

export default ElencoMessaggi