import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  account: '',
  userId: '',
  nome: '',
  token: '',
  nome_account: '',
  servizio_libemax: null,
  logged: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      return initialState
    },
    login: (state, action) => {
      let encoded = window.btoa(decodeURIComponent(encodeURIComponent(JSON.stringify(action.payload))));
      localStorage.setItem('user', encoded);

      let new_state = {
        ...action.payload,
        logged: true
      }

      return new_state
    },
    getLocalStorageLogin: (state) => {
      if(localStorage.getItem('user') !== null){
        try{
          let encoded = localStorage.getItem('user');
          let decoded = JSON.parse(decodeURIComponent(encodeURIComponent(window.atob(encoded))));

          decoded.logged = true;
          return decoded
        }catch (e){
          return state
        }
        
      }else{
        return state
      }
    }
  }
});

export const userActions = userSlice.actions;

export default userSlice;