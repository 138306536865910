import axios from 'axios';
import qs from 'qs';
import costanti from './costanti';

const chiamataAPI = async (url, params, token = null, axiosCancelToken = false) => {
    let config = {
        withCredentials: url === costanti.api_login_account || url === costanti.api_autologin ? true : false,
        cancelToken: axiosCancelToken ? axiosCancelToken.token : null
    }
        
    var data = qs.stringify(params, { arrayFormat: 'repeat' });
    if (token !== null) {
        url += '?token=' + token;
    }

    return await axios.post(url, data, config)
        .then(res => {
            if (process.env.REACT_APP_STAGE !== 'prod') {
                console.log(new Date().toISOString(), res.data)
            }
            return res.data
        }, (error) => {
            if (process.env.REACT_APP_STAGE !== 'prod' || error.message !== 'michele approved') {
                console.log(error)
            }
            // per avere lo status code della risposta impostare in api gateway header della risposta allow cors origins '*'
            if (error.response !== undefined && error.response.status === 403) {
                return {
                    stato: -1,
                    errori: ['403 - Unauthorized ']
                }
            } else if (error.message === 'michele approved') {
                return {
                    stato: 0,
                    errori: ['Operazione Interrotta']
                }
            } else {
                return {
                    stato: 0,
                    errori: ['Ops! Qualcosa è andato storto']
                }
            }
        });


}

export default chiamataAPI;