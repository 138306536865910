import { conversazioniActions } from "../conversazioni/conversazioniSlice";
import { messaggiActions } from "../messaggi/messaggiSlice";
import { moduliActions } from "../moduli/moduliSlice";
import { chatDettaglioGet } from '../dettaglio/dettaglioActions';
import { feedbackActions } from "../feedback/feedbackSlice";
import { messaggioInserisci } from "../messaggi/messaggiActions";
import { messaggioRimuovi } from "../messaggi/messaggiActions";

const onMessage = (store) => (msg) => {
    let data = JSON.parse(msg.data);
    if(process.env.REACT_APP_STAGE !== 'prod'){
        console.log(new Date().toISOString(), data)
    }

    if (data.stato === 1) {
        switch (data.tipo_richiesta) {
            case 'ws-chat-lista-get':
                store.dispatch(moduliActions.setListaCaricaAltreStatus({carica_altre_status: 'ok'}));
                store.dispatch(conversazioniActions.chatListaGet(data));
                break;
            case 'ws-conversazione-update':
                if(store.getState().conversazioni.chat_active === data.data?.ChiavePartizione?.S){
                    store.dispatch(chatDettaglioGet({}));// qua non c'è la possibilità di annulare la richiesta API. se uno cambia chat nel metre la richiesta è inviata e arriva prima la sua e poi questa si rischia che si trova una chat aperta con i dettagli di un'altra chat ma è una roba praticamente imposssibile (millisecondi di errore) e nel caso basta che riclicca sulla sua e si sitema tutto. Quindi per ora tengo così
                }
                store.dispatch(conversazioniActions.conversazioneUpdate(data));
                break;
            case 'ws-chat-messaggi-get':
                store.dispatch(moduliActions.setMessaggiStatus({ status: 'ok', btn_carica_altri_status: 'ok' }));
                store.dispatch(messaggiActions.chatMessaggiGet({
                    data: data,
                    riferimento_div_scroll: store.getState().conversazioni.message_active
                }));
                break;
            case 'ws-messaggio-inserisci':
                store.dispatch(messaggioInserisci({ data: data.data }));
                break;
            case 'ws-messaggio-elimina':
                store.dispatch(messaggioRimuovi({ data: data.data }));
                break;
            case 'ws-utente-inserisci':
                store.dispatch(conversazioniActions.utenteInserisci({ chat_detail: data.data.chat_detail }));
                break;
            case 'ws-utente-elimina':
                store.dispatch(conversazioniActions.utenteElimina({ chat_detail: data.data.chat_detail }));
                break;
            case 'ws-utente-modifica':
                store.dispatch(chatDettaglioGet({}));// qua non c'è la possibilità di annulare la richiesta API. se uno cambia chat nel metre la richiesta è inviata e arriva prima la sua e poi questa si rischia che si trova una chat aperta con i dettagli di un'altra chat ma è una roba praticamente imposssibile (millisecondi di errore) e nel caso basta che riclicca sulla sua e si sitema tutto. Quindi per ora tengo così
                store.dispatch(conversazioniActions.utenteModifica(data));
                //XXX AGGIUNGERE QUA AGGIORNAMENTO LISTA CHAT ARCHIVIATE ?? 
                break;
            default:
                break;
        }
    } else if (data.stato === 0) {
        switch (data.tipo_richiesta) {
            case 'ws-chat-messaggi-get':
                // store.dispatch(feedbackActions.addNotifica({ errori: ["Si sta cercando di aprire una chat che non esiste o una chat di cui non si è partecipante. Controllare che l'utente con cui si è fatto login sia un partecipante della chat richiesta"] })); // adesso questo errore è tornato dal server
                store.dispatch(moduliActions.setMessaggiStatus({ status: 'ok' }));
                break;
            case 'ws-connect':
                store.dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Attenzione lo stesso utente non può essere loggato in dimmia con più di ' + data.numero_massimo_connessioni + ' dispositivi diversi',
                    callback_action: '',
                    status: 'ok'
                }));
                break;
            default:
                break;
        }
    }
}

export default onMessage