import chiamataAPI from "../../utility/chiamataAPI";
import costanti from "../../utility/costanti";

import { feedbackActions } from '../feedback/feedbackSlice';
import { moduliActions } from '../moduli/moduliSlice';
import { conversazioniActions } from './conversazioniSlice'
import message from '../../utility/ws_message';

export const submitCreaChat = (payload) => {
    return async (dispatch, getState) => {
        dispatch(moduliActions.setCreaChatStatus({ status: 'loading' }));

        const form = payload.form;

        const richiesta_axios = payload.richiesta_axios;

        let partecipanti = [];

        Object.values(form.lista_utenti).forEach(utente => {
            if (utente.is_checked) {
                partecipanti.push(JSON.stringify({
                    userId: utente.ChiaveOrdinamento,
                    is_amministratore: utente.is_amministratore
                }));
            }
        });

        const user = getState().user;

        let url = costanti.api_chat_inserisci;
        let data = {
            titolo: form.titolo,
            account: user.account,
            utente: partecipanti,
            categoria: form.categoria,
            descrizione: encodeURI(form.descrizione),
            userId: user.userId,
            piattaforma: 'web'
        }

        chiamataAPI(url, data, user.token, richiesta_axios).then(r => {
            if (r.stato === -1) {
                dispatch(feedbackActions.addNotifica({ errori: ['Operazione Interrotta'] }));
                dispatch(feedbackActions.setModaleTokenScaduto({
                    show: true,
                    text: 'Sessione scaduta. Inserire password per effettuare il login',
                    callback_action: JSON.stringify({
                        type: 'submitCreaChat',
                        payload: payload
                    }),
                    status: 'ok'
                }))
            } else if (r.stato === 1) {
                dispatch(moduliActions.setCreaChatStatus({ status: 'ok', open: false }));
                dispatch(conversazioniActions.setChatActive({ chat_active: r.data.ChiavePartizione, message_active: null }))
                dispatch(feedbackActions.addNotifica({ success: 'Chat creata correttamente' }));
            } else if (r.stato === 0) {
                dispatch(moduliActions.setCreaChatStatus({ status: 'ok' }));
                dispatch(feedbackActions.addNotifica({ errori: r.errori ? r.errori : ['Ops! Qualcosa è andato storto'] }));
            }
        })
    }
};

export const messaggioInserisci = (payload) => {
    return async (dispatch, getState) => {
        let nuovaLista = null;
        let esiste = false;
        let messaggio = payload.msg;
        const user = getState().user;
        const lista_chat = [...getState().conversazioni.lista_chat];
        const chatLastEvaluatedKey = getState().conversazioni.chatLastEvaluatedKey;

        if (lista_chat !== null) {
            nuovaLista = [...lista_chat];

            nuovaLista = nuovaLista.map(chat => {
                if (chat.ChiavePartizione === messaggio.ChiavePartizione.S) {
                    esiste = true;
                    return {
                        ...chat,
                        nuovi_messaggi: chat.nuovi_messaggi + 1,
                        last_updated: messaggio.messaggio_data_ora.S
                    }
                }

                return chat
            });
        }

        if (esiste) {
            dispatch(conversazioniActions.chatListaGet({
                data: {
                    chat: nuovaLista,
                    altri: false,
                    chatLastEvaluatedKey: chatLastEvaluatedKey
                }
            }))
        } else {
            if (lista_chat !== null) { // se c'è un nuovo messaggio in teoria il last_updated della chat è praticamente adesso quindi la chat col nuovo messaggio dovrebbe trovarsi nelle primisssime posizioni della lista chat. Quindi aggiorni la lista arrivando all'ultima chat che ho già in modo che l'utente non si veda cambiare la lista chat se non per l'aggiunta della chat col nuoco messaggio. Altrimenti carico le chat almeno fino a quella col nuovo messaggio.
                dispatch({
                    type: 'WS_SEND',
                    payload: {
                        msg: message.chat_lista_get(user.userId, user.account)
                    }
                })
            }else{
                dispatch({
                    type: 'WS_SEND',
                    payload: {
                        msg: message.chat_lista_get(user.userId, user.account)
                    }
                })
            }
        }

    }
};

export const submitClonaChat = () => {
    return async (dispatch, getState) => {
        dispatch(moduliActions.setDettaglio({ btn_clona_status: 'loading' }));

        const dettaglio = getState().dettaglio.dettagli;
        const chat_open = getState().moduli.crea_chat.open;
        const lista_utenti = getState().dettaglio.lista_utenti;

        let utenti_chat = [];
        let amministratori = [];
        lista_utenti.forEach(ut =>{
            utenti_chat.push(ut.ChiaveOrdinamento);
            if(ut.is_amministratore){
                amministratori.push(ut.ChiaveOrdinamento);
            }
        })

        let init = {
            titolo: dettaglio.titolo,
            descrizione: dettaglio.descrizione,
            categoria: dettaglio.categoria,
            utenti: utenti_chat,
            amministratori: amministratori
        }

        dispatch(moduliActions.setCreaChatInit({ 
            init: init 
        }));

        if (!chat_open) {
            dispatch(moduliActions.toggleCreaChat());
        }

        dispatch(moduliActions.setDettaglio({ btn_clona_status: 'ok' }));
    }
};