import { useSelector, useDispatch } from 'react-redux';

// bootstrap
import { Form, Badge } from 'react-bootstrap'
import { getLetterColor } from '../../utility/functions';

//fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faSpinner, faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import { listaCategorieGet } from '../../store/servizio/servizioActions'

const RicercaCategorie = (props) => {
    const form = props.form;
    const setForm = props.setForm;
    const onChangeHandler = props.onChangeHandler;

    const dispatch = useDispatch();

    const refreshCategorie = () => {
        dispatch(listaCategorieGet());
    }

    const lista_categorie_status = useSelector((state) => state.moduli.servizio.lista_categorie_status);

    const categoriaHandler = (action, cat) => {
        let categoria = form.categoria;

        if (action === 'add' && cat.length >= 3) {
            categoria.push(cat);
            categoria = [...new Set(categoria)]; // elimino duplicati
        } else if (action === 'remove') {
            const index = categoria.indexOf(cat);
            if (index > -1) {
                categoria.splice(index, 1);
            }
        }

        setForm({
            ...form,
            categoria: categoria
        });
    }

    const ListaCategoria = () => {
        let count = 0;
        if (form.lista_categorie) {
            return form.lista_categorie?.map(cat => {
                if (!form.categoria?.includes(cat.nome) && count < 5 && cat.nome.toLowerCase().startsWith(form.input_categoria.toLowerCase())) {
                    count++;
                    return <Badge key={cat.nome} onClick={() => { categoriaHandler('add', cat.nome) }} className="text-light cursor-pointer p-1 me-2 mb-2" style={{ backgroundColor: getLetterColor(cat.nome) }}>{cat.nome}<FontAwesomeIcon className="ms-2" icon={faPlus} /></Badge>
                }else{
                    return null
                }
            })
        } else {
            return null
        }
    }

    const ListaSelezionate = () => {

        return (
            <>
            {form.categoria.length > 0 && (
                <>
                {
                     form.categoria?.map(cat => {
                        return <Badge key={cat} onClick={() => { categoriaHandler('remove', cat) }} className="text-light cursor-pointer p-1 me-2 mb-2" style={{ backgroundColor: getLetterColor(cat) }}>{cat}<FontAwesomeIcon className="ms-2" icon={faMinus} /></Badge>
                    })
                }
                </>
            )}
            {form.categoria.length === 0 && (
                <small>Nessuna categoria selezionata</small>
            )}
            </>
        )
    }

    return (
        <div className="py-2">
            <Form.Label><strong>Categorie</strong><FontAwesomeIcon onClick={() => {refreshCategorie()}} className="ms-2 text-primary cursor-pointer" spin={lista_categorie_status === 'loading'} size="sm" icon={faSyncAlt} /></Form.Label>
            <div className="d-flex align-items-center justify-content-start mb-2">
                <Form.Control className="w-75 d-inline-block" placeholder="Ricerca Categoria" onChange={onChangeHandler} name="input_categoria" value={form.input_categoria} />
            </div>
            {lista_categorie_status === 'loading' && (
                <div className="text-primary text-center py-2">
                    <FontAwesomeIcon spin className="text-primary" icon={faSpinner} />
                </div>
            )}
            {lista_categorie_status === 'ok' && (
                <>
                    <ListaCategoria />
                    <h6 className="my-1"><small><strong>Selezionate:</strong></small></h6>
                    <ListaSelezionate />
                </>
            )}
        </div>
    )
}

export default RicercaCategorie