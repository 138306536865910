import { useSelector, useDispatch } from "react-redux"
import { useState, useRef, useEffect } from 'react';

import { CSSTransition } from "react-transition-group";

import 'emoji-mart-virtualized/css/emoji-mart.css'
import data from 'emoji-mart-virtualized/data/google.json'

import { NimblePicker } from 'emoji-mart-virtualized'

import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faMicrophone, faPaperclip, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faSmile } from "@fortawesome/free-regular-svg-icons";

//action
import { moduliActions } from '../../store/moduli/moduliSlice';
import { inviaMessaggio } from "../../store/messaggi/messaggiActions";

const InputTesto = () => {
    const showEmoji = useSelector((state) => state.moduli.input_messaggio.showEmoji);
    const textMultiline = useSelector((state) => state.moduli.input_messaggio.multiline);
    const xs = useSelector((state) => state.moduli.input_messaggio.xs);
    const dettaglio_open = useSelector((state) => state.moduli.dettaglio.open);

    const divInput = useRef(null);

    const [inputMsg, setInputMsg] = useState({
        msg: ''
    });

    const dispatch = useDispatch();

    useEffect(()=>{
        // devo dare il tempo all'animazione di apertura del dettaglio se no ho la vecchia width. Comunque questo resize all'apertura del dettaglio si fa solo in rare width intermedie tra il desktop e il mobile
        setTimeout(() => {
            if(divInput.current?.offsetWidth < 550){
                dispatch(moduliActions.setInputVisualization({xs: true}))
            }else{
                dispatch(moduliActions.setInputVisualization({xs: false}))
            }
        }, 301)
        
    }, [divInput.current, dettaglio_open])

    const setType = (type) => {
        dispatch(moduliActions.setInputType({ type: type }));
    }

    const toggleEmoji = () => {
        dispatch(moduliActions.toggleEmoji());
        document.getElementById("msg").focus();
    }

    const disableEnter = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault()
        }
    }

    const onChangeHandler = (e) => {
        // Number(window.getComputedStyle(document.getElementById('msg')).lineHeight.match(/\d+/)[0])
        // questo stringone fa prendo la lineheight con getComputedStyle cioè la line-height calcolata (non è definita nel css ma calcolata). poi fa una regex per prendere solo la parte numerica. poi fa Number per convertirla in tipo numero
        if (document.getElementById('msg').scrollHeight > Number(window.getComputedStyle(document.getElementById('msg')).lineHeight.match(/\d+/)[0]) * 2) {
            // sistema non precisissimo scrollheight tiene conto del padding quindi è circa 36. lineheight è circa 24 (scrollHeight - padding). Ignoro il padding quindi se raddoppio le linee lo scroll è due linee più padding e sarà maggiore di altezzalinea * 2
            dispatch(moduliActions.setInputVisualization({ multiline: true }));
        }

        if (e.target.value === '') {
            dispatch(moduliActions.setInputVisualization({ multiline: false }));
        }

        setInputMsg({
            ...inputMsg,
            msg: e.target.value
        });
    }

    const onKeyUpHandler = (event) => {
        if (event.keyCode === 13 && inputMsg.msg.length > 1 && (!event.shiftKey && !event.ctrlKey && !event.altKey)) {
            submitHandler();
        } else if (event.keyCode === 13 && (event.shiftKey || event.ctrlKey || event.altKey)) {
            dispatch(moduliActions.setInputVisualization({ multiline: true }));
            setInputMsg({
                ...inputMsg,
                msg: inputMsg.msg + '\n'
            });
        };
    }

    const selectEmojiHandler = (emoji) => {
        let el = document.getElementById('msg');
        const [start, end] = [el.selectionStart, el.selectionEnd];

        let textBeforeCursorPosition = inputMsg.msg.substring(0, start);
        let textAfterCursorPosition = inputMsg.msg.substring(end, inputMsg.msg.length);

        setInputMsg({
            ...inputMsg,
            msg: textBeforeCursorPosition + emoji.native + textAfterCursorPosition
        });

        document.getElementById("msg").focus();
    }

    const submitHandler = (e) => {

        let form = document.getElementById('form-input-msg');

        if (form.checkValidity() === true) {
            if (e) e.preventDefault();

            dispatch(inviaMessaggio({
                testo: inputMsg.msg,
                tipo: 'testo'
            }));

            if (showEmoji) dispatch(toggleEmoji);

            setInputMsg({
                msg: ''
            });
            
            dispatch(moduliActions.setInputVisualization({ multiline: false })); // in teoria questo va in automatico ma lo forzo perchè dipende dal browser
        }
    }

    return (
        <div ref={divInput} className={`w-100 shadow-top border-top ${textMultiline ? 'multiline' : ''}`} id="input-messaggio">
            <Form onSubmit={submitHandler} id="form-input-msg" className="form-inline w-100 h-100 ">
                <Form.Group className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <div className={` ${xs ? 'd-flex justify-content-center align-items-center w-25' : ' w-15'} text-end `}>
                        <FontAwesomeIcon onClick={() => { setType('audio') }} className="fs-5 align-middle text-primary me-3 cursor-pointer" icon={faMicrophone} />
                        <FontAwesomeIcon onClick={() => { setType('file') }} className="fs-5 align-middle text-primary me-3 cursor-pointer" icon={faPaperclip} />
                    </div>
                    <div className="flex-fill h-100 py-3 position-relative d-flex align-items-center justify-content-center">
                        <CSSTransition in={showEmoji} timeout={500} unmountOnExit classNames="emoji-fade" >
                            <NimblePicker data={data} exclude={['symbols']} color="#346ab0" notFoundEmoji="sleuth_or_spy" onSelect={selectEmojiHandler} sheetSize={32} useButton={false} set="google" showPreview={false} showSkinTones={false} defaultSkin={1} style={{ position: "absolute", top: 6, left: 0, transform: 'translateY(-100%) scale(1)' }} />
                        </CSSTransition>
                        {!xs &&
                            <FontAwesomeIcon id="toggleEmoji" className="text-primary fs-6 cursor-pointer" onClick={toggleEmoji} icon={showEmoji ? faTimes : faSmile} />
                        }
                        <Form.Control id="msg" required minLength={3} onKeyDown={disableEnter} style={{ resize: 'none' }} autoComplete="off" onKeyUp={onKeyUpHandler} as="textarea" rows={textMultiline ? 3 : 1} value={inputMsg.msg} onChange={onChangeHandler} className={`w-100 h-100 ${!textMultiline && 'rounded-pill'} ${xs ? 'ps-3' : 'ps-5'}`} placeholder="Scrivi Messaggio" />
                    </div>
                    <div className={` ${xs ? 'd-flex justify-content-center align-items-center w-25' : 'text-start w-15'}  `}>
                        <Button type="submit" className={` ${xs ? '' : 'ms-3 px-4'}  cursor-pointer rounded-pill`}><FontAwesomeIcon icon={faPaperPlane} /></Button>
                    </div>
                </Form.Group>
            </Form>
        </div>
    )
}

export default InputTesto