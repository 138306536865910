import axios from 'axios';
import qs from 'qs';
import costanti from './costanti';

const uploadFile = async (data, metadata, progress, token, axiosCancelToken = false) => {
    let risposta = false;

    let url = costanti.api_url_presigned_url_get + '?token=' + token;
    let config = {
        headers: {
            'Content-Type': metadata.content_type !== '' ? metadata.content_type : 'file'
        },
        onUploadProgress: progress
    }

    if (axiosCancelToken) {
        config.cancelToken = axiosCancelToken.token;
    }

    metadata = qs.stringify(metadata);

    await axios.post(url, metadata, axiosCancelToken ? { cancelToken: axiosCancelToken.token } : '')
        .then(async res => {
            let presignedUrl = res.data;
            await axios.put(presignedUrl, data, config).then(r => {

                risposta = {
                    stato: 1,
                    data: r
                }
            }).catch(e => {

                if (e.message === 'michele approved') {
                    return {
                        stato: 0,
                        errori: ['Operazione Interrotta']
                    }
                } else {
                    risposta = {
                        stato: 0,
                        errori: e
                    }
                }
            })

        }, (error) => {
            if (error.message === 'michele approved') {
                return {
                    stato: 0,
                    errori: ['Operazione Interrotta']
                }
            } else {
                risposta = {
                    stato: 0,
                    errori: error
                }
            }
        });

    return risposta
}

export default uploadFile;